import { msg } from "@lingui/macro";
import { useLingui } from "@lingui/react";
import React, { useState } from "react";
import { useNavigate } from "react-router-dom";

import { useUpdateEmailMutation } from "../graphql/__generated__/globalTypes";
import Me from "../graphql/queries/me";
import { getValidationErrorForField } from "../graphql/validationErrors";
import ErrorMessage from "../shared/ErrorMessage";
import LoadingIndicator from "../shared/LoadingIndicator";
import PageLayout from "../shared/PageLayout";
import useRefetchAccount from "../utils/useRefetchAccount";
import type { OnSubmitData } from "./EditEmailPage/EditEmailForm";
import EditEmailForm from "./EditEmailPage/EditEmailForm";

function Loading(): React.ReactElement {
  return (
    <span className="loading-indicator-wrapper">
      <LoadingIndicator />
    </span>
  );
}

export default function EditEmailPage(): React.ReactElement {
  const { _ } = useLingui();

  // NOTE: we want an up to date accout here, not the cached value
  const {
    account,
    loading: accountLoading,
    error: accountError,
  } = useRefetchAccount();

  const navigate = useNavigate();

  const [loading, setLoading] = useState(false);

  const [updateEmail, { error: updateEmailError }] = useUpdateEmailMutation({
    refetchQueries: [{ query: Me }],
    onError: console.error,
  });

  const onSubmit = async (data: OnSubmitData) => {
    setLoading(true);

    try {
      const response = await updateEmail({
        variables: {
          unconfirmedEmail: data.email,
        },
      });

      if (response.data?.updateEmail?.id) {
        navigate("/email/confirm");
      }
    } finally {
      setLoading(false);
    }
  };

  let emailErrorMessage: string | undefined = undefined;

  if (updateEmailError) {
    emailErrorMessage = getValidationErrorForField(
      updateEmailError.graphQLErrors,
      "unconfirmed_email"
    );
  }

  const error = accountError || updateEmailError;
  const showForm = !accountLoading && (!error || emailErrorMessage);

  return (
    <PageLayout title={_(msg`Edit email`)} backPath="/reservations/new/space">
      {accountLoading && <Loading />}
      {error && !emailErrorMessage && <ErrorMessage error={error} />}
      {showForm && (
        <EditEmailForm
          account={account || undefined}
          loading={loading}
          onSubmit={onSubmit}
          emailErrorMessage={emailErrorMessage}
        />
      )}
    </PageLayout>
  );
}
