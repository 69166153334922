import { useEffect, useState } from "react";

import csrf from "./csrf";

const useCsrfToken = () => {
  const [csrfToken, setCsrfToken] = useState(csrf.token);

  useEffect(() => {
    const requestCsrfToken = async () => {
      await csrf.update();
      setCsrfToken(csrf.token);
    };
    requestCsrfToken();
  }, []);
  return csrfToken;
};

export default useCsrfToken;
