import { Trans } from "@lingui/macro";
import times from "lodash/times";
import React from "react";

import classNames from "../utils/classNames";

export type Props = {
  step: number;
  of: number;
};

function ProgressStepper({
  step: current,
  of: count,
}: Props): React.ReactElement {
  return (
    <div className="progress-stepper">
      <p className="u-sr-only">
        <Trans>
          Step {current} of {count}
        </Trans>
      </p>
      {times(count, (index) => (
        <div
          role="presentation"
          key={index}
          className={classNames({
            "progress-stepper__step": true,
            "progress-stepper__step--visited": index < current,
          })}
        />
      ))}
    </div>
  );
}

export default ProgressStepper;
