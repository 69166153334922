import { msg, Trans } from "@lingui/macro";
import { useLingui } from "@lingui/react";
import { Link } from "react-router-dom";

import { useGetReservableCategoriesQuery } from "../../../graphql/__generated__/globalTypes";
import ErrorMessage from "../../../shared/ErrorMessage";
import { ReactComponent as MinuteSeat } from "../../../shared/icons/48/minute-seat.svg";
import { ReactComponent as StoreLogoApple } from "../../../shared/images/store-logo-apple.svg";
import { ReactComponent as StoreLogoGoogle } from "../../../shared/images/store-logo-google.svg";
import {
  ExpandableLinkListItem,
  LinkList,
  LinkListItem,
} from "../../../shared/LinkList";
import LoadingIndicator from "../../../shared/LoadingIndicator";
import PageLayout from "../../../shared/PageLayout";
import ProgressStepper from "../../../shared/ProgressStepper";
import { uniqueBy } from "../../../utils/array";
import useFindSpaceOrRedirect from "../../../utils/useFindSpaceOrRedirect";

export const appleStoreUrl = "https://apps.apple.com/de/app/id1499634357";
export const googleStoreUrl =
  "https://play.google.com/store/apps/details?id=de.everyworks.app";

const ReservableCategorySelectionPage = () => {
  const { _ } = useLingui();

  const { space, spaceError, spaceLoading } = useFindSpaceOrRedirect();

  const {
    data,
    error: categoriesError,
    loading: categoriesLoading,
  } = useGetReservableCategoriesQuery({
    skip: !space?.id,
    variables: { spaceId: space?.id! },
  });

  // Note: not all spaces have all available reservable types, that's why we load reservable categories for spaces.
  // But there can be multiple reservable categories belonging to the same type and same space,
  // and we only want to display unique reservable types.
  const sortedReservableCategories = data?.reservableCategories
    ? uniqueBy(
        data?.reservableCategories,
        (category) => category?.type?.id
      ).sort((a, b) => {
        if (!a || !b) return 0;
        const posA = a.type?.position ?? 0;
        const posB = b.type?.position ?? 0;

        return posA - posB;
      })
    : [];

  const loading = categoriesLoading || spaceLoading;
  const error = categoriesError || spaceError;

  return (
    <PageLayout
      title={space?.name ?? ""}
      subtitle={_(msg`Please select an offer.`)}
      backPath={"/reservations/new/space/"}
    >
      <ProgressStepper step={2} of={6} />
      {loading && (
        <span className="loading-indicator-wrapper">
          <LoadingIndicator />
        </span>
      )}
      {error && <ErrorMessage error={error} />}
      {!loading && !error && sortedReservableCategories && (
        <LinkList>
          {sortedReservableCategories.map((category) => (
            <LinkListItem
              key={category?.id}
              imageUrl={category?.type?.iconUrl!}
              title={category?.type?.name!}
              to={`/reservations/new/date/?spaceId=${space?.id}&reservableCategoryId=${category?.id}`}
            />
          ))}
          <ExpandableLinkListItem icon={MinuteSeat} title={_(msg`Minute Seat`)}>
            <div className="reservable-category__expandable-item">
              <p>
                <Trans>
                  The Minute Seat is a workstation in our open coworking area.
                  No reservation is necessary. You will need the everyworks app
                  to check in.
                </Trans>
              </p>
              <ul className="reservable-category__expandable-item-list">
                <li>
                  <Trans>Free choice of seating</Trans>
                </li>
                <li>
                  <Trans>WLAN</Trans>
                </li>
                <li>
                  <Trans>Coffee, tea and water</Trans>
                </li>
              </ul>
            </div>
            <div className="reservable-category__stores">
              <Link to={appleStoreUrl}>
                <StoreLogoApple
                  role="img"
                  aria-label={_(msg`Apple store logo`)}
                />
              </Link>
              <Link to={googleStoreUrl}>
                <StoreLogoGoogle
                  role="img"
                  aria-label={_(msg`Google store logo`)}
                />
              </Link>
            </div>
          </ExpandableLinkListItem>
        </LinkList>
      )}
    </PageLayout>
  );
};

export default ReservableCategorySelectionPage;
