import { gql } from "@apollo/client";

const query = gql`
  query getMyReservations {
    myReservations(onlyActive: false) {
      id
      startsAt
      endsAt
      date
      guests {
        name
      }
      guestInvitationUrl
      reservable {
        name
        reservableCategory {
          capacity
          name
          type {
            name
          }
        }
        space {
          name
        }
      }
      state
      totalCents
      vatPercent
    }
  }
`;
export default query;
