import React from "react";

import CSRFContext from "./auth/CSRFContext";
import useCsrfToken from "./auth/useCsrfToken";
import type Client from "./graphql/Client";
import GraphQLProvider from "./graphql/Provider";
import I18nProvider from "./i18n/Provider";

export type Props = React.PropsWithChildren<{ client: Client }>;

function Wrapper({ client, children }: Props): React.ReactElement {
  const csrfToken = useCsrfToken();

  return (
    <CSRFContext.Provider value={csrfToken}>
      <GraphQLProvider client={client}>
        <I18nProvider>{children}</I18nProvider>
      </GraphQLProvider>
    </CSRFContext.Provider>
  );
}

export default Wrapper;
