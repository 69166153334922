import { useEffect } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";

import { isValidEncodedDate } from "./date";
import { findTimeSlotByEndTime, findTimeSlotByStartTime } from "./timeSlots";

export type Props = {
  spaceId: string | null | undefined;
  reservableCategoryId: string | null | undefined;
};
const useFindSelectedDateOrRedirect = ({
  spaceId,
  reservableCategoryId,
}: Props) => {
  const [searchParams] = useSearchParams();
  const navigate = useNavigate();
  const date = searchParams.get("date");
  const startTime = searchParams.get("startTime");
  const endTime = searchParams.get("endTime");

  const validFromSlot = () =>
    isValidEncodedDate(date)
      ? findTimeSlotByStartTime(date!, startTime!)
      : undefined;

  const validToSlot = () =>
    isValidEncodedDate(date)
      ? findTimeSlotByEndTime(date!, endTime!)
      : undefined;

  const fromSlot = validFromSlot();
  const toSlot = validToSlot();

  useEffect(() => {
    if (spaceId && reservableCategoryId && (!date || !fromSlot || !toSlot)) {
      navigate(
        `/reservations/new/date/?spaceId=${spaceId}&reservableCategoryId=${reservableCategoryId}`,
        {
          replace: true,
        }
      );
    }
  }, [date, fromSlot, toSlot, navigate, spaceId, reservableCategoryId]);

  return {
    date,
    startTime,
    endTime,
    fromSlot,
    toSlot,
  };
};

export default useFindSelectedDateOrRedirect;
