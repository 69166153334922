import { useEffect } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";

import type { ReservableCategory } from "../graphql/__generated__/globalTypes";
import { useGetReservableCategoriesQuery } from "../graphql/__generated__/globalTypes";

const useFindReservableCategoryOrRedirect = (
  spaceId: string | null | undefined
) => {
  const [searchParams] = useSearchParams();
  const navigate = useNavigate();
  const reservableCategoryId = searchParams.get("reservableCategoryId");
  const shouldSkip = !spaceId;
  const {
    data,
    loading: reservableCategoryLoading,
    error: reservableCategoryError,
  } = useGetReservableCategoriesQuery({
    skip: shouldSkip,
    variables: { spaceId: spaceId! },
  });

  const reservableCategory = data?.reservableCategories!.find(
    (category) => category?.id === reservableCategoryId
  );

  const allReservableCategoriesOfSameType =
    reservableCategory &&
    (data?.reservableCategories!.filter(
      (category) => category?.type?.id === reservableCategory?.type?.id
    ) as ReservableCategory[]);

  useEffect(() => {
    if (!shouldSkip && !reservableCategoryLoading && !reservableCategoryError) {
      if (!reservableCategory) {
        navigate(`/reservations/new/category?spaceId=${spaceId}`, {
          replace: true,
        });
      }
    }
  }, [
    shouldSkip,
    reservableCategoryLoading,
    reservableCategoryError,
    data,
    reservableCategory,
    spaceId,
    navigate,
  ]);

  return {
    reservableCategory,
    allReservableCategoriesOfSameType,
    reservableCategoryLoading,
    reservableCategoryError,
  };
};

export default useFindReservableCategoryOrRedirect;
