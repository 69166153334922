import { msg, Trans } from "@lingui/macro";
import { useLingui } from "@lingui/react";
import React from "react";

import { useGetReservationSuggestionsQuery } from "../../../graphql/__generated__/globalTypes";
import Button from "../../../shared/Button";
import EmptyBox from "../../../shared/EmptyBox";
import ErrorMessage from "../../../shared/ErrorMessage";
import FormattedDate from "../../../shared/FormattedDate";
import Icon from "../../../shared/Icon";
import { ReactComponent as Pencil32 } from "../../../shared/icons/32/pencil.svg";
import { ReactComponent as CalendarSearch48 } from "../../../shared/icons/48/calendar-search.svg";
import genericRoom from "../../../shared/images/generic-room.png";
import LoadingIndicator from "../../../shared/LoadingIndicator";
import PageLayout from "../../../shared/PageLayout";
import ProgressStepper from "../../../shared/ProgressStepper";
import ReservableListItem from "../../../shared/ReservableListItem";
import ReservableListPlaceholderItem from "../../../shared/ReservableListPlaceholderItem";
import {
  formatTimeInDefaultTimezone,
  formatTimezone,
} from "../../../utils/time";
import useFindReservableCategoryOrRedirect from "../../../utils/useFindReservableCategoryOrRedirect";
import useFindSelectedDateOrRedirect from "../../../utils/useFindSelectedDateOrRedirect";
import useFindSpaceOrRedirect from "../../../utils/useFindSpaceOrRedirect";

export default function SuggestionsPage(): React.ReactElement {
  const { _ } = useLingui();

  const { space, spaceError, spaceLoading } = useFindSpaceOrRedirect();
  const {
    reservableCategory,
    reservableCategoryError,
    reservableCategoryLoading,
  } = useFindReservableCategoryOrRedirect(space?.id);

  const { date, startTime, endTime, fromSlot, toSlot } =
    useFindSelectedDateOrRedirect({
      spaceId: space?.id,
      reservableCategoryId: reservableCategory?.id,
    });

  const {
    data: reservationSuggestionsData,
    loading: reservationSuggestionsLoading,
    error: reservationSuggestionsError,
  } = useGetReservationSuggestionsQuery({
    skip:
      !space?.id ||
      !date ||
      !fromSlot ||
      !toSlot ||
      !reservableCategory?.type?.id,
    variables: {
      spaceId: space?.id!,
      date: date,
      slotRange: { fromSlot: fromSlot?.index!, toSlot: toSlot?.index! },
      minCapacity: 1,
      reservableTypeId: reservableCategory?.type?.id || "",
    },
  });

  const queryParams = new URLSearchParams({
    spaceId: space?.id!,
    reservableCategoryId: reservableCategory?.id!,
    date: date!,
    startTime: startTime!,
    endTime: endTime!,
  }).toString();

  const backPath = `/reservations/new/date/?${queryParams}`;

  const hasMatches =
    reservationSuggestionsData?.reservationSuggestions?.matches?.length !== 0;

  const loading =
    spaceLoading || reservableCategoryLoading || reservationSuggestionsLoading;
  const error =
    spaceError || reservableCategoryError || reservationSuggestionsError;

  return (
    <PageLayout
      title={_(msg`These are your options`)}
      subtitle={_(msg`Please select an offer.`)}
      backPath={backPath}
    >
      <ProgressStepper step={4} of={6} />
      {loading && (
        <span className="loading-indicator-wrapper">
          <LoadingIndicator />
        </span>
      )}
      {error && <ErrorMessage error={error} />}
      {!loading && !error && (
        <div>
          <div className="suggestions-page__edit">
            <div>
              <Icon svg={CalendarSearch48} size="large" />
              <span className="u-h4">
                <FormattedDate date={new Date(date!)} />,{" "}
                <Trans>
                  {fromSlot?.startTimeStringDefaultTimezone} -{" "}
                  {toSlot?.endTimeStringDefaultTimezone}{" "}
                </Trans>{" "}
                {`(${formatTimezone(new Date(date!))})`}
              </span>
            </div>
            <Button
              data-testid="edit-button"
              shape="small"
              color="transparent"
              to={backPath}
            >
              <span className="u-sr-only">
                <Trans>Edit date</Trans>
              </span>
              <Icon
                svg={Pencil32}
                size="medium"
                className="suggestions-page__edit-date-icon"
              />
            </Button>
          </div>
          <h2 className="u-h4 suggestions-page__title">
            <Trans>Availability</Trans>
          </h2>
          {!hasMatches && (
            <EmptyBox className="suggestions-page__empty-box">
              <Trans>
                Unfortunately, there are currently no offers that match your
                search criteria :(
              </Trans>
            </EmptyBox>
          )}
          <ul className="u-list-none suggestions-page__grid">
            {hasMatches &&
              reservationSuggestionsData?.reservationSuggestions?.matches?.map(
                (match) => (
                  <li key={match?.reservable?.id}>
                    <ReservableListItem
                      date={new Date(date!)}
                      startTime={formatTimeInDefaultTimezone(
                        new Date(match?.slotRange?.from?.startsAt!)
                      )}
                      endTime={formatTimeInDefaultTimezone(
                        new Date(match?.slotRange?.to?.endsAt!)
                      )}
                      reservable={match?.reservable!}
                      totalCents={match?.totalCents!}
                      to={`/reservations/new/details?${queryParams}&reservableId=${match?.reservable?.id}`}
                      titleTag="h3"
                    />
                  </li>
                )
              )}
            <li>
              <ReservableListPlaceholderItem
                title={_(msg`Individual offer? Enquire now!`)}
                description={_(
                  msg`For multi-day bookings or workshop and event spaces, simply enquire and receive a quote.`
                )}
                imageUrl={genericRoom}
                to="mailto:everyworks@deutschebahn.com"
              />
            </li>
          </ul>
        </div>
      )}
    </PageLayout>
  );
}
