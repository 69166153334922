import { createContext, type ReactElement, useContext } from "react";

import type { Account } from "../graphql/__generated__/globalTypes";
import { useMeQuery } from "../graphql/__generated__/globalTypes";
import type { GraphQLEveryworksError } from "../graphql/EveryworksError";

export type AuthContextType = { account: Account | null; loading: boolean };

const AuthContext = createContext<AuthContextType>({
  account: null,
  loading: false,
});

const expectedErrors = [
  // 'this operation requires a customer token', which just means the user is not logged in
  2001,
];

export const AuthContextProvider = ({
  children,
}: {
  children: ReactElement;
}) => {
  const { data, loading, error } = useMeQuery();
  if (error?.graphQLErrors[0]) {
    const graphQLError = error?.graphQLErrors[0] as GraphQLEveryworksError;

    if (!expectedErrors.includes(graphQLError.code)) {
      throw graphQLError;
    }
  }

  return (
    <AuthContext.Provider
      value={{
        account: data?.me || null,
        loading: loading,
      }}
    >
      {children}
    </AuthContext.Provider>
  );
};

export const useAuth = () => {
  return useContext(AuthContext);
};
