import { formatInTimeZone } from "date-fns-tz";

import type { Space } from "../graphql/__generated__/globalTypes";
import { defaultTimezone, parseDateAndTimeInDefaultTimezone } from "./time";

/*
  Returns the day of the week for the given date, using ISO indexing convention (Monday=1)
*/
export function isoDayOfWeek(dateString: string) {
  const date = parseDateAndTimeInDefaultTimezone(dateString, "00:00");
  const numberString = formatInTimeZone(date, defaultTimezone, "i");

  return parseInt(numberString, 10);
}

function findOpeningHour(space: Space, dateString: string) {
  const dayOfWeek = isoDayOfWeek(dateString);
  return space.openingHours?.find(
    (openingHour) => openingHour?.dayOfWeek === `${dayOfWeek}`
  );
}

export function findAndValidateOpeningHour(
  space: Space,
  dateString: string
): {
  opensAt: string;
  closesAt: string;
} | null {
  const openingHour = findOpeningHour(space, dateString);
  if (!openingHour) {
    console.error(
      `Expected space to define opening hours for all days of the week. Didn't find opening hours for ${dateString}.`
    );
    return null;
  }

  if (!openingHour.opensAt || !openingHour.closesAt) {
    // space is not open on this day
    return null;
  }

  // note: space opening hours (strings) are in the default timezone
  return { opensAt: openingHour.opensAt, closesAt: openingHour.closesAt };
}
