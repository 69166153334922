import { msg } from "@lingui/macro";
import { useLingui } from "@lingui/react";
import { useSearchParams } from "react-router-dom";

import ErrorMessage from "../../../shared/ErrorMessage";
import LoadingIndicator from "../../../shared/LoadingIndicator";
import PageLayout from "../../../shared/PageLayout";
import ProgressStepper from "../../../shared/ProgressStepper";
import useFindReservableCategoryOrRedirect from "../../../utils/useFindReservableCategoryOrRedirect";
import useFindSpaceOrRedirect from "../../../utils/useFindSpaceOrRedirect";
import DateSelectionForm from "./DateSelectionPage/DateSelectionForm";

const DateSelectionPage = () => {
  const { _ } = useLingui();
  const { space, spaceError, spaceLoading } = useFindSpaceOrRedirect();
  const {
    reservableCategory,
    allReservableCategoriesOfSameType,
    reservableCategoryError,
    reservableCategoryLoading,
  } = useFindReservableCategoryOrRedirect(space?.id);
  const [searchParams] = useSearchParams();

  const loading = spaceLoading || reservableCategoryLoading;
  const error = spaceError || reservableCategoryError;

  return (
    <PageLayout
      title={_(msg`When are you coming over?`)}
      backPath={`/reservations/new/category?spaceId=${space?.id}`}
    >
      <ProgressStepper step={3} of={6} />
      {loading && (
        <span className="loading-indicator-wrapper">
          <LoadingIndicator />
        </span>
      )}
      {error && <ErrorMessage error={error} />}
      {!loading && !error && space && reservableCategory && (
        <DateSelectionForm
          space={space}
          reservableCategory={reservableCategory}
          allReservableCategoriesOfSameType={
            allReservableCategoriesOfSameType || []
          }
          dateInitialValue={searchParams.get("date")}
          startTimeInitialValue={searchParams.get("startTime")}
          endTimeInitialValue={searchParams.get("endTime")}
        />
      )}
    </PageLayout>
  );
};

export default DateSelectionPage;
