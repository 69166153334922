import { msg } from "@lingui/macro";
import { useLingui } from "@lingui/react";
import { useState } from "react";

import {
  useCreateAccountMutation,
  useSignInMutation,
} from "../graphql/__generated__/globalTypes";
import { hasValidationErrors } from "../graphql/validationErrors";
import ErrorMessage from "../shared/ErrorMessage";
import PageLayout from "../shared/PageLayout";
import useRedirectPathSearchParam from "../shared/useRedirectPathSearchParam";
import { getDeviceId, getDeviceName } from "../utils/detectUserBrowser";
import { getDimaLogInPath, getExternalRedirectPath } from "../utils/dima";
import type { OnSubmitData } from "./RegisterPage/RegisterForm";
import RegisterForm from "./RegisterPage/RegisterForm";

export default function RegisterPage() {
  const { _ } = useLingui();
  const { redirectPath } = useRedirectPathSearchParam();
  const URIEncodedRedirectPath = encodeURIComponent(redirectPath);
  const [loading, setLoading] = useState(false);

  const [createAccount, { error: createAccountError }] =
    useCreateAccountMutation({ onError: console.error });

  const [signIn, { error: signInError, client }] = useSignInMutation({
    onError: console.error,
  });

  const error = signInError || createAccountError;
  const isValidationError =
    createAccountError && hasValidationErrors(createAccountError);
  const formErrors = createAccountError?.graphQLErrors;

  const onSubmit = async (data: OnSubmitData) => {
    setLoading(true);

    try {
      const createAccountResponse = await createAccount({
        variables: {
          email: data.email,
          password: data.password,
          profile: data.profile,
        },
      });

      if (createAccountResponse.data?.createAccount?.id) {
        const signInResponse = await signIn({
          variables: {
            email: data.email,
            password: data.password,
            deviceName: getDeviceName(),
            deviceIdentifier: getDeviceId(),
            redirectPath: getExternalRedirectPath(
              `/email/confirm?redirectPath=${URIEncodedRedirectPath}`
            ),
          },
        });
        const token = signInResponse.data?.signIn?.token;
        if (token) {
          await client.cache.reset();
          window.location.href = getDimaLogInPath(token);
        }
      }
    } finally {
      setLoading(false);
    }
  };

  return (
    <PageLayout title={_(msg`Your details`)} backPath="/reservations/new/space">
      {error && !isValidationError ? (
        <ErrorMessage error={error} />
      ) : (
        <RegisterForm
          onSubmit={onSubmit}
          loading={loading}
          errors={formErrors}
        />
      )}
    </PageLayout>
  );
}
