import { useApolloClient } from "@apollo/client";
import { Trans } from "@lingui/macro";
import React from "react";

import type { Props } from "../shared/Button";
import Button from "../shared/Button";
import { getDimaLogOutPath, getExternalRedirectPath } from "../utils/dima";
import { useCSRF } from "./CSRFContext";
import CSRFTokenInput from "./CSRFTokenInput";
export type { Props };

function LogOutButton(props: Props) {
  const client = useApolloClient();
  const token = useCSRF();

  if (!token) {
    return null;
  }

  const onSubmit = async () => await client.cache.reset();

  return (
    <form
      data-testid="log-out-form"
      method="POST"
      action={getDimaLogOutPath(getExternalRedirectPath("/"))}
      onSubmit={onSubmit}
    >
      <CSRFTokenInput />
      <input type="hidden" name="_method" value="delete" />
      <Button {...props} type="submit">
        <Trans>Log out</Trans>
      </Button>
    </form>
  );
}

export default LogOutButton;
