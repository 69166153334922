import { msg, Trans } from "@lingui/macro";
import { useLingui } from "@lingui/react";
import React from "react";

import { useAuth } from "../../auth/AuthContext";
import LogOutButton from "../../auth/LogOutButton";
import classNames from "../../utils/classNames";
import Button from "../Button";
import Dropdown from "../Dropdown/Dropdown";
import { ReactComponent as LogoEveryworks } from "../images/logo-everyworks.svg";
import useProfileLinks from "./useProfileLinks";

export type Props = {
  className?: string;
};

const TopBar = ({ className }: Props): React.ReactElement => {
  const { _ } = useLingui();
  const { account } = useAuth();
  const loggedIn = account!!;
  const profileLinks = useProfileLinks() || [];

  return (
    <nav
      aria-label={_(msg`primary`)}
      className={classNames(["top-bar", className])}
    >
      <div className="top-bar__nav a-content a-content--l">
        <Button to="/" color="nav">
          <span className="u-sr-only">
            <Trans>Home</Trans>
          </span>
          <LogoEveryworks role="img" aria-label={_(msg`everyworks logo`)} />
        </Button>
        <div className="top-bar__items">
          <Button
            href={_(msg`https://everyworks.de/en/contact-support/`)}
            color="nav"
          >
            <Trans>Contact</Trans>
          </Button>
          {!loggedIn && (
            <Button to="/log-in-or-register" color="nav">
              <Trans>Login</Trans>
            </Button>
          )}
          {loggedIn && (
            <>
              <Button to="/reservations" color="nav">
                <Trans>Your reservations</Trans>
              </Button>
              <Dropdown.Wrapper>
                <Dropdown.Button>
                  <Trans>Hello, {account?.profile?.firstName}!</Trans>
                </Dropdown.Button>
                <Dropdown.List>
                  {profileLinks.map((link) => (
                    <Dropdown.ListItem key={link.to || link.href}>
                      <Button
                        color="dropdown-list"
                        {...(link.to ? { to: link.to } : { href: link.href })}
                      >
                        {_(link.title)}
                      </Button>
                    </Dropdown.ListItem>
                  ))}
                  <Dropdown.ListItem>
                    <LogOutButton color="dropdown-list" />
                  </Dropdown.ListItem>
                </Dropdown.List>
              </Dropdown.Wrapper>
            </>
          )}
        </div>
      </div>
    </nav>
  );
};
export default TopBar;
