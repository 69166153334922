import { msg, Trans } from "@lingui/macro";
import { useLingui } from "@lingui/react";
import type { ReactElement } from "react";
import React, {
  createContext,
  useCallback,
  useContext,
  useEffect,
  useRef,
  useState,
} from "react";
import { Link } from "react-router-dom";

import classNames from "../../utils/classNames";
import setFocus from "../../utils/setFocus";
import Icon from "../Icon";
import { ReactComponent as CloseIcon } from "../icons/32/close.svg";
import { ReactComponent as MenuIcon } from "../icons/32/menu.svg";
import { ReactComponent as LogoEveryworks } from "../images/logo-everyworks.svg";

type SidebarContextType = {
  id: string;
  isOpen: boolean;
  buttonRef: React.RefObject<HTMLButtonElement> | null;
  openSidebar: () => void;
  closeSidebar: () => void;
};

export const SidebarContext = createContext<SidebarContextType>({
  id: "sidebar",
  isOpen: false,
  buttonRef: null,
  openSidebar: () => {},
  closeSidebar: () => {},
});

export type SidebarProviderProps = React.PropsWithChildren<{
  open?: boolean;
}>;

export function SidebarProvider({
  children,
  open = false,
}: SidebarProviderProps) {
  const buttonRef = useRef<HTMLButtonElement>(null);
  const [isOpen, setIsOpen] = useState(open);
  const openSidebar = useCallback(() => {
    setIsOpen(true);
  }, []);

  const closeSidebar = useCallback(() => {
    setIsOpen(false);
  }, []);

  return (
    <SidebarContext.Provider
      value={{ id: "sidebar", openSidebar, closeSidebar, isOpen, buttonRef }}
    >
      {children}
    </SidebarContext.Provider>
  );
}

export type SidebarButtonProps = {};

function SidebarButton() {
  const { openSidebar, closeSidebar, id, isOpen, buttonRef } =
    useContext(SidebarContext);
  const { _ } = useLingui();

  let onClick = closeSidebar;
  let description = _(msg`Close sidebar`);

  if (!isOpen) {
    onClick = openSidebar;
    description = _(msg`Open sidebar`);
  } else {
    // This is only triggered in the normally-odd case that the user manages to click the original button that opened the sidebar (in the topbar)
    onClick = () => {
      buttonRef && setFocus(buttonRef.current);
      closeSidebar();
    };
  }

  return (
    <button
      className="a-button a-button--square a-button--transparent a-button--small"
      data-testid="sidebar-button"
      ref={buttonRef}
      onClick={onClick}
      aria-controls={id}
      aria-expanded={isOpen}
    >
      <Icon svg={MenuIcon} />
      <span className="u-sr-only">{description}</span>
    </button>
  );
}

export type SidebarContentProps = React.PropsWithChildren<{}>;

function SidebarContent({ children }: SidebarContentProps): React.ReactElement {
  const { closeSidebar, isOpen, id, buttonRef } = useContext(SidebarContext);
  const contentRef = useRef<HTMLDivElement>(null);
  const { _ } = useLingui();

  const onClick = () => {
    buttonRef && setFocus(buttonRef.current);
    closeSidebar();
  };

  const closeButton = (
    <button
      className="a-button a-button--square a-button--ghost a-button--small"
      onClick={onClick}
    >
      <span className="u-sr-only">
        <Trans>Close sidebar</Trans>
      </span>
      <Icon svg={CloseIcon} />
    </button>
  );

  useEffect(() => {
    if (isOpen) setFocus(contentRef.current);
  }, [isOpen]);

  return (
    <div
      id={id}
      ref={contentRef}
      tabIndex={-1}
      data-testid="sidebar-content"
      className="sidebar__content"
    >
      <div className="sidebar__header">
        <Link to="/">
          <span className="u-sr-only">
            <Trans>Home</Trans>
          </span>
          <LogoEveryworks role="img" aria-label={_(msg`everyworks logo`)} />
        </Link>
        {closeButton}
      </div>
      {children}
    </div>
  );
}

export type SidebarWrapperProps = {
  children: ReactElement<typeof SidebarContent>;
};
function SidebarWrapper({ children }: SidebarWrapperProps) {
  const { isOpen, closeSidebar } = useContext(SidebarContext);

  const className = classNames(["sidebar", isOpen && "is-open"]);

  const closeOnEscape = useCallback(
    (event: KeyboardEvent) => {
      if (event.code === "Escape") closeSidebar();
    },
    [closeSidebar]
  );

  useEffect(() => {
    document.addEventListener("keydown", closeOnEscape);
    return () => {
      document.removeEventListener("keydown", closeOnEscape);
    };
  }, [closeOnEscape]);

  return (
    <div data-testid="sidebar" className={className} hidden={!isOpen}>
      <button className="sidebar__close-section" onClick={closeSidebar}>
        <span className="u-sr-only">
          <Trans>Close sidebar</Trans>
        </span>
      </button>
      {children}
    </div>
  );
}

const Sidebar = {
  Provider: SidebarProvider,
  Wrapper: SidebarWrapper,
  Content: SidebarContent,
  Button: SidebarButton,
};

export default Sidebar;
