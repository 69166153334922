import { msg, Trans } from "@lingui/macro";
import { useLingui } from "@lingui/react";
import React, { useState } from "react";

import { useSignInMutation } from "../graphql/__generated__/globalTypes";
import type { GraphQLEveryworksError } from "../graphql/EveryworksError";
import Button from "../shared/Button";
import ErrorMessage from "../shared/ErrorMessage";
import PageLayout from "../shared/PageLayout";
import useRedirectPathSearchParam from "../shared/useRedirectPathSearchParam";
import { getDeviceId, getDeviceName } from "../utils/detectUserBrowser";
import { isInternalAppPath } from "../utils/dima";
import { getDimaLogInPath, getExternalRedirectPath } from "../utils/dima";
import type { OnSubmitData } from "./LogInPage/LogInForm";
import LogInForm from "./LogInPage/LogInForm";

export default function LogInPage(): React.ReactElement {
  const { _ } = useLingui();
  let { redirectPath } = useRedirectPathSearchParam();
  const expectedErrors: { [key: number]: string } = {
    1004: _(msg`Account has been blocked.`),
    1031: _(msg`Account has been marked for deletion.`),
    1032: _(msg`Incorrect email or password.`),
  };

  const [loading, setLoading] = useState(false);

  const [signIn, { error: signInError, client }] = useSignInMutation({
    onError: console.error,
  });

  const maybeExternalRedirectPath = isInternalAppPath(redirectPath)
    ? getExternalRedirectPath(redirectPath)
    : redirectPath;

  const onSubmit = async (data: OnSubmitData) => {
    setLoading(true);

    try {
      const response = await signIn({
        variables: {
          email: data.email,
          password: data.password,
          deviceName: getDeviceName(),
          deviceIdentifier: getDeviceId(),
          redirectPath: maybeExternalRedirectPath,
        },
      });

      const token = response.data?.signIn?.token;

      if (token) {
        await client.cache.reset();
        window.location.href = getDimaLogInPath(token);
      }
    } finally {
      setLoading(false);
    }
  };

  let formError: string | undefined = undefined;

  if (signInError?.graphQLErrors[0]) {
    const error = signInError.graphQLErrors[0] as GraphQLEveryworksError;
    if (error.code) {
      formError = expectedErrors[error.code];
    }
  }

  let pageContent;
  if (signInError && !formError) {
    pageContent = <ErrorMessage error={signInError} />;
  } else {
    pageContent = (
      <>
        <LogInForm
          loading={loading}
          onSubmit={onSubmit}
          errorMessage={formError}
        />
        <Button to="/reset-password" color="ghost">
          <Trans>Reset password</Trans>
        </Button>
      </>
    );
  }

  return (
    <PageLayout title={_(msg`Login`)} backPath="/reservations/new/space">
      {pageContent}
    </PageLayout>
  );
}
