import type { Ref } from "react";
import React from "react";
import type { LinkProps as RouterLinkProps } from "react-router-dom";
import { Link } from "react-router-dom";

import classNames from "../utils/classNames";

type AnchorProps = Omit<
  React.AnchorHTMLAttributes<HTMLAnchorElement>,
  "className"
>;
type ButtonProps = Omit<
  React.ButtonHTMLAttributes<HTMLButtonElement>,
  "className"
>;
type LinkProps = Omit<RouterLinkProps, "className">;

type ButtonTargetProps = ButtonProps | AnchorProps | LinkProps;

function shouldRenderAsAnchor(
  props: ButtonTargetProps
): props is React.AnchorHTMLAttributes<HTMLAnchorElement> {
  return props.hasOwnProperty("href");
}

function shouldRenderAsLink(props: ButtonTargetProps): props is LinkProps {
  return props.hasOwnProperty("to");
}

export type Props = React.PropsWithChildren<
  {
    color?: keyof typeof COLOR_BUTTON_CLASSES;
    shape?: keyof typeof SHAPE_BUTTON_CLASSES;
  } & ButtonTargetProps
>;

const BASE_BUTTON_CLASS = "a-button";

const COLOR_BUTTON_CLASSES = {
  ghost: "a-button--ghost",
  menu: "a-button--menu",
  nav: "a-button--nav",
  primary: "a-button--primary",
  secondary: "a-button--secondary",
  transparent: "a-button--transparent",
  "dropdown-list": "a-button--dropdown-list",
  gallery: "a-button--gallery",
};

const SHAPE_BUTTON_CLASSES = {
  "link-list": "a-button--link-list",
  menu: "a-button--menu",
  round: "a-button--round",
  small: "a-button--small",
  square: "a-button--square",
};

/**
 * A button component which renders a link or a button.
 * It takes a `color` and a `shape` property and allows all permutations of them.
 */
const Button = React.forwardRef<HTMLButtonElement | HTMLAnchorElement, Props>(
  ({ color, shape, ...props }: Props, ref) => {
    const className = classNames([
      BASE_BUTTON_CLASS,
      color && COLOR_BUTTON_CLASSES[color],
      shape && SHAPE_BUTTON_CLASSES[shape],
    ]);

    if (shouldRenderAsAnchor(props)) {
      return (
        // eslint-disable-next-line jsx-a11y/anchor-has-content
        <a
          ref={ref as Ref<HTMLAnchorElement>}
          {...props}
          className={className}
        />
      );
    }

    if (shouldRenderAsLink(props)) {
      return (
        // eslint-disable-next-line jsx-a11y/anchor-has-content
        <Link
          ref={ref as Ref<HTMLAnchorElement>}
          {...props}
          className={className}
        />
      );
    }

    return (
      <button
        ref={ref as Ref<HTMLButtonElement>}
        {...(props as ButtonProps)}
        className={className}
      />
    );
  }
);

export default Button;
