import classNames from "../utils/classNames";

export type Props = React.PropsWithChildren<{
  className?: string;
}>;

export default function EmptyBox({ className, children }: Props) {
  return (
    <article className={classNames([className, "box box--empty u-h5"])}>
      {children}
    </article>
  );
}
