import React from "react";
import type { To } from "react-router-dom";
import { Link } from "react-router-dom";

import type { TitleTag } from "../utils/propTypes";

export type Props = {
  title: string;
  description: string;
  imageUrl: string;
  to: To;
  titleTag?: TitleTag;
};

/**
 * A promotional link looking similar to a reservable, meant to be rendered as the last item in a list of reservables in reservation suggestions.
 */
export default function ReservableListPlaceholderItem(props: Props) {
  const { title, description, imageUrl, to } = props;
  const TitleTag = props.titleTag || "h3";

  return (
    <Link to={to} className="reservable-list-item u-link-reset">
      <div
        className="reservable-list-item__image"
        style={{ backgroundImage: `url(${imageUrl})` }}
        aria-hidden={true}
      />
      <TitleTag className="reservable-list-item__title u-h4">{title}</TitleTag>
      <p>{description}</p>
    </Link>
  );
}
