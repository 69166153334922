import { useLingui } from "@lingui/react";
import React from "react";

import { encodeDate, formatDateInDefaultTimezone } from "../utils/date";

export type Props = {
  date: Date;
  withWeekday?: boolean;
} & React.HTMLAttributes<HTMLSpanElement>;

export default function FormattedDate({
  date,
  withWeekday,
}: Props): React.ReactElement {
  const { i18n } = useLingui();

  const dateString = formatDateInDefaultTimezone(i18n.locale, date, {
    withWeekday,
  });

  return <time dateTime={encodeDate(date)}>{dateString}</time>;
}
