import { useEffect } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";

import { useGetReservationSuggestionsQuery } from "../graphql/__generated__/globalTypes";

export type Props = {
  spaceId: string | null | undefined;
  reservableCategoryId: string | null | undefined;
  reservableTypeId: string | null | undefined;
  date: string | null | undefined;
  startTime: string | null | undefined;
  fromSlotIndex: number | null | undefined;
  endTime: string | null | undefined;
  toSlotIndex: number | null | undefined;
};

const useFindReservationSuggestionOrRedirect = (props: Props) => {
  const {
    spaceId,
    reservableCategoryId,
    reservableTypeId,
    date,
    startTime,
    fromSlotIndex,
    endTime,
    toSlotIndex,
  } = props;
  const [searchParams] = useSearchParams();
  const navigate = useNavigate();
  const reservableId = searchParams.get("reservableId");

  const shouldSkip =
    !spaceId || !date || !fromSlotIndex || !toSlotIndex || !reservableTypeId;

  const {
    data: reservationSuggestionsData,
    loading: reservationSuggestionLoading,
    error: reservationSuggestionError,
  } = useGetReservationSuggestionsQuery({
    skip: shouldSkip,
    variables: {
      spaceId: spaceId!,
      date: date,
      slotRange: { fromSlot: fromSlotIndex!, toSlot: toSlotIndex! },
      minCapacity: 1,
      reservableTypeId: reservableTypeId,
    },
  });

  const reservationSuggestion =
    reservationSuggestionsData?.reservationSuggestions?.matches?.find(
      (suggestion) => suggestion?.reservable?.id === reservableId
    );

  useEffect(() => {
    if (
      !shouldSkip &&
      !reservationSuggestionLoading &&
      !reservationSuggestionError
    ) {
      if (!reservationSuggestion) {
        navigate(
          `/reservations/new/suggestions?spaceId=${spaceId}&reservableCategoryId=${reservableCategoryId}&date=${date}&startTime=${startTime}&endTime=${endTime}`,
          {
            replace: true,
          }
        );
      }
    }
  }, [
    shouldSkip,
    reservationSuggestionLoading,
    reservationSuggestionError,
    reservationSuggestionsData,
    spaceId,
    reservableId,
    date,
    startTime,
    endTime,
    navigate,
    reservableCategoryId,
    reservationSuggestion,
  ]);

  return {
    reservationSuggestion,
    reservationSuggestionLoading,
    reservationSuggestionError,
  };
};

export default useFindReservationSuggestionOrRedirect;
