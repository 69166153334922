import { addDays } from "date-fns/addDays";
import { addMinutes } from "date-fns/addMinutes";
import { compareAsc } from "date-fns/compareAsc";
import { isValid } from "date-fns/isValid";
import { max } from "date-fns/max";
import { roundToNearestMinutes } from "date-fns/roundToNearestMinutes";

import type {
  ReservableCategory,
  Space,
} from "../graphql/__generated__/globalTypes";
import { findAndValidateOpeningHour } from "./openingHours";
import {
  formatTimeInDefaultTimezone,
  parseDateAndTimeInDefaultTimezone,
  slotDurationMinutes,
} from "./time";

export type TimeSlot = {
  index: number;
  startDate: Date;
  startTimeStringDefaultTimezone: string;
  endTimeStringDefaultTimezone: string;
};

/*
  Returns a list of all possible time slots on a given day, ignoring space's opening hours.
*/
export function getFullDayTimetable(dateString: string): TimeSlot[] {
  const localDayStart = parseDateAndTimeInDefaultTimezone(dateString, "00:00");
  const localDayEnd = addMinutes(
    parseDateAndTimeInDefaultTimezone(dateString, "23:59"),
    1
  );

  let timetable = [];
  let index = 0;
  let date = localDayStart;

  while (compareAsc(localDayEnd, date) >= 0) {
    let startDate = date;
    let endDate = addMinutes(startDate, slotDurationMinutes);
    timetable.push({
      index: index,
      startDate: date,
      startTimeStringDefaultTimezone: formatTimeInDefaultTimezone(startDate),
      endTimeStringDefaultTimezone: formatTimeInDefaultTimezone(endDate),
    });

    index += 1;
    date = addMinutes(localDayStart, slotDurationMinutes * index);
  }

  return timetable;
}

function getMinimumDurationSlots(reservableCategory: ReservableCategory) {
  return reservableCategory?.minimumDurationSlots || 2;
}

/*
Returns a list of all time slots on a given day that would be valid reservation start times for a given space.
*/
export function getValidReservationStartTimeSlots(
  dateString: string,
  space: Space,
  reservableCategory: ReservableCategory
) {
  const openingHour = findAndValidateOpeningHour(space, dateString);
  if (!openingHour) {
    return [];
  }

  const spaceOpeningDate = parseDateAndTimeInDefaultTimezone(
    dateString,
    openingHour.opensAt
  );

  let spaceClosingDate = parseDateAndTimeInDefaultTimezone(
    dateString,
    openingHour.closesAt
  );

  // spaces can close at midnight of next day
  if (openingHour.closesAt.startsWith("00:00")) {
    spaceClosingDate = addDays(spaceClosingDate, 1);
  }

  const earliestPossibleReservationStart = roundToNearestMinutes(
    spaceOpeningDate,
    { roundingMethod: "ceil", nearestTo: slotDurationMinutes }
  );
  const latestPossibleReservationStart = addMinutes(
    roundToNearestMinutes(spaceClosingDate, {
      roundingMethod: "floor",
      nearestTo: slotDurationMinutes,
    }),
    -1 * getMinimumDurationSlots(reservableCategory) * slotDurationMinutes
  );

  return getFullDayTimetable(dateString).filter(
    (timeSlot) =>
      compareAsc(earliestPossibleReservationStart, timeSlot.startDate) <= 0 &&
      compareAsc(latestPossibleReservationStart, timeSlot.startDate) >= 0
  );
}

/*
  Returns a list of all time slots on a given day that would be valid reservation end times for a given space.
*/
export function getValidReservationEndTimeSlots(
  dateString: string,
  space: Space,
  reservableCategory: ReservableCategory,
  reservationStartTimeString?: string
) {
  const openingHour = findAndValidateOpeningHour(space, dateString);
  if (!openingHour) {
    return [];
  }

  const spaceOpeningDate = parseDateAndTimeInDefaultTimezone(
    dateString,
    openingHour.opensAt
  );

  let spaceClosingDate = parseDateAndTimeInDefaultTimezone(
    dateString,
    openingHour.closesAt
  );

  // spaces can close at midnight of next day
  if (openingHour.closesAt.startsWith("00:00")) {
    spaceClosingDate = addDays(spaceClosingDate, 1);
  }

  let earliestPossibleReservationEnd = addMinutes(
    roundToNearestMinutes(spaceOpeningDate, {
      roundingMethod: "ceil",
      nearestTo: slotDurationMinutes,
    }),
    getMinimumDurationSlots(reservableCategory) * slotDurationMinutes
  );

  if (reservationStartTimeString) {
    earliestPossibleReservationEnd = max([
      earliestPossibleReservationEnd,
      addMinutes(
        parseDateAndTimeInDefaultTimezone(
          dateString,
          reservationStartTimeString
        ),
        getMinimumDurationSlots(reservableCategory) * slotDurationMinutes
      ),
    ]);
  }

  const latestPossibleReservationEnd = roundToNearestMinutes(spaceClosingDate, {
    roundingMethod: "floor",
    nearestTo: slotDurationMinutes,
  });

  return getFullDayTimetable(dateString).filter(
    (timeSlot) =>
      compareAsc(earliestPossibleReservationEnd, timeSlot.startDate) <= 0 &&
      compareAsc(latestPossibleReservationEnd, timeSlot.startDate) >= 0
  );
}

/*
  Checks if the given date and time strings represent a valid reservation start date on a specific day, of a specific space and reservable category.
 */
export function isValidReservationStart(
  dateString: string,
  timeString: string,
  space: Space,
  reservableCategory: ReservableCategory
) {
  const parsedDate = parseDateAndTimeInDefaultTimezone(dateString, timeString);
  if (!isValid(parsedDate)) {
    return false;
  }

  const timetable = getValidReservationStartTimeSlots(
    dateString,
    space,
    reservableCategory
  );

  return !!timetable.find(
    (timeSlot) => timeSlot.startTimeStringDefaultTimezone === timeString
  );
}

/*
  Checks if the given date and time strings represent a valid reservation end date on a specific day, of a specific space and reservable category.
 */
export function isValidReservationEnd(
  dateString: string,
  timeString: string,
  space: Space,
  reservableCategory: ReservableCategory,
  reservationStartTimeString?: string
) {
  const parsedDate = parseDateAndTimeInDefaultTimezone(dateString, timeString);
  if (!isValid(parsedDate)) {
    return false;
  }

  const timetable = getValidReservationEndTimeSlots(
    dateString,
    space,
    reservableCategory,
    reservationStartTimeString
  );

  return !!timetable.find(
    (timeSlot) => timeSlot.startTimeStringDefaultTimezone === timeString
  );
}

export function findTimeSlotByStartTime(
  dateString: string,
  timeString: string
) {
  const parsedDate = parseDateAndTimeInDefaultTimezone(dateString, timeString);
  if (!isValid(parsedDate)) {
    return null;
  }

  const timetable = getFullDayTimetable(dateString);

  return (
    timetable.find(
      (timeSlot) => timeSlot.startTimeStringDefaultTimezone === timeString
    ) || null
  );
}

export function findTimeSlotByEndTime(dateString: string, timeString: string) {
  const parsedDate = parseDateAndTimeInDefaultTimezone(dateString, timeString);
  if (!isValid(parsedDate)) {
    return null;
  }

  const timetable = getFullDayTimetable(dateString);

  return (
    timetable.find(
      (timeSlot) => timeSlot.endTimeStringDefaultTimezone === timeString
    ) || null
  );
}
