import { msg, Trans } from "@lingui/macro";
import { useLingui } from "@lingui/react";

import Button from "../shared/Button";
import PageLayout from "../shared/PageLayout";
import useRedirectPathSearchParam from "../shared/useRedirectPathSearchParam";

export default function LogInOrRegisterPage() {
  const { _ } = useLingui();
  const { redirectPath } = useRedirectPathSearchParam();
  const URIEncodedRedirectPath = encodeURIComponent(redirectPath);

  return (
    <PageLayout
      title={_(msg`Register or Login`)}
      backPath="/reservations/new/space"
    >
      <div className="button-group">
        <Button to={`/register?redirectPath=${URIEncodedRedirectPath}`}>
          <Trans>Register</Trans>
        </Button>
        <Button
          color="secondary"
          to={`/log-in?redirectPath=${URIEncodedRedirectPath}`}
        >
          <Trans>Login</Trans>
        </Button>
      </div>
    </PageLayout>
  );
}
