import type { ApolloLink, NormalizedCacheObject } from "@apollo/client";
import { ApolloClient, concat, HttpLink, InMemoryCache } from "@apollo/client";
import { setContext } from "@apollo/client/link/context";
import { i18n } from "@lingui/core";

import type { CSRF } from "../auth/csrf";

function compose(...links: ApolloLink[]): ApolloLink {
  return links.reduce((link, next) => concat(link, next));
}

class Client extends ApolloClient<NormalizedCacheObject> {
  constructor(csrf: CSRF) {
    const http = new HttpLink({ uri: "/api/web_app/v1/graphql" });

    const headers = setContext(async () => {
      if (!csrf.token) await csrf.update();
      return {
        headers: { "x-csrf-token": csrf.token, "accept-language": i18n.locale },
      };
    });

    const link = compose(headers, http);

    const cache = new InMemoryCache();

    super({
      cache,
      link,
      connectToDevTools: process.env.REACT_APP_APOLLO_DEV_TOOLS === "dev",
    });
  }
}

export default Client;
