import { getIntlLocale } from "./intl";

export function formatCents(locale: string, cents: number) {
  const currencyFormatter = new Intl.NumberFormat(getIntlLocale(locale), {
    style: "currency",
    currency: "EUR",
  });

  return currencyFormatter.format(cents / 100);
}
