export interface EveryworksCustomWindow extends Window {
  _appsignal_key: string;
  _app_revision: string;
  _tracking_enabled: boolean;
  _matomo_site_id: string;
  _db_tracker_src: string;
  DB_CMP: {
    showSecondLayer: () => void;
  };
}

declare let window: EveryworksCustomWindow;

const APP_CONFIG = {
  REACT_APP_APPSIGNAL_KEY: window._appsignal_key,
  REACT_APP_APP_REVISION: window._app_revision || "no_revision_found",
};

export default APP_CONFIG;
