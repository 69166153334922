import { msg } from "@lingui/macro";
import { useLingui } from "@lingui/react";

import type { Reservation } from "../../graphql/__generated__/globalTypes";
import { useGetMyReservationsQuery } from "../../graphql/__generated__/globalTypes";
import { ReservationState } from "../../graphql/__generated__/globalTypes";
import EmptyBox from "../../shared/EmptyBox";
import ErrorMessage from "../../shared/ErrorMessage";
import LoadingIndicator from "../../shared/LoadingIndicator";
import PageLayout from "../../shared/PageLayout";
import ReservationDetails from "../../shared/ReservationDetails";

type pageSectionProps = {
  reservations: Reservation[] | undefined;
  title: string;
  emptyMessage: string;
};

function PageSection({ reservations, title, emptyMessage }: pageSectionProps) {
  return (
    <article>
      <h2 className="reservations-list__title">{title}</h2>
      {(!reservations || reservations.length === 0) && (
        <EmptyBox>{emptyMessage}</EmptyBox>
      )}
      {reservations && reservations.length > 0 && (
        <ul className="u-list-none reservations-list reservations-list--items">
          {reservations.map((reservation, index) => {
            if (reservation) {
              return (
                <li key={index}>
                  <ReservationDetails
                    reservation={reservation}
                    space={reservation.reservable?.space!}
                  />
                </li>
              );
            } else {
              return null;
            }
          })}
        </ul>
      )}
    </article>
  );
}

export function isCurrentOrUpcomingEvent(reservation: Reservation): Boolean {
  if (
    !reservation.endsAt ||
    (reservation.state && reservation.state === ReservationState.Cancelled)
  )
    return false;

  const currentDate = new Date(Date());
  const eventEndDate = new Date(reservation.endsAt);
  if (eventEndDate > currentDate) return true;
  return false;
}

export function isPastOrCanceledEvent(reservation: Reservation) {
  if (!reservation.endsAt) return false;
  if (reservation.state && reservation.state === ReservationState.Cancelled)
    return true;

  const currentDate = new Date(Date());
  const eventEndDate = new Date(reservation.endsAt);
  if (eventEndDate <= currentDate) return true;
  return false;
}

export default function ReservationsPage(): React.ReactElement {
  const { _ } = useLingui();
  const { data, error, loading } = useGetMyReservationsQuery();

  let currentAndUpcoming = undefined;
  let pastAndCancelled = undefined;

  if (data && data.myReservations) {
    currentAndUpcoming = data.myReservations.filter(
      (reservation: Reservation | null) =>
        reservation && isCurrentOrUpcomingEvent(reservation)
    );

    pastAndCancelled = data.myReservations.filter(
      (reservation: Reservation | null) =>
        reservation && isPastOrCanceledEvent(reservation)
    );
  }

  return (
    <PageLayout
      title={_(msg`Your reservations`)}
      backPath="/reservations/new/space"
    >
      {loading && (
        <span className="loading-indicator-wrapper">
          <LoadingIndicator />
        </span>
      )}
      {error && <ErrorMessage error={error} />}
      {!loading && !error && (
        <div className="reservations-list">
          <PageSection
            reservations={currentAndUpcoming as Reservation[]}
            title={_(msg`Current and upcoming`)}
            emptyMessage={_(msg`- No current / upcoming bookings -`)}
          />
          <PageSection
            reservations={pastAndCancelled as Reservation[]}
            title={_(msg`Past and cancelled`)}
            emptyMessage={_(msg`- No past / cancelled bookings -`)}
          />
        </div>
      )}
    </PageLayout>
  );
}
