type ArrayClassNames = Array<String | null | undefined | false>;
type ObjectClassNames = { [k: string]: null | undefined | boolean };
export type ClassNames = ArrayClassNames | ObjectClassNames;

export default function classNames(input: ClassNames): string {
  if (Array.isArray(input)) {
    const unique = input.filter(
      (value, index, array) => array.indexOf(value) === index
    );
    return unique.filter((cn) => !!cn).join(" ");
  } else {
    const arrayClassNames = Object.keys(input).filter((cn) => !!input[cn]);
    return classNames(arrayClassNames);
  }
}
