import { gql } from "@apollo/client";

const query = gql`
  query me {
    me {
      id
      email
      unconfirmedEmail
      emailConfirmedAt
      paymentMethod {
        failing
        confirmedAt
      }
      profile {
        address
        city
        company
        country
        firstName
        lastName
        optionalInformation
        zip
      }
    }
  }
`;
export default query;
