import { Trans } from "@lingui/macro";
import { msg } from "@lingui/macro";
import { useLingui } from "@lingui/react";
import { useState } from "react";

import { useResetPasswordMutation } from "../graphql/__generated__/globalTypes";
import ButtonWithLoadingIndicator from "../shared/ButtonWithLoadingIndicator";
import PageLayout from "../shared/PageLayout";

const ResetPasswordPage = () => {
  const { _ } = useLingui();
  const [email, setEmail] = useState("");
  const [submitted, setSubmitted] = useState(false);

  const onEmailChange = (e: React.ChangeEvent<HTMLInputElement>) =>
    setEmail(e.target.value);

  const [resetPassword, { loading }] = useResetPasswordMutation();

  const onSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    await resetPassword({ variables: { email: email! } });
    setSubmitted(true);
  };

  return (
    <PageLayout
      title={_(msg`Reset password`)}
      backPath="/reservations/new/space"
    >
      {!submitted ? (
        <form className="form" onSubmit={onSubmit} data-testid="form">
          <div className="form__row">
            <label className="form__label" htmlFor="email">
              <Trans>Email</Trans>
            </label>
            <input
              className="form__input"
              id="email"
              type="email"
              required
              value={email}
              onChange={onEmailChange}
              autoComplete="email"
            />
          </div>

          <div className="form__row form__row--submit">
            <ButtonWithLoadingIndicator
              type="submit"
              loading={loading}
              data-testid="log-in-button"
            >
              <Trans>Reset password</Trans>
            </ButtonWithLoadingIndicator>
          </div>
        </form>
      ) : (
        <p>
          <Trans>
            An email with a link to change your password has been sent to your
            email address.
          </Trans>
        </p>
      )}
    </PageLayout>
  );
};
export default ResetPasswordPage;
