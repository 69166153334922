import { msg, Trans } from "@lingui/macro";
import { useLingui } from "@lingui/react";
import React from "react";

import { useAuth } from "../../auth/AuthContext";
import LogOutButton from "../../auth/LogOutButton";
import { onManageAnalytics } from "../../utils/analytics";
import Button from "../Button";
import { ReactComponent as LogoSmartCity } from "../images/logo-smartcity.svg";
import { getFooterLinks } from "./Footer";
import LocaleSwitcher from "./LocaleSwitcher";
import Sidebar from "./Sidebar";
import useProfileLinks from "./useProfileLinks";

function SidebarNav(): React.ReactElement {
  const { _ } = useLingui();
  const { account } = useAuth();
  const loggedIn = account!!;
  const profileLinks = useProfileLinks() || [];

  const footerLinkItems = getFooterLinks().map((link) => (
    <li key={_(link.href)}>
      <Button href={_(link.href)} color="menu" shape="menu">
        {_(link.title)}
      </Button>
    </li>
  ));

  const profileLinkItems = profileLinks.map((link) => (
    <li key={link.to || link.href}>
      <Button
        color="menu"
        shape="menu"
        {...(link.to ? { to: link.to } : { href: link.href })}
      >
        {_(link.title)}
      </Button>
    </li>
  ));

  return (
    <Sidebar.Provider>
      <Sidebar.Button />
      <Sidebar.Wrapper>
        <Sidebar.Content>
          <nav className="sidebar__body a-content" aria-label={_(msg`primary`)}>
            <ul className="u-list-none sidebar__menu">
              {loggedIn ? (
                <>
                  <li className="u-h2 sidebar__menu-profile">
                    <Trans>Hello, {account?.profile?.firstName}!</Trans>
                  </li>
                  {profileLinkItems}
                  <li>
                    <Button to="/reservations" color="menu" shape="menu">
                      <Trans>Your reservations</Trans>
                    </Button>
                  </li>
                  <li>
                    <LogOutButton color="menu" shape="menu" />
                  </li>
                </>
              ) : (
                <li>
                  <Button to="/log-in-or-register" color="menu" shape="menu">
                    <Trans>Login / Register</Trans>
                  </Button>
                </li>
              )}
              <li>
                <LocaleSwitcher buttonClassName="a-button a-button--menu" />
              </li>
              {footerLinkItems}
              <li>
                <Button color="menu" shape="menu" onClick={onManageAnalytics}>
                  <Trans>Manage analytics</Trans>
                </Button>
              </li>
              <li>
                <a
                  href={_(msg`https://smartcity.db.de/`)}
                  className="a-button a-button--menu"
                >
                  <span className="u-sr-only">
                    <Trans>SmartCity</Trans>
                  </span>
                  <LogoSmartCity
                    role="img"
                    aria-label={_(msg`SmartCity logo`)}
                  />
                </a>
              </li>
            </ul>
          </nav>
        </Sidebar.Content>
      </Sidebar.Wrapper>
    </Sidebar.Provider>
  );
}

export default SidebarNav;
