import type { EveryworksCustomWindow } from "../app.config";

declare let window: EveryworksCustomWindow;

export const onManageAnalytics = () => {
  try {
    (window as EveryworksCustomWindow).DB_CMP.showSecondLayer();
  } catch (error) {
    if (error instanceof Error) {
      const err = new Error(
        "Cannot open tracking consent layer. Is it configured correctly?"
      );
      err.stack = error.stack;
      throw err;
    }
  }
};
