import Appsignal from "@appsignal/javascript";
import { plugin as windowEventsPlugin } from "@appsignal/plugin-window-events";

import APP_CONFIG from "../app.config";

const appsignal = new Appsignal({
  key: APP_CONFIG.REACT_APP_APPSIGNAL_KEY,
  revision: APP_CONFIG.REACT_APP_APP_REVISION,
  ignoreErrors: [
    /Can't find variable: __gCrWeb/, // ignores error introduced by a chrome extension on ios
    /evaluating 'a\.L'/, // random error, don't know where it comes from but not from the app
    /evaluating 'n\.features'/, // random error, don't know where it comes from but not from the app
  ],
});

appsignal.use(windowEventsPlugin({}));

export default appsignal;
