import type { GraphQLErrors } from "@apollo/client/errors";
import { Trans } from "@lingui/macro";
import { useLingui } from "@lingui/react";
import React, { useEffect, useState } from "react";

import type {
  Profile,
  UpdateProfileMutationVariables,
} from "../../graphql/__generated__/globalTypes";
import { getValidationErrorForField } from "../../graphql/validationErrors";
import ButtonWithLoadingIndicator from "../../shared/ButtonWithLoadingIndicator";
import { getCountryCodesAndNames, loadCountryData } from "../../utils/country";

export type Props = {
  loading: boolean;
  profile?: Profile;
  errors?: GraphQLErrors;
  onSubmit: (data: UpdateProfileMutationVariables) => void;
};

loadCountryData();

type InputChangeEvent = React.ChangeEvent<HTMLInputElement>;

function getValidationError(
  errors: GraphQLErrors | undefined,
  field: string
): string | undefined {
  return errors && getValidationErrorForField(errors, field);
}

/**
 * A form for users to edit their profile. The fields are similar to the
 * billing-address section of the registration form.
 */
export default function EditProfileForm(props: Props): React.ReactElement {
  const { i18n } = useLingui();
  const { loading, profile, errors } = props;

  const countryCodesAndNames = getCountryCodesAndNames(i18n.locale);
  const countryCodes = Object.keys(countryCodesAndNames);

  const [firstName, setFirstName] = useState("");
  const onFirstNameChange = (e: InputChangeEvent) =>
    setFirstName(e.target.value);
  const firstNameErrorMessage = getValidationError(errors, "first_name");

  const [lastName, setLastName] = useState("");
  const onLastNameChange = (e: InputChangeEvent) => setLastName(e.target.value);
  const lastNameErrorMessage = getValidationError(errors, "last_name");

  const [company, setCompany] = useState("");
  const onCompanyChange = (e: InputChangeEvent) => setCompany(e.target.value);
  const companyErrorMessage = getValidationError(errors, "company");

  const [optionalInformation, setOptionalInformation] = useState("");
  const onOptionalInformationChange = (e: InputChangeEvent) =>
    setOptionalInformation(e.target.value);
  const optionalInformationErrorMessage = getValidationError(
    errors,
    "optional_information"
  );

  const [address, setAddress] = useState("");
  const onAddressChange = (e: InputChangeEvent) => setAddress(e.target.value);
  const addressErrorMessage = getValidationError(errors, "address");

  const [zip, setZip] = useState("");
  const onZipChange = (e: InputChangeEvent) => setZip(e.target.value);
  const zipErrorMessage = getValidationError(errors, "zip");

  const [city, setCity] = useState("");
  const onCityChange = (e: InputChangeEvent) => setCity(e.target.value);
  const cityErrorMessage = getValidationError(errors, "city");

  const [country, setCountry] = useState("");
  const onCountryChange = (e: React.ChangeEvent<HTMLSelectElement>) =>
    setCountry(e.target.value);
  const countryErrorMessage = getValidationError(errors, "country");

  // NOTE: This is done to have updates to the state work in Storybook
  useEffect(() => {
    if (profile) {
      setFirstName(profile.firstName || "");
      setLastName(profile.lastName || "");
      setCompany(profile.company || "");
      setOptionalInformation(profile.optionalInformation || "");
      setAddress(profile.address || "");
      setZip(profile.zip || "");
      setCity(profile.city || "");
      setCountry(profile.country || "");
    }
  }, [profile]);

  const onSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    props.onSubmit({
      firstName,
      lastName,
      company,
      optionalInformation,
      address,
      zip,
      city,
      country,
    });
  };

  return (
    <>
      <form method="post" action={``} onSubmit={onSubmit} className="form">
        <h2 className="u-h2">
          <Trans>Billing address</Trans>
        </h2>
        <div className="form__row">
          <label className="form__label" htmlFor="first_name">
            <Trans>First name</Trans>
          </label>
          <input
            id="first_name"
            type="text"
            name="first_name"
            value={firstName}
            onChange={onFirstNameChange}
            autoComplete="given-name"
            required
            aria-invalid={!!firstNameErrorMessage}
            aria-describedby={firstNameErrorMessage && "first-name-error"}
          />
          {firstNameErrorMessage && (
            <p id="first-name-error" className="form__error">
              {firstNameErrorMessage}
            </p>
          )}
        </div>
        <div className="form__row">
          <label className="form__label" htmlFor="last_name">
            <Trans>Last name</Trans>
          </label>
          <input
            id="last_name"
            type="text"
            name="last_name"
            value={lastName}
            onChange={onLastNameChange}
            autoComplete="family-name"
            required
            aria-invalid={lastNameErrorMessage ? true : false}
            aria-describedby={lastNameErrorMessage && "last-name-error"}
          />
          {lastNameErrorMessage && (
            <p id="last-name-error" className="form__error">
              {lastNameErrorMessage}
            </p>
          )}
        </div>
        <div className="form__row">
          <label className="form__label" htmlFor="company">
            <Trans>Company name (optional)</Trans>
          </label>
          <input
            id="company"
            type="text"
            name="company"
            value={company}
            onChange={onCompanyChange}
            autoComplete="organization"
            aria-invalid={companyErrorMessage ? true : false}
            aria-describedby={`company-hint ${companyErrorMessage ? "company-error" : ""}`}
          />
          <p className="form__input-hint" id="company-hint">
            <Trans>Only to be completed for companies based in Germany.</Trans>
          </p>
          {companyErrorMessage && (
            <p id="company-error" className="form__error">
              {companyErrorMessage}
            </p>
          )}
        </div>
        <div className="form__row">
          <label className="form__label" htmlFor="optional_information">
            <Trans>Additional company info (optional)</Trans>
          </label>
          <input
            id="optional_information"
            type="text"
            name="optional_information"
            value={optionalInformation}
            onChange={onOptionalInformationChange}
            aria-invalid={optionalInformationErrorMessage ? true : false}
            aria-describedby={`optional-information-hint ${optionalInformationErrorMessage ? "optional-information-error" : ""}`}
          />
          <p className="form__input-hint" id="optional-information-hint">
            <Trans>Only to be completed for companies based in Germany.</Trans>
          </p>
          {optionalInformationErrorMessage && (
            <p id="optional-information-error" className="form__error">
              {optionalInformationErrorMessage}
            </p>
          )}
        </div>
        <div className="form__row">
          <label className="form__label" htmlFor="address">
            <Trans>Street and house number</Trans>
          </label>
          <input
            id="address"
            type="text"
            name="address"
            value={address}
            onChange={onAddressChange}
            autoComplete="address-line1"
            required
            aria-invalid={addressErrorMessage ? true : false}
            aria-describedby={addressErrorMessage && "address-error"}
          />
          {addressErrorMessage && (
            <p id="address-error" className="form__error">
              {addressErrorMessage}
            </p>
          )}
        </div>
        <div className="form__row">
          <label className="form__label" htmlFor="zip">
            <Trans>Postal code</Trans>
          </label>
          <input
            id="zip"
            type="text"
            name="zip"
            value={zip}
            onChange={onZipChange}
            autoComplete="postal-code"
            required
            aria-invalid={zipErrorMessage ? true : false}
            aria-describedby={zipErrorMessage && "zip-error"}
          />
          {zipErrorMessage && (
            <p id="zip-error" className="form__error">
              {zipErrorMessage}
            </p>
          )}
        </div>
        <div className="form__row">
          <label className="form__label" htmlFor="address_city">
            <Trans>City</Trans>
          </label>
          <input
            id="address_city"
            type="text"
            name="address_city"
            value={city}
            onChange={onCityChange}
            autoComplete="address-level2"
            required
            aria-invalid={cityErrorMessage ? true : false}
            aria-describedby={cityErrorMessage && "city-error"}
          />
          {cityErrorMessage && (
            <p id="city-error" className="form__error">
              {cityErrorMessage}
            </p>
          )}
        </div>
        <div className="form__row">
          <label className="form__label" htmlFor="country">
            <Trans>Country</Trans>
          </label>
          <select
            id="country"
            name="country"
            value={country}
            onChange={onCountryChange}
            autoComplete="country-name"
            required
            aria-invalid={countryErrorMessage ? true : false}
            aria-describedby={countryErrorMessage && "country-error"}
          >
            <option disabled value="">
              <Trans>Please select a country</Trans>
            </option>
            {countryCodes.map((countryCode) => (
              <option key={countryCode} value={countryCode}>
                {countryCodesAndNames[countryCode]}
              </option>
            ))}
          </select>
          {countryErrorMessage && (
            <p id="country-error" className="form__error">
              {countryErrorMessage}
            </p>
          )}
        </div>
        <div className="form__row form__row--submit">
          <ButtonWithLoadingIndicator type="submit" loading={loading}>
            <Trans>Save my data</Trans>
          </ButtonWithLoadingIndicator>
        </div>
      </form>
    </>
  );
}
