import { Trans } from "@lingui/macro";

export type Props = {
  error?: string;
};

export default function ErrorPage({ error }: Props): React.ReactElement {
  return (
    <div className="error-page u-min-height-stretch a-content a-content--m">
      <div className="error-page__header">
        <h1>
          <Trans>Something went wrong</Trans>
        </h1>
        {error && <p>{error}</p>}
      </div>
      <a href={process.env.PUBLIC_URL} className="a-button">
        <Trans>Go to start screen</Trans>
      </a>
    </div>
  );
}
