import { formatInTimeZone, toDate } from "date-fns-tz";

// those two values are also hardcoded in the backend
export const defaultTimezone = "Europe/Berlin";
export const slotDurationMinutes = 30;

export function parseDateAndTimeInDefaultTimezone(
  dateString: string,
  timeString: string
) {
  const dateTimeString = `${dateString}T${timeString}`;
  return toDate(dateTimeString, { timeZone: defaultTimezone });
}

export function formatTimeInDefaultTimezone(date: Date) {
  return formatInTimeZone(date, defaultTimezone, "HH:mm");
}

export function formatTimezone(date: Date) {
  return formatInTimeZone(date, defaultTimezone, "O");
}
