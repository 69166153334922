import { de, enGB } from "date-fns/locale";
import { formatInTimeZone } from "date-fns-tz";

import { defaultTimezone } from "./time";

type FormatDateOptions = {
  withWeekday?: boolean;
};

export function formatDateInDefaultTimezone(
  locale: string,
  date: Date,
  options?: FormatDateOptions
) {
  let formatString = "";
  let dateFnsLocale;

  if (locale === "en") {
    formatString =
      options && options.withWeekday ? "EEEE, dd/MM/yyyy" : "dd/MM/yyyy";
    dateFnsLocale = enGB;
  } else {
    formatString =
      options && options.withWeekday ? "EEEE, dd.MM.yyyy" : "dd.MM.yyyy";
    dateFnsLocale = de;
  }

  return formatInTimeZone(date, defaultTimezone, formatString, {
    locale: dateFnsLocale,
  });
}

export function formatWeekdayInDefaultTimezone(locale: string, date: Date) {
  const formatString = "EEEE";
  const dateFnsLocale = locale === "en" ? enGB : de;

  return formatInTimeZone(date, defaultTimezone, formatString, {
    locale: dateFnsLocale,
  });
}

export function decodeDate(string: string) {
  return new Date(string);
}

export function encodeDate(date: Date) {
  return date.toISOString().split("T")[0];
}

export function isValidEncodedDate(string: string | null | undefined) {
  // This doesn't work: isMatch(string, "yyyy-MM-dd");
  // because date-fns accepts non-zero-padded month/day numbers even though 'MM-dd' 'implies zero-padding
  try {
    return !!string && encodeDate(decodeDate(string)) === string;
  } catch {
    return false;
  }
}
