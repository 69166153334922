const endpoint = "/api/app/v1/.csrf";

class HTTPError extends Error {
  public response: Response;

  constructor(response: Response) {
    const code = response.status ?? "";
    const title = response.statusText ?? "";

    super(`${code} ${title}`.trim());

    this.name = "HTTPError";
    this.response = response;
  }
}

export class CSRF {
  token: string | null = null;
  request: Promise<void> | null = null;

  async update() {
    if (this.request) return this.request;

    this.request = (async () => {
      try {
        const response = await fetch(endpoint);
        if (!response.ok) throw new HTTPError(response);
        const data = await response.json();
        this.token = data.csrfToken;
      } finally {
        this.request = null;
      }
    })();

    return this.request;
  }

  clear() {
    this.token = null;
  }
}
const csrf = new CSRF();
export default csrf;
