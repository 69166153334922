import { msg, Trans } from "@lingui/macro";
import { useLingui } from "@lingui/react";
import { useParams } from "react-router-dom";

import Button from "../../shared/Button";
import PageLayout from "../../shared/PageLayout";

const ReservationConfirmationPage = () => {
  const { _ } = useLingui();
  const { id } = useParams();

  return (
    <PageLayout
      title={_(msg`Booking successful! Confirmation will follow.`)}
      backPath="/reservations/new/space"
    >
      <div className="reservation-confirmation-page">
        <p>
          <Trans>
            Your booking was successful. You will receive a confirmation and
            your access pass by email in a few minutes.
          </Trans>
        </p>
        <Button to={`/reservations/${id}`}>
          <Trans>Okay, continue!</Trans>
        </Button>
      </div>
    </PageLayout>
  );
};
export default ReservationConfirmationPage;
