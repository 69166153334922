import { useLingui } from "@lingui/react";
import React from "react";

import { formatCents } from "../utils/money";

export type Props = {
  cents: number;
} & React.HTMLAttributes<HTMLSpanElement>;

export default function FormattedMoney({ cents }: Props): React.ReactElement {
  const { i18n } = useLingui();

  const centsString = formatCents(i18n.locale, cents);

  return <span>{centsString}</span>;
}
