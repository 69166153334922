import React from "react";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import { Navigate } from "react-router-dom";

import ErrorBoundary from "./appsignal/ErrorBoundary";
import { AuthContextProvider } from "./auth/AuthContext";
import ProtectedRoute from "./auth/ProtectedRoute";
import EditEmailPage from "./pages/EditEmailPage";
import EditProfilePage from "./pages/EditProfilePage";
import EmailConfirmationPage from "./pages/EmailConfirmationPage";
import ErrorPage from "./pages/ErrorPage";
import LogInOrRegisterPage from "./pages/LogInOrRegisterPage";
import LogInPage from "./pages/LogInPage";
import NotFoundPage from "./pages/NotFoundPage";
import RegisterPage from "./pages/RegisterPage";
import CancelReservationPage from "./pages/Reservations/CancelReservationPage";
import DateSelectionPage from "./pages/Reservations/New/DateSelectionPage";
import ReservableCategorySelectionPage from "./pages/Reservations/New/ReservableCategorySelectionPage";
import ReservableDetailsPage from "./pages/Reservations/New/ReservableDetailsPage";
import SpaceSelectionPage from "./pages/Reservations/New/SpaceSelectionPage";
import SubmitReservationPage from "./pages/Reservations/New/SubmitReservationPage";
import SuggestionsPage from "./pages/Reservations/New/SuggestionsPage";
import ReservationConfirmationPage from "./pages/Reservations/ReservationConfirmationPage";
import ReservationPage from "./pages/Reservations/ReservationPage";
import ReservationsPage from "./pages/Reservations/ReservationsPage";
import ResetPasswordPage from "./pages/ResetPasswordPage";
import type { Props } from "./Wrapper";
import Wrapper from "./Wrapper";

function App(props: Props) {
  return (
    <Wrapper {...props}>
      <AuthContextProvider>
        <BrowserRouter basename={process.env.PUBLIC_URL}>
          <ErrorBoundary fallback={() => <ErrorPage />}>
            <Routes>
              <Route path="*" element={<NotFoundPage />} />
              <Route
                path="/log-in-or-register"
                element={<LogInOrRegisterPage />}
              />
              <Route path="/log-in" element={<LogInPage />} />
              <Route path="/register" element={<RegisterPage />} />
              <Route path="/reset-password" element={<ResetPasswordPage />} />
              <Route
                path="/"
                element={<Navigate to="/reservations/new" replace />}
              />
              <Route
                path="/reservations/new"
                element={<Navigate to="/reservations/new/space" replace />}
              />
              <Route
                path="/reservations/new/space"
                element={<SpaceSelectionPage />}
              />
              <Route
                path="/reservations/new/category/"
                element={<ReservableCategorySelectionPage />}
              />
              <Route
                path="/reservations/new/date"
                element={<DateSelectionPage />}
              />
              <Route
                path="/reservations/new/suggestions"
                element={<SuggestionsPage />}
              />
              <Route
                path="/reservations/new/submit"
                element={<SubmitReservationPage />}
              />
              <Route
                path="/reservations/new/details"
                element={<ReservableDetailsPage />}
              />
              <Route element={<ProtectedRoute />}>
                <Route
                  path="/email/confirm"
                  element={<EmailConfirmationPage />}
                />
                <Route path="/email/edit" element={<EditEmailPage />} />
                <Route path="/profile/edit" element={<EditProfilePage />} />
                <Route path="/reservations" element={<ReservationsPage />} />
                <Route path="/reservations/:id" element={<ReservationPage />} />
                <Route
                  path="/reservations/:id/cancel"
                  element={<CancelReservationPage />}
                />
                <Route
                  path="/reservations/:id/created"
                  element={<ReservationConfirmationPage />}
                />
              </Route>
            </Routes>
          </ErrorBoundary>
        </BrowserRouter>
      </AuthContextProvider>
    </Wrapper>
  );
}

export default App;
