import { msg } from "@lingui/macro";
import { useLingui } from "@lingui/react";

import ErrorMessage from "../../shared/ErrorMessage";
import LoadingIndicator from "../../shared/LoadingIndicator";
import PageLayout from "../../shared/PageLayout";
import ReservationDetails from "../../shared/ReservationDetails";
import useFindMyReservationOrRedirect from "../../utils/useFindMyReservationOrRedirect";

const ReservationPage = () => {
  const { _ } = useLingui();

  const { reservation, loading, error } = useFindMyReservationOrRedirect();

  return (
    <PageLayout
      title={_(msg`Your Reservation`)}
      backPath="/reservations/new/space"
    >
      {loading && (
        <span className="loading-indicator-wrapper">
          <LoadingIndicator />
        </span>
      )}
      {error && <ErrorMessage error={error} />}
      {!loading && !error && reservation && (
        <ReservationDetails
          reservation={reservation}
          space={reservation?.reservable?.space!}
        />
      )}
    </PageLayout>
  );
};
export default ReservationPage;
