import { msg, plural, Trans } from "@lingui/macro";
import { useLingui } from "@lingui/react";
import { addDays } from "date-fns/addDays";
import React, { useState } from "react";
import { useNavigate } from "react-router-dom";

import type {
  ReservableCategory,
  Space,
} from "../../../../graphql/__generated__/globalTypes";
import Button from "../../../../shared/Button";
import SelectWithLabel from "../../../../shared/SelectWithLabel";
import { uniqueBy } from "../../../../utils/array";
import {
  encodeDate,
  formatWeekdayInDefaultTimezone,
  isValidEncodedDate,
} from "../../../../utils/date";
import { formatNumber } from "../../../../utils/number";
import {
  findAndValidateOpeningHour,
  isoDayOfWeek,
} from "../../../../utils/openingHours";
import {
  formatTimeInDefaultTimezone,
  parseDateAndTimeInDefaultTimezone,
  slotDurationMinutes,
} from "../../../../utils/time";
import {
  getFullDayTimetable,
  getValidReservationEndTimeSlots,
  getValidReservationStartTimeSlots,
  isValidReservationEnd,
  isValidReservationStart,
} from "../../../../utils/timeSlots";

export type Props = {
  space: Space;
  reservableCategory: ReservableCategory;
  allReservableCategoriesOfSameType: ReservableCategory[];
  dateInitialValue?: string | null;
  startTimeInitialValue?: string | null;
  endTimeInitialValue?: string | null;
};

function isNumber(item: number | null | undefined): item is number {
  return !!item;
}

function numbersAsc(n1: number, n2: number): number {
  return n1 - n2;
}

export default function DateSelectionForm(props: Props) {
  const { i18n, _ } = useLingui();
  const navigate = useNavigate();

  // when the option has an actual empty string as a value,
  // it will not be selected on initial render
  // so we're using this dummy empty value to force the "Choose a date first" option to be preselected
  const dummyEmptyValue = "-";

  const emptyOption = { value: "", label: "", disabled: false };
  const dateMissingOption = {
    value: dummyEmptyValue,
    label: _(msg`Choose a date first`),
    disabled: true,
  };
  const startTimeMissingOption = {
    value: dummyEmptyValue,
    label: _(msg`Choose a start time first`),
    disabled: true,
  };

  const { space, reservableCategory, allReservableCategoriesOfSameType } =
    props;

  const minimumDurationsSorted = uniqueBy(
    allReservableCategoriesOfSameType,
    (category) => category.minimumDurationSlots
  )
    .map((category) => category.minimumDurationSlots)
    .filter(isNumber)
    .sort(numbersAsc);

  const reservableCategoryWithSmallestMinimumDuration =
    allReservableCategoriesOfSameType.find(
      (category) => category.minimumDurationSlots === minimumDurationsSorted[0]
    )!;

  const dateInitialValue =
    props.dateInitialValue && isValidEncodedDate(props.dateInitialValue)
      ? props.dateInitialValue
      : "";
  const startTimeInitialValue =
    props.startTimeInitialValue &&
    isValidReservationStart(
      dateInitialValue,
      props.startTimeInitialValue,
      space,
      reservableCategoryWithSmallestMinimumDuration
    )
      ? props.startTimeInitialValue
      : dateMissingOption.value;

  const endTimeInitialValue =
    props.endTimeInitialValue &&
    isValidReservationEnd(
      dateInitialValue,
      props.endTimeInitialValue,
      space,
      reservableCategoryWithSmallestMinimumDuration
    )
      ? props.endTimeInitialValue
      : dateMissingOption.value;

  const [date, setDate] = useState(dateInitialValue);
  const [startTime, setStartTime] = useState(startTimeInitialValue);
  const [endTime, setEndTime] = useState(endTimeInitialValue);

  const today = new Date();
  const fullDayTimetable = date ? getFullDayTimetable(date) : [];

  let startTimeOptions = date
    ? [
        emptyOption,
        ...getValidReservationStartTimeSlots(
          date,
          space,
          reservableCategoryWithSmallestMinimumDuration
        ).map((timeSlot) => ({
          value: timeSlot.startTimeStringDefaultTimezone,
          label: timeSlot.startTimeStringDefaultTimezone,
          disabled: false,
        })),
      ]
    : [dateMissingOption];

  let endTimeOptions: Array<typeof emptyOption> = [];
  if (date && startTime && startTime !== dummyEmptyValue) {
    endTimeOptions = [
      emptyOption,
      ...getValidReservationEndTimeSlots(
        date,
        space,
        reservableCategoryWithSmallestMinimumDuration,
        startTime
      ).map((timeSlot) => ({
        value: timeSlot.startTimeStringDefaultTimezone,
        label: timeSlot.startTimeStringDefaultTimezone,
        disabled: false,
      })),
    ];
  } else if (date) {
    endTimeOptions = [startTimeMissingOption];
  } else {
    endTimeOptions = [dateMissingOption];
  }

  const minimumBookingDurationSnippets = minimumDurationsSorted
    .map((durationSlots) => {
      const durationHours =
        durationSlots && durationSlots / (60 / slotDurationMinutes);
      return plural(durationHours!, {
        one: `${formatNumber(i18n.locale, durationHours!)} hour`,
        other: `${formatNumber(i18n.locale, durationHours!)} hours`,
      });
    })
    .join(_(msg`, or`) + " ");

  const minimumBookingDurationSentence = _(
    msg`The minimum booking duration is ${minimumBookingDurationSnippets}.`
  );

  const dateOpeningHour = date ? findAndValidateOpeningHour(space, date) : null;

  const spaceClosedOnDate = date ? !dateOpeningHour : false;
  let spaceClosedErrorMessage;
  if (spaceClosedOnDate) {
    const dayOfWeekExpressions = [
      _(msg`on Mondays`),
      _(msg`on Tuesdays`),
      _(msg`on Wednesdays`),
      _(msg`on Thursdays`),
      _(msg`on Fridays`),
      _(msg`on Saturdays`),
      _(msg`on Sundays`),
    ];

    const dayOfWeekExpression = dayOfWeekExpressions[isoDayOfWeek(date) - 1];
    spaceClosedErrorMessage = _(
      msg`This location is not open ${dayOfWeekExpression}.`
    );
  }

  const formatOpeningHour = (time: string) =>
    formatTimeInDefaultTimezone(parseDateAndTimeInDefaultTimezone(date, time));

  const onDateChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    // Safari allows changing values of date inputs to invalid dates, e.g. 33333-12-31
    if (isValidEncodedDate(e.target.value)) {
      setDate(e.target.value);
    }
  };

  const onStartTimeChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
    const newStartTime = e.target.value;

    // maybe move endTime by the same number of slots as startTime changed
    if (startTime && endTime) {
      const newStartTimeIndexShift =
        fullDayTimetable.findIndex(
          (slot) => slot.startTimeStringDefaultTimezone === newStartTime
        ) -
        fullDayTimetable.findIndex(
          (slot) => slot.startTimeStringDefaultTimezone === startTime
        );
      const newEndTime =
        fullDayTimetable[
          fullDayTimetable.findIndex(
            (slot) => slot.startTimeStringDefaultTimezone === endTime
          ) + newStartTimeIndexShift
        ];
      setEndTime(newEndTime?.startTimeStringDefaultTimezone);
    }

    setStartTime(newStartTime);
  };

  const onEndTimeChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
    const newEndTime = e.target.value;
    setEndTime(newEndTime);
  };

  const handleFormSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    navigate(
      `/reservations/new/suggestions?spaceId=${space.id}&reservableCategoryId=${reservableCategory.id}&date=${date}&startTime=${startTime}&endTime=${endTime}`
    );
  };

  return (
    <form
      className="form"
      method="get"
      onSubmit={handleFormSubmit}
      data-testid="date-selection-form"
    >
      <input type="hidden" name="spaceId" value={space.id || ""} />
      <input
        type="hidden"
        name="reservableCategoryId"
        value={reservableCategory.id || ""}
      />

      <div className="form__row">
        <label className="form__label" htmlFor="date">
          <Trans>Date</Trans>
        </label>
        <input
          id="date"
          type="date"
          name="date"
          required
          value={date}
          onChange={onDateChange}
          min={encodeDate(today)}
          max={encodeDate(addDays(today, space?.maxFutureReservationDays!))}
        />
      </div>

      <div className="form__row">
        {spaceClosedOnDate ? (
          <p
            className="form__input-hint form__input-hint--bigger"
            data-testid="space-closed-message"
          >
            {spaceClosedErrorMessage}
          </p>
        ) : null}
        {dateOpeningHour ? (
          <p
            className="form__input-hint form__input-hint--bigger"
            data-testid="space-opening-hours"
          >
            <Trans>
              Opening hours:{" "}
              {formatWeekdayInDefaultTimezone(
                i18n.locale,
                parseDateAndTimeInDefaultTimezone(date, "00:00")
              )}
              , {formatOpeningHour(dateOpeningHour.opensAt)} -{" "}
              {formatOpeningHour(dateOpeningHour.closesAt)}
            </Trans>
          </p>
        ) : null}
      </div>

      <fieldset>
        <div className="form__row">
          <legend className="form__label">
            <Trans>Time</Trans>
          </legend>
          <div className="form__input-group">
            <SelectWithLabel
              id="startTime"
              name="startTime"
              required
              value={startTime}
              onChange={onStartTimeChange}
              label={_(msg`From`)}
              aria-describedby="start-time-hint"
            >
              {startTimeOptions.map((option) => (
                <option
                  key={option.value}
                  value={option.value}
                  disabled={option.disabled}
                >
                  {option.label}
                </option>
              ))}
            </SelectWithLabel>
            <SelectWithLabel
              id="endTime"
              name="endTime"
              required
              value={endTime}
              onChange={onEndTimeChange}
              label={_(msg`To`)}
            >
              {endTimeOptions.map((option) => (
                <option
                  key={option.value}
                  value={option.value}
                  disabled={option.disabled}
                >
                  {option.label}
                </option>
              ))}
            </SelectWithLabel>
          </div>

          <div
            className="form__input-hint form__input-hint--bigger"
            id="start-time-hint"
          >
            <p data-testid="minimum-meeting-duration">
              {minimumBookingDurationSentence}
            </p>
          </div>
        </div>
      </fieldset>

      <div className="form__row form__row--submit">
        <Button type="submit" data-testid="submit-button">
          <Trans>Search</Trans>
        </Button>
      </div>
    </form>
  );
}
