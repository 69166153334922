import type { GraphQLErrors } from "@apollo/client/errors";
import { Trans } from "@lingui/macro";
import { useLingui } from "@lingui/react";
import React, { useState } from "react";

import { getValidationErrorForField } from "../../graphql/validationErrors";
import ButtonWithLoadingIndicator from "../../shared/ButtonWithLoadingIndicator";
import { privacyLink, termsLink } from "../../shared/PageLayout/Footer";
import { getCountryCodesAndNames, loadCountryData } from "../../utils/country";

export type OnSubmitData = {
  email: string;
  password: string;
  profile: {
    firstName: string;
    lastName: string;
    company: string;
    optionalInformation: string;
    address: string;
    zip: string;
    city: string;
    country: string;
  };
};

export type Props = {
  loading: boolean;
  errors?: GraphQLErrors;
  onSubmit: (data: OnSubmitData) => void;
};

loadCountryData();

export default function RegisterForm(props: Props) {
  const { _, i18n } = useLingui();
  const { loading, errors } = props;

  const countryCodesAndNames = getCountryCodesAndNames(i18n.locale);
  const countryCodes = Object.keys(countryCodesAndNames);

  // Billing address
  const [firstName, setFirstName] = useState("");
  const onFirstNameChange = (e: React.ChangeEvent<HTMLInputElement>) =>
    setFirstName(e.target.value);
  const firstNameErrorMessage =
    errors && getValidationErrorForField(errors, "profile.first_name");

  const [lastName, setLastName] = useState("");
  const onLastNameChange = (e: React.ChangeEvent<HTMLInputElement>) =>
    setLastName(e.target.value);
  const lastNameErrorMessage =
    errors && getValidationErrorForField(errors, "profile.last_name");

  const [company, setCompany] = useState("");
  const onCompanyChange = (e: React.ChangeEvent<HTMLInputElement>) =>
    setCompany(e.target.value);
  const companyErrorMessage =
    errors && getValidationErrorForField(errors, "profile.company");

  const [optionalInformation, setOptionalInformation] = useState("");
  const onOptionalInformationChange = (
    e: React.ChangeEvent<HTMLInputElement>
  ) => setOptionalInformation(e.target.value);
  const optionalInformationErrorMessage =
    errors &&
    getValidationErrorForField(errors, "profile.optional_information");

  const [address, setStreetAddress] = useState("");
  const onAddressChange = (e: React.ChangeEvent<HTMLInputElement>) =>
    setStreetAddress(e.target.value);
  const addressErrorMessage =
    errors && getValidationErrorForField(errors, "profile.address");

  const [zip, setZip] = useState("");
  const onZipChange = (e: React.ChangeEvent<HTMLInputElement>) =>
    setZip(e.target.value);
  const zipErrorMessage =
    errors && getValidationErrorForField(errors, "profile.zip");

  const [city, setCity] = useState("");
  const onCityChange = (e: React.ChangeEvent<HTMLInputElement>) =>
    setCity(e.target.value);
  const cityErrorMessage =
    errors && getValidationErrorForField(errors, "profile.city");

  const [country, setCountry] = useState("DE");
  const onCountryChange = (e: React.ChangeEvent<HTMLSelectElement>) =>
    setCountry(e.target.value);
  const countryErrorMessage =
    errors && getValidationErrorForField(errors, "profile.country");

  // Login credentials
  const [email, setEmail] = useState("");
  const onEmailChange = (e: React.ChangeEvent<HTMLInputElement>) =>
    setEmail(e.target.value);
  const emailErrorMessage =
    errors && getValidationErrorForField(errors, "email");

  const [password, setPassword] = useState("");
  const onPasswordChange = (e: React.ChangeEvent<HTMLInputElement>) =>
    setPassword(e.target.value);
  const passwordErrorMessage =
    errors && getValidationErrorForField(errors, "password");

  // Other
  const onSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    props.onSubmit({
      profile: {
        firstName,
        lastName,
        company,
        optionalInformation,
        address,
        zip,
        city,
        country,
      },
      email,
      password,
    });
  };

  return (
    <form
      method="post"
      action={``}
      data-testid="registration-form"
      onSubmit={onSubmit}
      className="form"
    >
      <fieldset className="form__row">
        <div className="form">
          <legend className="u-h2">
            <Trans>Billing address</Trans>
          </legend>
          <div className="form__section">
            <div className="form__row">
              <label className="form__label" htmlFor="profile.first_name">
                <Trans>First name</Trans>
              </label>
              <input
                id="profile.first_name"
                type="text"
                name="profile.first_name"
                value={firstName}
                onChange={onFirstNameChange}
                autoComplete="given-name"
                required
                aria-invalid={!!firstNameErrorMessage}
                aria-describedby={firstNameErrorMessage && "first-name-error"}
              />
              {firstNameErrorMessage && (
                <p id="first-name-error" className="form__error">
                  {firstNameErrorMessage}
                </p>
              )}
            </div>
            <div className="form__row">
              <label className="form__label" htmlFor="profile.last_name">
                <Trans>Last name</Trans>
              </label>
              <input
                id="profile.last_name"
                type="text"
                name="profile.last_name"
                value={lastName}
                onChange={onLastNameChange}
                autoComplete="family-name"
                required
                aria-invalid={lastNameErrorMessage ? true : false}
                aria-describedby={lastNameErrorMessage && "last-name-error"}
              />
              {lastNameErrorMessage && (
                <p id="last-name-error" className="form__error">
                  {lastNameErrorMessage}
                </p>
              )}
            </div>
          </div>
          <div className="form__section">
            <div className="form__row">
              <label className="form__label" htmlFor="profile.company">
                <Trans>Company name (optional)</Trans>
              </label>
              <input
                id="profile.company"
                type="text"
                name="profile.company"
                value={company}
                onChange={onCompanyChange}
                autoComplete="organization"
                aria-invalid={companyErrorMessage ? true : false}
                aria-describedby={`company-hint ${companyErrorMessage ? "company-error" : ""}`}
              />
              <p className="form__input-hint" id="company-hint">
                <Trans>
                  Only to be completed for companies based in Germany.
                </Trans>
              </p>
              {companyErrorMessage && (
                <p id="company-error" className="form__error">
                  {companyErrorMessage}
                </p>
              )}
            </div>
            <div className="form__row">
              <label
                className="form__label"
                htmlFor="profile.optional_information"
              >
                <Trans>Additional company info (optional)</Trans>
              </label>
              <input
                id="profile.optional_information"
                type="text"
                name="profile.optional_information"
                value={optionalInformation}
                onChange={onOptionalInformationChange}
                aria-invalid={optionalInformationErrorMessage ? true : false}
                aria-describedby={`optional-information-hint ${optionalInformationErrorMessage ? "optional-information-error" : ""}`}
              />
              <p className="form__input-hint" id="optional-information-hint">
                <Trans>
                  Only to be completed for companies based in Germany.
                </Trans>
              </p>
              {optionalInformationErrorMessage && (
                <p id="optional-information-error" className="form__error">
                  {optionalInformationErrorMessage}
                </p>
              )}
            </div>
          </div>
          <div className="form__section">
            <div className="form__row">
              <label className="form__label" htmlFor="profile.address">
                <Trans>Street and house number</Trans>
              </label>
              <input
                id="profile.address"
                type="text"
                name="profile.address"
                value={address}
                onChange={onAddressChange}
                autoComplete="address-line1"
                required
                aria-invalid={addressErrorMessage ? true : false}
                aria-describedby={addressErrorMessage && "address-error"}
              />
              {addressErrorMessage && (
                <p id="address-error" className="form__error">
                  {addressErrorMessage}
                </p>
              )}
            </div>
            <div className="form__row">
              <label className="form__label" htmlFor="profile.zip">
                <Trans>Postal code</Trans>
              </label>
              <input
                id="profile.zip"
                type="text"
                name="profile.zip"
                value={zip}
                onChange={onZipChange}
                autoComplete="postal-code"
                required
                aria-invalid={zipErrorMessage ? true : false}
                aria-describedby={zipErrorMessage && "zip-error"}
              />
              {zipErrorMessage && (
                <p id="zip-error" className="form__error">
                  {zipErrorMessage}
                </p>
              )}
            </div>
            <div className="form__row">
              <label className="form__label" htmlFor="address_city">
                <Trans>City</Trans>
              </label>
              <input
                id="address_city"
                type="text"
                name="address_city"
                value={city}
                onChange={onCityChange}
                autoComplete="address-level2"
                required
                aria-invalid={cityErrorMessage ? true : false}
                aria-describedby={cityErrorMessage && "city-error"}
              />
              {cityErrorMessage && (
                <p id="city-error" className="form__error">
                  {cityErrorMessage}
                </p>
              )}
            </div>
            <div className="form__row">
              <label className="form__label" htmlFor="profile.country">
                <Trans>Country</Trans>
              </label>
              <select
                id="profile.country"
                name="profile.country"
                value={country}
                onChange={onCountryChange}
                autoComplete="country-name"
                required
                aria-invalid={countryErrorMessage ? true : false}
                aria-describedby={countryErrorMessage && "country-error"}
              >
                <option disabled value="">
                  <Trans>Please select a country</Trans>
                </option>
                {countryCodes.map((countryCode) => (
                  <option key={countryCode} value={countryCode}>
                    {countryCodesAndNames[countryCode]}
                  </option>
                ))}
              </select>
              {countryErrorMessage && (
                <p id="country-error" className="form__error">
                  {countryErrorMessage}
                </p>
              )}
            </div>
          </div>
        </div>
      </fieldset>
      <fieldset className="form__row">
        <div className="form">
          <legend className="u-h2">
            <Trans>Email and password</Trans>
          </legend>
          <div className="form__row">
            <label className="form__label" htmlFor="email">
              <Trans>Email address</Trans>
            </label>
            <input
              id="email"
              type="email"
              name="email"
              value={email}
              onChange={onEmailChange}
              autoComplete="email"
              required
              aria-invalid={emailErrorMessage ? true : false}
              aria-describedby={emailErrorMessage && "email-error"}
            />
            {emailErrorMessage && (
              <p id="email-error" className="form__error">
                {emailErrorMessage}
              </p>
            )}
          </div>
          <div className="form__row">
            <label className="form__label" htmlFor="password">
              <Trans>Password</Trans>
            </label>
            <input
              id="password"
              type="password"
              name="password"
              value={password}
              onChange={onPasswordChange}
              autoComplete="new-password"
              required
              aria-invalid={passwordErrorMessage ? true : false}
              aria-describedby={`password-hint ${passwordErrorMessage ? "password-error" : ""}`}
            />
            <p className="form__input-hint" id="password-hint">
              <Trans>
                Your password must consist of at least 8 characters, an upper
                and lower case letter, a special character and a number.
              </Trans>
            </p>
            {passwordErrorMessage && (
              <p id="password-error" className="form__error">
                {passwordErrorMessage}
              </p>
            )}
          </div>
        </div>
      </fieldset>
      <p className="u-h5">
        <Trans>
          The <a href={_(termsLink().href)}>general terms and conditions</a> and{" "}
          the <a href={_(privacyLink().href)}>data protection notice</a> apply.
        </Trans>
      </p>
      <div className="form__row form__row--submit">
        <ButtonWithLoadingIndicator
          type="submit"
          loading={loading}
          data-testid="register-button"
        >
          <Trans>Register</Trans>
        </ButtonWithLoadingIndicator>
      </div>
    </form>
  );
}
