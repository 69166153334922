import { Trans } from "@lingui/macro";
import { useRef, useState } from "react";

import classNames from "../utils/classNames";
import type { Props as ButtonProps } from "./Button";
import Button from "./Button";

export type Props = ButtonProps & {
  valueToCopy: string;
};

const CopyToClipboard: React.FC<Props> = ({
  color,
  shape,
  children,
  valueToCopy,
}) => {
  const [copiedToClipboard, setCopiedToClipboard] = useState(false);

  const timeoutRef = useRef<NodeJS.Timeout | null>(null);

  const handleWriteText = async (value: string) => {
    await navigator.clipboard.writeText(value);
    setCopiedToClipboard(true);
    if (timeoutRef.current) {
      clearTimeout(timeoutRef.current);
    }
    timeoutRef.current = setTimeout(() => setCopiedToClipboard(false), 3000);
  };

  return (
    <Button
      color={color}
      shape={shape}
      onClick={() => handleWriteText(valueToCopy)}
    >
      <span className="a-button__overlay" aria-live="polite">
        {copiedToClipboard && <Trans>Copied!</Trans>}
      </span>
      <span
        className={classNames([copiedToClipboard && "a-button__hidden-label"])}
      >
        {children}
      </span>
    </Button>
  );
};
export default CopyToClipboard;
