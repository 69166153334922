import type { LocalizedCountryNames } from "i18n-iso-countries";
import { registerLocale } from "i18n-iso-countries";
import { getNames } from "i18n-iso-countries";
import deCountries from "i18n-iso-countries/langs/de.json";
import enCountries from "i18n-iso-countries/langs/en.json";

type CountryMap = LocalizedCountryNames<{
  select: "official";
}>;

export function loadCountryData(): void {
  registerLocale(enCountries);
  registerLocale(deCountries);
}

export function getCountryCodesAndNames(locale: string): CountryMap {
  const entries = Object.entries(getNames(locale, { select: "official" }));
  const sortedEntries = entries.sort(([_keyA, nameA], [_keyB, nameB]) =>
    nameA.localeCompare(nameB)
  );
  return Object.fromEntries(sortedEntries);
}
