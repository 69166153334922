import { i18n } from "@lingui/core";
import { I18nProvider } from "@lingui/react";
import React from "react";

export type Props = React.PropsWithChildren<any>;

function Provider({ children, ...props }: Props): React.ReactElement {
  return (
    <I18nProvider {...props} i18n={i18n}>
      {children}
    </I18nProvider>
  );
}

export default Provider;
