export function getDimaLogInPath(token: string): string {
  return `/dima/customer/session/sign_in?token=${token}`;
}

export function getDimaLogOutPath(redirectPath: string): string {
  return `/dima/customer/session/log_out?redirect_path=${redirectPath}`;
}

export function getDimaPaymentMethodPath(
  creditorId: string,
  redirectPath: string
): string {
  return `/dima/customer/payment_method/select?creditor_id=${creditorId}&redirect_path=${redirectPath}`;
}

export function getExternalRedirectPath(internalRedirectPath: string): string {
  return process.env.PUBLIC_URL + internalRedirectPath;
}

export function isDimaPath(pathname: string): boolean {
  return pathname.startsWith("/dima/");
}

export function isInternalAppPath(
  pathname: string,
  public_url: string = process.env.PUBLIC_URL
): boolean {
  return !isDimaPath(pathname) && !isExternalAppPath(pathname, public_url);
}

export function isExternalAppPath(
  pathname: string,
  public_url: string = process.env.PUBLIC_URL
): boolean {
  return !isDimaPath(pathname) && pathname.startsWith(public_url);
}
