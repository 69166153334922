import { Trans } from "@lingui/macro";
import React, { useState } from "react";

import ButtonWithLoadingIndicator from "../../shared/ButtonWithLoadingIndicator";

export type OnSubmitData = { email: string; password: string };

export type Props = {
  loading: boolean;
  errorMessage?: string;
  onSubmit: (data: OnSubmitData) => void;
};

export default function LogInForm(props: Props) {
  const { loading, errorMessage } = props;
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");

  const onEmailChange = (e: React.ChangeEvent<HTMLInputElement>) =>
    setEmail(e.target.value);
  const onPasswordChange = (e: React.ChangeEvent<HTMLInputElement>) =>
    setPassword(e.target.value);

  const onSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    props.onSubmit({ email, password });
  };

  return (
    <form className="form" onSubmit={onSubmit}>
      <div className="form__row">
        <label className="form__label" htmlFor="email">
          <Trans>Email</Trans>
        </label>
        <input
          id="email"
          type="email"
          required
          value={email}
          onChange={onEmailChange}
          autoComplete="email"
        />
      </div>

      <div className="form__row">
        <label className="form__label" htmlFor="password">
          <Trans>Password</Trans>
        </label>
        <input
          id="password"
          type="password"
          required
          value={password}
          onChange={onPasswordChange}
          autoComplete="current-password"
        />
      </div>

      <div className="form__row">
        {errorMessage ? <p className="form__error">{errorMessage}</p> : null}
      </div>

      <div className="form__row form__row--submit">
        <ButtonWithLoadingIndicator
          type="submit"
          loading={loading}
          data-testid="log-in-button"
        >
          <Trans>Log in</Trans>
        </ButtonWithLoadingIndicator>
      </div>
    </form>
  );
}
