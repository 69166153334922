import type { SVGProps } from "react";
import React, { Children } from "react";

import Button from "../Button";
import Icon from "../Icon";
import { ReactComponent as NavigationChevronRight } from "../icons/32/navigation-chevron-right.svg";
import { ReactComponent as NavigationExpandLess } from "../icons/32/navigation-expand-less.svg";
import { ReactComponent as NavigationExpandMore } from "../icons/32/navigation-expand-more.svg";

export type LinkListItemProps = {
  icon?: React.FC<SVGProps<SVGSVGElement>>;
  imageUrl?: string | null;
  title: string;
  to: string;
};

export type ExpandableLinkListItemProps = React.PropsWithChildren<{
  icon: React.FC<SVGProps<SVGSVGElement>>;
  title: string;
}>;

export function LinkListItem({
  icon,
  imageUrl,
  title,
  to,
}: LinkListItemProps): React.ReactElement {
  return (
    <Button color="transparent" shape="link-list" to={to}>
      {icon && <Icon svg={icon} size="large" />}
      {!icon && imageUrl && (
        <img src={imageUrl} alt="" className="link-list-item__image" />
      )}
      <span className="link-list-item__title u-truncate">{title}</span>
      <Icon svg={NavigationChevronRight} className="link-list-item__arrow" />
    </Button>
  );
}

export function ExpandableLinkListItem({
  icon,
  title,
  children,
}: ExpandableLinkListItemProps): React.ReactElement {
  return (
    <details className="expandable-item">
      <summary className="expandable-item__button a-button a-button--transparent a-button--link-list">
        <Icon svg={icon} size="large" />
        <span
          className="link-list-item__title u-truncate"
          data-testid="expandable-item"
        >
          {title}
        </span>
        <Icon
          svg={NavigationExpandMore}
          className="link-list-item__arrow expandable-item__icon-down"
        />
        <Icon
          svg={NavigationExpandLess}
          className="link-list-item__arrow expandable-item__icon-up"
        />
      </summary>
      <div className="expandable-item__content">{children}</div>
    </details>
  );
}

export type Props = React.PropsWithChildren<{}>;

export function LinkList({ children }: Props): React.ReactElement {
  return (
    <ul className="u-list-none">
      {Children.map(children, (child, index) => (
        <li key={`link-${index}`} className="link-list-item">
          {child}
        </li>
      ))}
    </ul>
  );
}
