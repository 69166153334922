import { useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";

import { useGetMyReservationsQuery } from "../graphql/__generated__/globalTypes";

const useFindMyReservationOrRedirect = () => {
  const { id } = useParams();
  const navigate = useNavigate();

  const { data, error, loading } = useGetMyReservationsQuery();

  const reservation = data?.myReservations?.find(
    (reservation) => reservation?.id === id
  );

  useEffect(() => {
    if (!error && !loading) {
      if (!reservation) navigate("/reservations", { replace: true });
    }
  }, [loading, error, data, reservation, navigate]);

  return { reservation, loading, error };
};

export default useFindMyReservationOrRedirect;
