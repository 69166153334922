import { msg, Trans } from "@lingui/macro";
import { useLingui } from "@lingui/react";
import React, { useState } from "react";
import { useNavigate } from "react-router-dom";

import { useAuth } from "../auth/AuthContext";
import type { UpdateProfileMutationVariables } from "../graphql/__generated__/globalTypes";
import { useUpdateProfileMutation } from "../graphql/__generated__/globalTypes";
import Me from "../graphql/queries/me";
import { hasValidationErrors } from "../graphql/validationErrors";
import Button from "../shared/Button";
import ErrorMessage from "../shared/ErrorMessage";
import PageLayout from "../shared/PageLayout";
import EditProfileForm from "./EditProfilePage/EditProfileForm";

export default function EditProfilePage(): React.ReactElement {
  const { _ } = useLingui();
  const { account } = useAuth();
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);

  const [updateProfile, { error }] = useUpdateProfileMutation({
    refetchQueries: [{ query: Me }],
    onError: console.error,
  });

  const isValidationError = error && hasValidationErrors(error);
  const formErrors = error?.graphQLErrors;

  const onSubmit = async (data: UpdateProfileMutationVariables) => {
    setLoading(true);

    try {
      const response = await updateProfile({
        variables: {
          firstName: data.firstName,
          lastName: data.lastName,
          company: data.company,
          optionalInformation: data.optionalInformation,
          address: data.address,
          zip: data.zip,
          city: data.city,
          country: data.country,
        },
      });
      if (response.data?.updateProfile?.id) {
        navigate("/");
      }
    } finally {
      setLoading(false);
    }
  };

  return (
    <PageLayout title={_(msg`Your details`)} backPath="/reservations/new/space">
      {error && !isValidationError ? (
        <ErrorMessage error={error} />
      ) : (
        <>
          <EditProfileForm
            profile={account?.profile || undefined}
            onSubmit={onSubmit}
            loading={loading}
            errors={formErrors}
          />
          <Button to="/reset-password" color="ghost">
            <Trans>Reset password</Trans>
          </Button>
        </>
      )}
    </PageLayout>
  );
}
