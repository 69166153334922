import type { MessageDescriptor } from "@lingui/core";
import { msg } from "@lingui/macro";
import { Trans } from "@lingui/macro";
import { useLingui } from "@lingui/react";
import { useEffect } from "react";
import { useNavigate } from "react-router-dom";

import { useCancelReservationMutation } from "../../graphql/__generated__/globalTypes";
import getMyReservations from "../../graphql/queries/getMyReservations";
import ButtonWithLoadingIndicator from "../../shared/ButtonWithLoadingIndicator";
import ErrorMessage from "../../shared/ErrorMessage";
import LoadingIndicator from "../../shared/LoadingIndicator";
import PageLayout from "../../shared/PageLayout";
import ReservationDetails from "../../shared/ReservationDetails";
import { canCancel } from "../../shared/ReservationDetails";
import useFindMyReservationOrRedirect from "../../utils/useFindMyReservationOrRedirect";

export function cancellationConditionLink(): {
  href: MessageDescriptor;
} {
  return {
    href: msg`https://everyworks.de/en/agb-app-v7/#cancellation-of-contract`,
  };
}

const CancelReservationPage = () => {
  const { _ } = useLingui();
  const navigate = useNavigate();

  const {
    reservation,
    loading: reservationLoading,
    error: reservationError,
  } = useFindMyReservationOrRedirect();

  useEffect(() => {
    if (reservation && !canCancel(reservation)) {
      navigate("/reservations", { replace: true });
    }
  }, [reservation, navigate]);

  const [
    cancelReservation,
    { loading: cancelReservationLoading, error: cancelReservationError },
  ] = useCancelReservationMutation();

  const onCancelReservation = async () => {
    await cancelReservation({
      variables: { reservationId: reservation?.id! },
      refetchQueries: [getMyReservations],
    });
    navigate("/reservations");
  };

  const error = reservationError || cancelReservationError;

  return (
    <PageLayout
      title={_(msg`Cancel reservation`)}
      backPath="/reservations/new/space"
    >
      {reservationLoading && (
        <span className="loading-indicator-wrapper">
          <LoadingIndicator />
        </span>
      )}
      {error && <ErrorMessage error={error} />}
      {!reservationLoading && !error && reservation && (
        <>
          <ReservationDetails
            simple
            reservation={reservation}
            space={reservation?.reservable?.space!}
          />
          <h3 className="u-h4 cancel-reservation-page__heading">
            <Trans>Really cancel your booking?</Trans>
          </h3>
          <p>
            <Trans>You will receive the following refunds:</Trans>
          </p>
          <ul className="cancel-reservation-page__list u-list-none">
            <li>
              <Trans>More than 48 hours before start: 100%.</Trans>
            </li>
            <li>
              <Trans>48 to 24 hours before start: 50%.</Trans>
            </li>
            <li>
              <Trans>Less than 24 hours before start: 10%.</Trans>
            </li>
          </ul>
          <div className="button-group">
            <a
              className="a-link u-h5 cancel-reservation-page__link"
              href={_(cancellationConditionLink().href)}
            >
              <Trans>Cancellation conditions apply</Trans>
            </a>
            <ButtonWithLoadingIndicator
              loading={cancelReservationLoading}
              onClick={() => onCancelReservation()}
            >
              <Trans>Cancel reservation</Trans>
            </ButtonWithLoadingIndicator>
          </div>
        </>
      )}
    </PageLayout>
  );
};
export default CancelReservationPage;
