import type { MessageDescriptor } from "@lingui/core";
import { msg, Trans } from "@lingui/macro";
import { useLingui } from "@lingui/react";
import React from "react";

import { onManageAnalytics } from "../../utils/analytics";
import classNames from "../../utils/classNames";
import { ReactComponent as LogoSmartCity } from "../images/logo-smartcity.svg";
import LocaleSwitcher from "./LocaleSwitcher";

export function privacyLink(): {
  title: MessageDescriptor;
  href: MessageDescriptor;
} {
  return {
    title: msg`Privacy`,
    href: msg`https://everyworks.de/en/privacy-app/`,
  };
}

export function termsLink(): {
  title: MessageDescriptor;
  href: MessageDescriptor;
} {
  return {
    title: msg`Terms`,
    href: msg`https://everyworks.de/en/terms-app/`,
  };
}

// must be a function, not a const value, to react to the current locale
export function getFooterLinks(): Array<{
  title: MessageDescriptor;
  href: MessageDescriptor;
}> {
  const privacy = privacyLink();
  const terms = termsLink();
  return [
    {
      title: terms.title,
      href: terms.href,
    },
    {
      title: msg`Imprint`,
      href: msg`https://everyworks.de/en/imprint/`,
    },
    {
      title: msg`FAQ`,
      href: msg`https://everyworks.de/en/faq/`,
    },
    {
      title: privacy.title,
      href: privacy.href,
    },
    {
      title: msg`Accessibility statement`,
      href: msg`http://everyworks.de/en/accessibility-statement`,
    },
  ];
}

export type Props = {
  className?: string;
};

export default function Footer({ className }: Props) {
  const { _ } = useLingui();

  const linkClassName = "u-h4 a-link a-link--nav";

  const footerLinks = getFooterLinks().map((link) => (
    <a className={linkClassName} key={_(link.href)} href={_(link.href)}>
      {_(link.title)}
    </a>
  ));

  return (
    <footer className={classNames(["footer", className])}>
      <div className="footer__content a-content a-content--l">
        <div className="footer__logo">
          <a href={_(msg`https://smartcity.db.de/`)} className={linkClassName}>
            <span className="u-sr-only">
              <Trans>SmartCity</Trans>
            </span>
            <LogoSmartCity role="img" aria-label={_(msg`SmartCity logo`)} />
          </a>
        </div>
        <nav className="footer__nav" aria-label={_(msg`secondary`)}>
          <LocaleSwitcher buttonClassName={linkClassName} />
          {footerLinks}
          <button className={linkClassName} onClick={onManageAnalytics}>
            <Trans>Manage analytics</Trans>
          </button>
        </nav>
      </div>
    </footer>
  );
}
