import { msg, Trans } from "@lingui/macro";
import { useLingui } from "@lingui/react";
import React from "react";

import type { Reservation, Space } from "../graphql/__generated__/globalTypes";
import { ReservationState } from "../graphql/__generated__/globalTypes";
import classNames from "../utils/classNames";
import type { TitleTag } from "../utils/propTypes";
import { formatTimeInDefaultTimezone } from "../utils/time";
import Button from "./Button";
import CopyToClipboard from "./CopyToClipboard";
import FormattedDate from "./FormattedDate";
import FormattedMoney from "./FormattedMoney";
import Icon from "./Icon";
import { ReactComponent as BookIcon48 } from "./icons/48/book.svg";
import { ReactComponent as CalendarCheckmarkIcon48 } from "./icons/48/calendar-checkmark.svg";
import { ReactComponent as ClockIcon48 } from "./icons/48/clock.svg";
import { ReactComponent as LocationIcon48 } from "./icons/48/location.svg";
import { ReactComponent as MeetingIcon48 } from "./icons/48/meeting.svg";
import { ReactComponent as SpeechBubblesIcon48 } from "./icons/48/speech-bubbles.svg";

export type Props = {
  reservation: Reservation;
  space: Space;
  titleTag?: TitleTag;
  simple?: boolean;
};

export const canCancel = (reservation: Reservation) => {
  let hasAlreadyStarted = false;
  if (reservation.startsAt) {
    const startTime = new Date(reservation.startsAt);
    const currentTime = new Date(Date());
    hasAlreadyStarted = currentTime > startTime;
  }
  return reservation.state === ReservationState.Confirmed && !hasAlreadyStarted;
};

function ReservationDetails({
  reservation,
  space,
  titleTag,
  simple,
}: Props): React.ReactElement {
  const {
    startsAt,
    endsAt,
    date,
    guestInvitationUrl,
    guests,
    totalCents,
    state,
    vatPercent,
    reservable,
  } = reservation;
  const reservableCategory = reservable?.reservableCategory;
  const TitleTag = titleTag || "h3";
  const { _ } = useLingui();

  let hasAlreadyStarted = false;
  if (startsAt) {
    const startTime = new Date(startsAt);
    const currentTime = new Date(Date());
    hasAlreadyStarted = currentTime > startTime;
  }

  // First guest in the array must be the host
  const hasOwnerAsFirstGuest = guests && guests[0] && !guests[0].name;
  // Is there capacity to have guests who are not the host?
  const canHaveGuests =
    state === ReservationState.Confirmed &&
    reservableCategory?.capacity &&
    reservableCategory.capacity > 1;
  // Still has capacity to invite more guests?
  const guestCount = guests ? guests.length : 1;
  const canInviteMoreGuests =
    state === ReservationState.Confirmed &&
    guestInvitationUrl &&
    reservableCategory?.capacity &&
    reservableCategory.capacity > guestCount &&
    !hasAlreadyStarted;

  const guestListWithoutHost = hasOwnerAsFirstGuest
    ? guests.slice(1, guests.length)
    : guests || [];

  return (
    <article className={classNames(["box", simple && "box--simple"])}>
      <TitleTag
        className={classNames([!simple ? "u-h4 box__heading" : "u-sr-only"])}
      >
        <Trans>{reservableCategory?.type?.name} booking details</Trans>
      </TitleTag>
      <dl className="dl">
        {date && (
          <div className="dl__item">
            <dt>
              <span className="u-sr-only">
                <Trans>Date</Trans>
              </span>
              <Icon
                svg={CalendarCheckmarkIcon48}
                size="large"
                className="dl__icon"
              />
            </dt>
            <dd>
              <FormattedDate date={new Date(date)} withWeekday />
            </dd>
          </div>
        )}
        <div className="dl__item">
          <dt>
            <span className="u-sr-only">
              <Trans>Time</Trans>
            </span>
            <Icon svg={ClockIcon48} size="large" className="dl__icon" />
          </dt>
          <dd>
            <Trans>
              {startsAt && formatTimeInDefaultTimezone(new Date(startsAt))} to{" "}
              {endsAt && formatTimeInDefaultTimezone(new Date(endsAt))}
            </Trans>
          </dd>
        </div>
        <div className="dl__item">
          <dt>
            <span className="u-sr-only">
              <Trans>Location</Trans>
            </span>
            <Icon svg={LocationIcon48} size="large" className="dl__icon" />
          </dt>
          <dd>{space.name}</dd>
        </div>
        <div className="dl__item">
          <dt>
            <span className="u-sr-only">
              <Trans>Reserved space</Trans>
            </span>
            <Icon svg={SpeechBubblesIcon48} size="large" className="dl__icon" />
          </dt>
          <dd>
            {reservableCategory?.type?.name}: {reservation.reservable?.name}
          </dd>
        </div>
        {typeof totalCents === "number" && totalCents > 0 && (
          <div className="dl__item dl__item--highlighted">
            <dt>
              <span className="u-sr-only">
                <Trans>Price</Trans>
              </span>
              <Icon svg={BookIcon48} size="large" className="dl__icon" />
            </dt>
            <dd>
              <span className="u-font-bold">
                <FormattedMoney cents={totalCents} />
              </span>{" "}
              <Trans>incl. {vatPercent}% VAT</Trans>
            </dd>
          </div>
        )}
        {!simple && state === ReservationState.Unconfirmed && (
          <p>
            <strong>
              <Trans>Not yet confirmed</Trans>
            </strong>
          </p>
        )}
        {!simple && canHaveGuests && (
          <div className="dl__item dl__item--highlighted dl__item--stacked">
            <dt>
              <Trans>Attendees</Trans>
            </dt>
            <dd>
              {guestListWithoutHost && guestListWithoutHost.length > 0 && (
                <ul className="u-list-none dl__participants-list">
                  {guestListWithoutHost.map((guest, index) => {
                    if (guest) {
                      return (
                        <li key={index}>
                          {guest.name && guest.name}
                          {!guest.name && <Trans>Anonymous</Trans>}
                        </li>
                      );
                    } else {
                      return null;
                    }
                  })}
                </ul>
              )}
              {guestListWithoutHost.length === 0 && (
                <Trans>No registrations so far</Trans>
              )}
            </dd>
          </div>
        )}
      </dl>
      <div className="box__buttons">
        {!simple && canInviteMoreGuests && (
          <CopyToClipboard valueToCopy={guestInvitationUrl}>
            <Icon svg={MeetingIcon48} size="large" className="a-button__icon" />
            <Trans>Invite attendees</Trans>
          </CopyToClipboard>
        )}
        {!simple && canCancel(reservation) && (
          <>
            <Button to={`/reservations/${reservation.id}/cancel`} color="ghost">
              <Trans>Cancel reservation</Trans>
            </Button>
            <a
              className="a-link box__buttons-link u-h5"
              href={_(
                msg`https://everyworks.de/en/agb-app-v7/#cancellation-of-contract`
              )}
            >
              <Trans>Cancellation conditions apply</Trans>
            </a>
          </>
        )}
      </div>
    </article>
  );
}

export default ReservationDetails;
