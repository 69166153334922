import { msg, Trans } from "@lingui/macro";
import { useLingui } from "@lingui/react";

import Button from "../../../shared/Button";
import ErrorMessage from "../../../shared/ErrorMessage";
import LoadingIndicator from "../../../shared/LoadingIndicator";
import PageLayout from "../../../shared/PageLayout";
import ProgressStepper from "../../../shared/ProgressStepper";
import ReservableDetails from "../../../shared/ReservableDetails";
import { decodeDate } from "../../../utils/date";
import useFindReservableCategoryOrRedirect from "../../../utils/useFindReservableCategoryOrRedirect";
import useFindReservationSuggestionOrRedirect from "../../../utils/useFindReservationSuggestionOrRedirect";
import useFindSelectedDateOrRedirect from "../../../utils/useFindSelectedDateOrRedirect";
import useFindSpaceOrRedirect from "../../../utils/useFindSpaceOrRedirect";

const ReservableDetailsPage = () => {
  const { _ } = useLingui();
  const { space, spaceError, spaceLoading } = useFindSpaceOrRedirect();
  const {
    reservableCategory,
    reservableCategoryError,
    reservableCategoryLoading,
  } = useFindReservableCategoryOrRedirect(space?.id);

  const { date, startTime, endTime, fromSlot, toSlot } =
    useFindSelectedDateOrRedirect({
      spaceId: space?.id,
      reservableCategoryId: reservableCategory?.id,
    });

  const {
    reservationSuggestion,
    reservationSuggestionLoading,
    reservationSuggestionError,
  } = useFindReservationSuggestionOrRedirect({
    spaceId: space?.id,
    reservableCategoryId: reservableCategory?.id,
    reservableTypeId: reservableCategory?.type?.id,
    date: date,
    startTime: startTime,
    fromSlotIndex: fromSlot?.index,
    endTime: endTime,
    toSlotIndex: toSlot?.index,
  });

  const loading =
    spaceLoading || reservableCategoryLoading || reservationSuggestionLoading;
  const error =
    spaceError || reservableCategoryError || reservationSuggestionError;

  const query = `spaceId=${space?.id}&reservableCategoryId=${reservableCategory?.id}&date=${date}&startTime=${startTime}&endTime=${endTime}`;

  return (
    <PageLayout
      title={_(msg`All details at a glance`)}
      backPath={`/reservations/new/suggestions?${query}`}
    >
      <ProgressStepper step={5} of={6} />
      {loading && (
        <span className="loading-indicator-wrapper">
          <LoadingIndicator />
        </span>
      )}
      {error && <ErrorMessage error={error} />}
      {!loading &&
        !error &&
        space &&
        reservableCategory &&
        date &&
        startTime &&
        endTime &&
        reservationSuggestion && (
          <div className="reservable-details-page">
            <ReservableDetails
              date={decodeDate(date)}
              startTime={startTime}
              endTime={endTime}
              reservable={reservationSuggestion?.reservable!}
              totalCents={reservationSuggestion?.totalCents!}
            />
            <Button
              to={`/reservations/new/submit?${query}&reservableId=${reservationSuggestion?.reservable?.id}`}
            >
              <Trans>Booking overview</Trans>
            </Button>
          </div>
        )}
    </PageLayout>
  );
};

export default ReservableDetailsPage;
