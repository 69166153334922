import { Trans } from "@lingui/macro";
import { useLingui } from "@lingui/react";
import React, { useCallback, useEffect } from "react";

import classNames from "../../utils/classNames";

export const languages = [
  {
    name: "EN",
    key: "en",
  },
  {
    name: "DE",
    key: "de",
  },
];

export type Props = {
  buttonClassName?: string;
};

const LocaleSwitcher = ({ buttonClassName }: Props) => {
  const { i18n } = useLingui();
  const changeLocale = useCallback(
    (locale: string) => {
      i18n.activate(locale);
      window.localStorage.setItem("lang", locale);
    },
    [i18n]
  );

  useEffect(() => {
    document.documentElement.lang = i18n.locale;
  }, [i18n.locale]);

  return (
    <div className="locale-switcher">
      <p className="u-sr-only">
        <Trans>Choose your language</Trans>
      </p>
      <ul className="a-list-reset locale-switcher__list">
        {languages.map((language) => (
          <li key={language.key} className="locale-switcher__list_item">
            <button
              className={classNames([
                "locale-switcher__button",
                buttonClassName,
              ])}
              type="button"
              aria-current={i18n.locale === language.key ? true : undefined}
              onClick={() => changeLocale(language.key)}
            >
              {language.name}
            </button>
          </li>
        ))}
      </ul>
    </div>
  );
};
export default LocaleSwitcher;
