import { useEffect } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";

import { useGetSpacesQuery } from "../graphql/__generated__/globalTypes";

const useFindSpaceOrRedirect = () => {
  const [searchParams] = useSearchParams();
  const navigate = useNavigate();
  const spaceId = searchParams.get("spaceId");

  const {
    data,
    loading: spaceLoading,
    error: spaceError,
  } = useGetSpacesQuery();

  useEffect(() => {
    if (!spaceLoading && !spaceError) {
      const spaceExists = data?.spaces?.some((space) => space?.id === spaceId);
      if (!spaceExists) {
        navigate("/reservations/new/space", { replace: true });
      }
    }
  }, [spaceLoading, spaceError, data, spaceId, navigate]);

  const space = data?.spaces!.find((space) => space?.id === spaceId);

  return { space, spaceLoading, spaceError };
};

export default useFindSpaceOrRedirect;
