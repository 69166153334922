import { msg, Trans } from "@lingui/macro";
import { useLingui } from "@lingui/react";
import React from "react";
import { useNavigate } from "react-router-dom";

import { useCreateReservationMutation } from "../../../graphql/__generated__/globalTypes";
import {
  useEnvironmentQuery,
  useResendConfirmationEmailMutation,
} from "../../../graphql/__generated__/globalTypes";
import getMyReservations from "../../../graphql/queries/getMyReservations";
import ButtonWithLoadingIndicator from "../../../shared/ButtonWithLoadingIndicator";
import ErrorMessage from "../../../shared/ErrorMessage";
import LoadingIndicator from "../../../shared/LoadingIndicator";
import PageLayout from "../../../shared/PageLayout";
import { termsLink } from "../../../shared/PageLayout/Footer";
import ProgressStepper from "../../../shared/ProgressStepper";
import ReservationDetails from "../../../shared/ReservationDetails";
import {
  getDimaPaymentMethodPath,
  getExternalRedirectPath,
  isInternalAppPath,
} from "../../../utils/dima";
import useCurrentLocation from "../../../utils/useCurrentLocation";
import useFindReservableCategoryOrRedirect from "../../../utils/useFindReservableCategoryOrRedirect";
import useFindReservationSuggestionOrRedirect from "../../../utils/useFindReservationSuggestionOrRedirect";
import useFindSelectedDateOrRedirect from "../../../utils/useFindSelectedDateOrRedirect";
import useFindSpaceOrRedirect from "../../../utils/useFindSpaceOrRedirect";
import useRefetchAccount from "../../../utils/useRefetchAccount";
import { cancellationConditionLink } from "../CancelReservationPage";

export default function SubmitReservationPage(): React.ReactElement {
  const { _ } = useLingui();
  const navigate = useNavigate();
  const { loading: accountLoading, account } = useRefetchAccount();
  const currentLocation = useCurrentLocation();
  const { space, spaceError, spaceLoading } = useFindSpaceOrRedirect();
  const {
    reservableCategory,
    reservableCategoryError,
    reservableCategoryLoading,
  } = useFindReservableCategoryOrRedirect(space?.id);

  const { date, startTime, endTime, fromSlot, toSlot } =
    useFindSelectedDateOrRedirect({
      spaceId: space?.id,
      reservableCategoryId: reservableCategory?.id,
    });

  const {
    reservationSuggestion,
    reservationSuggestionLoading,
    reservationSuggestionError,
  } = useFindReservationSuggestionOrRedirect({
    spaceId: space?.id,
    reservableCategoryId: reservableCategory?.id,
    reservableTypeId: reservableCategory?.type?.id,
    date: date,
    startTime: startTime,
    fromSlotIndex: fromSlot?.index,
    endTime: endTime,
    toSlotIndex: toSlot?.index,
  });

  const [
    createReservation,
    { loading: createReservationLoading, error: createReservationError },
  ] = useCreateReservationMutation({
    onError: console.error,
    refetchQueries: [{ query: getMyReservations }],
  });

  const loading =
    accountLoading ||
    spaceLoading ||
    reservableCategoryLoading ||
    reservationSuggestionLoading;

  const error =
    spaceError ||
    reservableCategoryError ||
    reservationSuggestionError ||
    createReservationError;

  const queryParams = new URLSearchParams({
    spaceId: space?.id!,
    reservableCategoryId: reservableCategory?.id!,
    date: date!,
    startTime: startTime!,
    endTime: endTime!,
    reservableId: reservationSuggestion?.reservable?.id!,
  }).toString();

  const draftReservation = {
    date,
    startsAt: reservationSuggestion?.slotRange?.from?.startsAt,
    endsAt: reservationSuggestion?.slotRange?.to?.endsAt,
    totalCents: reservationSuggestion?.totalCents!,
    vatPercent: reservationSuggestion?.reservable?.vatPercent,
    reservable: reservationSuggestion?.reservable,
  };

  const { data } = useEnvironmentQuery();
  const creditorId = data?.environment?.creditorId || "";
  const changePaymentMethodPath = getDimaPaymentMethodPath(
    creditorId,
    encodeURIComponent(getExternalRedirectPath(currentLocation))
  );

  const shouldShowDetails =
    !loading &&
    !error &&
    space &&
    reservableCategory &&
    date &&
    startTime &&
    endTime &&
    reservationSuggestion;

  const [resendConfirmationEmail, { loading: resendConfirmationEmailLoading }] =
    useResendConfirmationEmailMutation({ onError: console.error });

  let nextStepButtonPath = "";
  let nextStepButtonOnClick;
  let nextStepButtonText = "";
  let nextStepButtonLoading = false;

  if (!account) {
    nextStepButtonPath = `/log-in-or-register?redirectPath=${encodeURIComponent(changePaymentMethodPath)}`;
    nextStepButtonText = _(msg`Login / Register`);
  } else if (!account.emailConfirmedAt) {
    nextStepButtonLoading = resendConfirmationEmailLoading;
    nextStepButtonOnClick = async () => {
      await resendConfirmationEmail();
      navigate(
        `/email/confirm?redirectPath=${encodeURIComponent(changePaymentMethodPath)}`
      );
    };
    nextStepButtonText = _(msg`Confirm email address`);
  } else if (!account.paymentMethod) {
    nextStepButtonPath = changePaymentMethodPath;
    nextStepButtonText = _(msg`Choose payment method`);
  } else if (
    account.paymentMethod &&
    (account.paymentMethod.failing || !account.paymentMethod.confirmedAt)
  ) {
    nextStepButtonPath = changePaymentMethodPath;
    nextStepButtonText = _(msg`Update payment method`);
  } else {
    nextStepButtonLoading = createReservationLoading;
    nextStepButtonOnClick = async () => {
      const { data } = await createReservation({
        variables: {
          reservableId: reservationSuggestion?.reservable?.id!,
          date: date!,
          fromSlot: fromSlot?.index!,
          toSlot: toSlot?.index!,
          title: "",
        },
      });

      if (data?.createReservation?.id) {
        navigate(`/reservations/${data?.createReservation?.id}/created`);
      }
    };
    nextStepButtonText = _(msg`Book now`);
  }

  return (
    <PageLayout
      title={_(msg`Overview`)}
      subtitle={_(msg`Please check your selection.`)}
      backPath={`/reservations/new/details?${queryParams}`}
    >
      <ProgressStepper step={6} of={6} />
      {loading && (
        <span className="loading-indicator-wrapper">
          <LoadingIndicator />
        </span>
      )}
      {error && <ErrorMessage error={error} />}
      {shouldShowDetails && (
        <div className="submit-reservation-page">
          <ReservationDetails
            reservation={draftReservation}
            space={space}
            simple={true}
            titleTag="h2"
          />
          <p className="u-h5">
            <Trans>
              Our general <a href={_(termsLink().href)}>terms and conditions</a>{" "}
              as well as the{" "}
              <a href={space?.spaceRulesUrl || "#"}>
                house fire protection regulations
              </a>{" "}
              apply. The booking cannot be cancelled, our{" "}
              <a href={_(cancellationConditionLink().href)}>
                cancellation conditions
              </a>{" "}
              apply.
            </Trans>
          </p>
          <ButtonWithLoadingIndicator
            {...(nextStepButtonPath && isInternalAppPath(nextStepButtonPath)
              ? { to: nextStepButtonPath }
              : {})}
            {...(nextStepButtonPath && !isInternalAppPath(nextStepButtonPath)
              ? { href: nextStepButtonPath }
              : {})}
            {...(nextStepButtonOnClick
              ? { onClick: nextStepButtonOnClick }
              : {})}
            loading={nextStepButtonLoading}
          >
            {nextStepButtonText}
          </ButtonWithLoadingIndicator>
        </div>
      )}
    </PageLayout>
  );
}
