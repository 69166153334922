import React from "react";
import { Navigate, Outlet } from "react-router-dom";

import LoadingIndicator from "../shared/LoadingIndicator";
import useCurrentLocation from "../utils/useCurrentLocation";
import { useAuth } from "./AuthContext";

/**
 * A loading page component used by the ProtectedRoute utility.
 * The component renders a loading indicator for the state while queries
 * are performed in the background to determine whether the user may access
 * the protected route or not.
 */
export function LoadingPage(): React.ReactElement {
  return (
    <div className="loading-indicator-page">
      <LoadingIndicator />
    </div>
  );
}

function ProtectedRoute(): React.ReactElement {
  const { loading, account } = useAuth();
  const currentLocation = useCurrentLocation();

  if (loading) return <LoadingPage />;

  if (account) return <Outlet />;

  const currentPath = encodeURIComponent(currentLocation);
  const redirectPath = `/log-in?redirectPath=${currentPath}`;

  return <Navigate to={redirectPath} replace />;
}

export default ProtectedRoute;
