import { msg } from "@lingui/macro";

import { useEnvironmentQuery } from "../../graphql/__generated__/globalTypes";
import {
  getDimaPaymentMethodPath,
  getExternalRedirectPath,
} from "../../utils/dima";
import useCurrentLocation from "../../utils/useCurrentLocation";

export default function useProfileLinks() {
  const currentLocation = useCurrentLocation();
  const { data } = useEnvironmentQuery();
  const creditorId = data?.environment?.creditorId || "";
  const currentPath = encodeURIComponent(currentLocation);

  return [
    {
      title: msg`Edit email`,
      to: "/email/edit",
    },
    {
      title: msg`Edit payment method`,
      href: getDimaPaymentMethodPath(
        creditorId,
        getExternalRedirectPath(currentPath)
      ),
    },
    {
      title: msg`Edit profile`,
      to: "/profile/edit",
    },
  ];
}
