import { Plural, Trans } from "@lingui/macro";
import { useLingui } from "@lingui/react";
import React, { useState } from "react";

import type { Reservable } from "../graphql/__generated__/globalTypes";
import classNames from "../utils/classNames";
import type { TitleTag } from "../utils/propTypes";
import Button from "./Button";
import FormattedDate from "./FormattedDate";
import FormattedMoney from "./FormattedMoney";
import Icon from "./Icon";
import { ReactComponent as Person } from "./icons/32/person.svg";
export type Props = {
  date: Date;
  startTime: string;
  endTime: string;
  reservable: Reservable;
  totalCents: number;
  titleTag?: TitleTag;
};

const renderAdditionalDetails = (data: any, locale: string) => (
  <div className="reservable-details__additional">
    {data[locale]?.additional_instructions && (
      <p>{data[locale]?.additional_instructions}</p>
    )}
    {data[locale]?.space_offers &&
      Array.isArray(data[locale]?.space_offers) && (
        <ul className="reservable-details__additional-list">
          {data[locale]?.space_offers.map((offer: any, index: number) => (
            <li key={index}>{offer?.title}</li>
          ))}
        </ul>
      )}
  </div>
);

export default function ReservableDetails(props: Props) {
  const { reservable, date, startTime, endTime, totalCents } = props;
  const { vatPercent, reservableCategory } = reservable;
  const capacity = reservableCategory?.capacity || 0;
  const TitleTag = props.titleTag || "h3";
  const { i18n } = useLingui();

  const allImages = [
    ...(reservable?.headerImage ? [reservable?.headerImage.url] : []),
    ...(reservable?.additionalImages
      ? reservable?.additionalImages.map((image) => image!.url)
      : []),
  ];

  const [selectedImage, setSelectedImage] = useState(allImages && allImages[0]);
  const [imageIndex, setImageIndex] = useState(1);
  const noImages = !selectedImage && allImages.length === 0;

  return (
    <article className="reservable-details">
      <TitleTag className="reservable-details__title">
        {reservable.name}
      </TitleTag>
      {noImages && (
        <div className="reservable-list-item__image reservable-list-item__image--placeholder" />
      )}
      {selectedImage && (
        <>
          <img
            className="reservable-details__image"
            src={selectedImage}
            alt=""
          />
          <span className="u-sr-only">
            <Trans>Selected image {imageIndex}</Trans>
          </span>
        </>
      )}
      {allImages.length > 0 && (
        <ul className="reservable-details__gallery u-list-none">
          {allImages.map((image, index) => (
            <li
              key={index}
              className={classNames([
                "reservable-details__gallery-item",
                image !== selectedImage &&
                  "reservable-details__gallery-item--not-selected",
              ])}
            >
              <Button
                onClick={() => {
                  setSelectedImage(image!);
                  setImageIndex(index + 1);
                }}
                color="gallery"
                shape="link-list"
                aria-current={image === selectedImage}
              >
                <img
                  className="reservable-details__gallery-image"
                  src={image!}
                  alt=""
                />
                <span className="u-sr-only">
                  <Trans>Show image {index + 1}</Trans>
                </span>
              </Button>
            </li>
          ))}
        </ul>
      )}
      <p>
        {<FormattedDate date={date} />},{" "}
        <Trans>
          {startTime} - {endTime}
        </Trans>
      </p>
      <p>
        <Icon svg={Person} className="reservable-details__person" />
        <Plural
          value={capacity}
          one={`{capacity} person`}
          other={`{capacity} people`}
        />
      </p>
      <p>
        <span className="u-h4">
          <FormattedMoney cents={totalCents} />
        </span>{" "}
        <span className="u-h6">
          <Trans>incl. {vatPercent}% VAT</Trans>
        </span>
      </p>
      {reservable.metadata &&
        renderAdditionalDetails(reservable.metadata, i18n.locale)}
    </article>
  );
}
