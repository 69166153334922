import React from "react";

export type Props = React.PropsWithChildren<
  {
    label: string;
    id: string;
    name: string;
  } & React.SelectHTMLAttributes<HTMLSelectElement>
>;

function SelectWithLabel({
  label,
  id,
  children,
  ...rest
}: Props): React.ReactElement {
  return (
    <div className="select-with-label">
      <label htmlFor={id} className="select-with-label__label u-truncate">
        {label}
      </label>
      <select {...rest} id={id} className="select-with-label__input">
        {children}
      </select>
    </div>
  );
}

export default SelectWithLabel;
