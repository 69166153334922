/* eslint-disable */
import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
export type Maybe<T> = T | null;
export type InputMaybe<T> = Maybe<T>;
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] };
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> };
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> };
export type MakeEmpty<T extends { [key: string]: unknown }, K extends keyof T> = { [_ in K]?: never };
export type Incremental<T> = T | { [P in keyof T]?: P extends ' $fragmentName' | '__typename' ? T[P] : never };
const defaultOptions = {} as const;
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: { input: string; output: string; }
  String: { input: string; output: string; }
  Boolean: { input: boolean; output: boolean; }
  Int: { input: number; output: number; }
  Float: { input: number; output: number; }
  Date: { input: string; output: string; }
  JSON: { input: any; output: any; }
  TimeWithoutTimezone: { input: string; output: string; }
  TimeslotIndex: { input: number; output: number; }
  UTCDateTime: { input: string; output: string; }
  UUIDV4: { input: string; output: string; }
};

/** A customer account. */
export type Account = {
  /** The customer account number. */
  accountNumber?: Maybe<Scalars['String']['output']>;
  /** The customers corporate profile. Corporate and normal profiles are mutually exclusive. */
  corporateProfile?: Maybe<CorporateProfile>;
  /** The email for the account */
  email?: Maybe<Scalars['String']['output']>;
  /** The confirmation date of the email. */
  emailConfirmedAt?: Maybe<Scalars['UTCDateTime']['output']>;
  /** The ID for the account. */
  id?: Maybe<Scalars['UUIDV4']['output']>;
  /** The active payment method. */
  paymentMethod?: Maybe<PaymentMethod>;
  /** The permanent discounts for this account. */
  permanentDiscounts?: Maybe<Array<Maybe<PermanentDiscount>>>;
  /** The customer's profile. */
  profile?: Maybe<Profile>;
  /** The service PIN. */
  servicePin?: Maybe<Scalars['String']['output']>;
  /** The accepted terms document. */
  termsDocumentAccepted?: Maybe<TermsDocument>;
  /** The date when the accepted terms document was accepted. */
  termsDocumentAcceptedAt?: Maybe<Scalars['UTCDateTime']['output']>;
  /** If the last seen terms document was rejected, the date of that rejection. */
  termsDocumentRejectedAt?: Maybe<Scalars['UTCDateTime']['output']>;
  /** The last seen terms document. */
  termsDocumentSeen?: Maybe<TermsDocument>;
  /** The date when the last seen terms document was presented. */
  termsDocumentSeenAt?: Maybe<Scalars['UTCDateTime']['output']>;
  /** If the email address is in reconfirmation, this contains the new email address. */
  unconfirmedEmail?: Maybe<Scalars['String']['output']>;
};


/** A customer account. */
export type AccountPaymentMethodArgs = {
  includeFailing?: InputMaybe<Scalars['Boolean']['input']>;
};

/** A spaces's closing event. */
export type ClosingEvent = {
  /** The end time of this event. */
  endsAt?: Maybe<Scalars['UTCDateTime']['output']>;
  /** The ID of the event. */
  id?: Maybe<Scalars['UUIDV4']['output']>;
  /** The start time of this event. */
  startsAt?: Maybe<Scalars['UTCDateTime']['output']>;
  /** The title or subject of this event. */
  subject?: Maybe<Scalars['String']['output']>;
};

/** A profile of a member of a corporation. */
export type CorporateProfile = {
  /** The corporation this profile belongs to. */
  corporation?: Maybe<Corporation>;
  /** The first name of the member. */
  firstName?: Maybe<Scalars['String']['output']>;
  /** The last name of the member. */
  lastName?: Maybe<Scalars['String']['output']>;
  /** A convenience attribute for the full name of the customer. */
  name?: Maybe<Scalars['String']['output']>;
};

/** A corporation. */
export type Corporation = {
  /** The Corporation name. */
  name?: Maybe<Scalars['String']['output']>;
  /** Whether the reservations feature is available for users belonging to this corporation. */
  reservationsEnabled: Scalars['Boolean']['output'];
  /** If this is set, the corporation is suspended and therefore inactive. */
  suspendedAt?: Maybe<Scalars['UTCDateTime']['output']>;
  /** Whether the visits feature is available for users belonging to this corporation. */
  visitsEnabled: Scalars['Boolean']['output'];
};

/** With this object, the client library of BS Payone can be initialized for everyworks. */
export type CreditCardCheckParams = {
  aid?: Maybe<Scalars['String']['output']>;
  encoding?: Maybe<Scalars['String']['output']>;
  hash?: Maybe<Scalars['String']['output']>;
  mid?: Maybe<Scalars['String']['output']>;
  mode?: Maybe<Scalars['String']['output']>;
  portalid?: Maybe<Scalars['String']['output']>;
  request?: Maybe<Scalars['String']['output']>;
  responsetype?: Maybe<Scalars['String']['output']>;
  storecarddata?: Maybe<Scalars['String']['output']>;
};

/** A promotion or a flexible time package. */
export type CurrentPromotion = FlexibleTimePackage | Promotion;

/** Information about the current environment. */
export type Environment = {
  /** The creditor id of the main provider */
  creditorId?: Maybe<Scalars['String']['output']>;
  /** The newsletter management link. */
  newsletterManagementUrl?: Maybe<Scalars['String']['output']>;
  /** The VAT rate currently applied. */
  vatPercent?: Maybe<Scalars['Int']['output']>;
};

/** A package of minutes which can be bought as a prepaid minute wallet for the account. */
export type FlexibleTimePackage = {
  /** The alternative color in hex format for presenting he package e.g. '#333333'. */
  altHexColor?: Maybe<Scalars['String']['output']>;
  /** The days after the purchase which this packages will expire. Expires always at the end of the day of the default timezone. */
  expiresAfterDays?: Maybe<Scalars['Int']['output']>;
  /** The color in hex format for presenting he package e.g. '#333333'. */
  hexColor?: Maybe<Scalars['String']['output']>;
  /** The ID of the flexible time package. */
  id?: Maybe<Scalars['UUIDV4']['output']>;
  /** The URL of the image for presenting this package. */
  imageUrl?: Maybe<Scalars['String']['output']>;
  /** Number of minutes. */
  minutes?: Maybe<Scalars['Int']['output']>;
  /** The name for presenting this package */
  name?: Maybe<Scalars['String']['output']>;
  /** Price of the package. */
  totalCents?: Maybe<Scalars['Int']['output']>;
  /** The VAT rate for this package in percent. */
  vatPercent?: Maybe<Scalars['Int']['output']>;
};


/** A package of minutes which can be bought as a prepaid minute wallet for the account. */
export type FlexibleTimePackageVatPercentArgs = {
  date?: InputMaybe<Scalars['Date']['input']>;
};

/** A guest for a reservation */
export type Guest = {
  /** The guests name. */
  name?: Maybe<Scalars['String']['output']>;
};

/** An image */
export type Image = {
  /** The ID of the image. */
  id?: Maybe<Scalars['UUIDV4']['output']>;
  /** Where to present the image. */
  type?: Maybe<ImageScope>;
  /** The image URL. */
  url?: Maybe<Scalars['String']['output']>;
};

/** An image scope. */
export enum ImageScope {
  Header = 'header',
  LocationOther = 'location_other',
  Other = 'other'
}

/** A minute wallet is a digital wallet containing a minute quota to be used for visits. */
export type MinuteWallet = {
  /** A minute wallet is considered active if it's past its active_from datetime, and it still has remaining minutes, and is not yet expired. */
  active?: Maybe<Scalars['Boolean']['output']>;
  /** The datetime at which the minute wallet becomes active. */
  activeFrom?: Maybe<Scalars['UTCDateTime']['output']>;
  /** The expiry date for the minute quota in this wallet. */
  expiresAt?: Maybe<Scalars['UTCDateTime']['output']>;
  /** The flexible time package that was bought to create this wallet, if the wallet was created by that. */
  flexibleTimePackage?: Maybe<FlexibleTimePackage>;
  /** The ID of the minute wallet */
  id?: Maybe<Scalars['UUIDV4']['output']>;
  /** The minute quota left on this wallet. */
  remainingMinutes?: Maybe<Scalars['Int']['output']>;
  /** The timestamp of the last update in this minute wallet. */
  updatedAt?: Maybe<Scalars['UTCDateTime']['output']>;
  /** The voucher code that created this wallet, if the wallet was created by a voucher. */
  voucherCode?: Maybe<VoucherCode>;
};

/** Represents a transaction on a minute wallet. */
export type MinuteWalletTransaction = {
  /** If true, the minutes were subtracted from the wallet. If false, the minutes were added to the wallet. */
  deduction?: Maybe<Scalars['Boolean']['output']>;
  /** The ID of the minute wallet transaction. */
  id?: Maybe<Scalars['UUIDV4']['output']>;
  /** The minute wallet this transaction belongs to. */
  minuteWallet?: Maybe<MinuteWallet>;
  /** The number of minutes to be transferred via the transaction. */
  minutes?: Maybe<Scalars['Int']['output']>;
};

/** A space opening hour entry. */
export type OpeningHour = {
  /** The time when the space closes. If the space is open until midnight, closes_at is 00:00:00. */
  closesAt?: Maybe<Scalars['TimeWithoutTimezone']['output']>;
  /** The weekday of this opening hour in ISO-8061 weekday numbers: ["1", "2", "3", "4", "5", "6", "7"] */
  dayOfWeek?: Maybe<Scalars['String']['output']>;
  /** The time the space opens. */
  opensAt?: Maybe<Scalars['TimeWithoutTimezone']['output']>;
};

/** A visit that was checked out or cancelled. */
export type PastVisit = {
  /** The per minute base price in cents. */
  basePriceCents?: Maybe<Scalars['Int']['output']>;
  /** The time when the customer arrived. */
  checkedInAt?: Maybe<Scalars['UTCDateTime']['output']>;
  /** The time when the customer left. */
  checkedOutAt?: Maybe<Scalars['UTCDateTime']['output']>;
  /** The number of minutes that were discounted from the total. */
  discountedMinutes?: Maybe<Scalars['Int']['output']>;
  /** The ID of the past visit. */
  id?: Maybe<Scalars['UUIDV4']['output']>;
  /** The number of invoiced minutes. */
  invoicedMinutes?: Maybe<Scalars['Int']['output']>;
  /** The transactions that this visit caused in minute wallets. */
  minuteWalletTransactions?: Maybe<Array<Maybe<MinuteWalletTransaction>>>;
  /** Describes the end state of the visit. */
  outcome?: Maybe<PastVisitOutcome>;
  /** The number of non-charged minutes designated for the customer to arrive at their desk. */
  prepTimeInMinutes?: Maybe<Scalars['Int']['output']>;
  /** The space in which the visit took place. */
  space?: Maybe<Space>;
  /** The visit price in cents. */
  totalCents?: Maybe<Scalars['Int']['output']>;
  /** The applied VAT rate in percent. */
  vatPercent?: Maybe<Scalars['Int']['output']>;
};

/** Visits are either checked out or cancelled. */
export enum PastVisitOutcome {
  Cancelled = 'cancelled',
  CheckedOut = 'checked_out'
}

/** A payment method of a customer. */
export type PaymentMethod = {
  /** The card expiry date for credit card payments. */
  cardExpiryDate?: Maybe<Scalars['String']['output']>;
  /** The card type for credit card payments. */
  cardType?: Maybe<Scalars['String']['output']>;
  /** The confirmation timestamp for this payment method. */
  confirmedAt?: Maybe<Scalars['UTCDateTime']['output']>;
  /**
   * The payment method currently has failing payments, that need to be processed successfully
   * before the user can use this payment method again. The user will be treated as a user without
   * a payment method until the flag is set to false again.
   *
   * This field will only be updated, if all outstanding payments have been processed, even
   * when the payment method was already updated. In order to show to the user, that the
   * payment method was updated, one can use the `untried` field, to show that outstanding
   * payments are still being processed with the new payment method and the user is still blocked
   * but has to wait on the result.
   */
  failing?: Maybe<Scalars['Boolean']['output']>;
  /** The ID of a payment method. */
  id?: Maybe<Scalars['UUIDV4']['output']>;
  /** If this is set, the customer needs to visit the URL to complete the payment method. */
  redirecturl?: Maybe<Scalars['String']['output']>;
  /** The truncated credit card number for credit card payments. */
  truncatedcardpan?: Maybe<Scalars['String']['output']>;
  /** The type of payment. */
  type?: Maybe<PaymentMethodType>;
  /**
   * This field will be true, if the payment method has ever been used for a payment. It will
   * only change if the payment is either successful or failing. Pending payment will not yet
   * update this field.
   */
  untried?: Maybe<Scalars['Boolean']['output']>;
};

/** The payment method types. */
export enum PaymentMethodType {
  Creditcard = 'creditcard',
  Paypal = 'paypal'
}

/** A permanent discount given to a customer. */
export type PermanentDiscount = {
  /** The bahn card numer */
  bahnCardNumber?: Maybe<Scalars['String']['output']>;
  /** The expiry date for the discount. For bahn card discounts this is set to the expiration date of the bahn card. */
  expiresAt?: Maybe<Scalars['UTCDateTime']['output']>;
  /** The ID of the permanent discount. */
  id?: Maybe<Scalars['UUIDV4']['output']>;
  /** The name of the discount campaign. */
  name?: Maybe<Scalars['String']['output']>;
  /** The percentage the discount reduces the price by. */
  percent?: Maybe<Scalars['Int']['output']>;
};

/** A customer profile. */
export type Profile = {
  /** The address information of the customer used for generating invoices. */
  address?: Maybe<Scalars['String']['output']>;
  /** The city of the customer address. */
  city?: Maybe<Scalars['String']['output']>;
  /** The customer's company name. */
  company?: Maybe<Scalars['String']['output']>;
  /** The ISO 3166-1 alpha-2 country code for the country of the customer. */
  country?: Maybe<Scalars['String']['output']>;
  /** The first name of the customer. */
  firstName?: Maybe<Scalars['String']['output']>;
  /** The ID of the profile. */
  id?: Maybe<Scalars['UUIDV4']['output']>;
  /** The last name of the customer. */
  lastName?: Maybe<Scalars['String']['output']>;
  /** A convenience attribute for the full name of the customer. */
  name?: Maybe<Scalars['String']['output']>;
  /** Optional information supplied by the customer. */
  optionalInformation?: Maybe<Scalars['String']['output']>;
  /** The postal code of the customer address. */
  zip?: Maybe<Scalars['String']['output']>;
};

/** Object for embedding a profile into a registration. */
export type ProfileInput = {
  /** The address information of the customer used for generating invoices. */
  address: Scalars['String']['input'];
  /** The city of the customer address. */
  city: Scalars['String']['input'];
  /** The optional company name for the customer. */
  company?: InputMaybe<Scalars['String']['input']>;
  /** The ISO 3166-1 alpha-2 country code for the country of the customer. */
  country: Scalars['String']['input'];
  /** The first name of the customer. */
  firstName: Scalars['String']['input'];
  /** The last name of the customer. */
  lastName: Scalars['String']['input'];
  /** Other information supplied by the customer. */
  optionalInformation?: InputMaybe<Scalars['String']['input']>;
  /** The postal code of the customer address. */
  zip: Scalars['String']['input'];
};

/** A promotion product. */
export type Promotion = {
  /** The alternative color in hex format for presenting the promotion e.g. '#333333'. */
  altHexColor?: Maybe<Scalars['String']['output']>;
  /** The description of the promotion. */
  description?: Maybe<Scalars['String']['output']>;
  /** The color in hex format for presenting the promotion e.g. '#333333'. */
  hexColor?: Maybe<Scalars['String']['output']>;
  /** The ID of the promotion. */
  id?: Maybe<Scalars['UUIDV4']['output']>;
  /** The link of the promotion. */
  link?: Maybe<Scalars['String']['output']>;
  /** The name of the promotion. */
  name?: Maybe<Scalars['String']['output']>;
  /** The description of the promotion link. */
  promotionUrlDescription?: Maybe<Scalars['String']['output']>;
};


/** A promotion product. */
export type PromotionDescriptionArgs = {
  locale?: InputMaybe<Scalars['String']['input']>;
};


/** A promotion product. */
export type PromotionLinkArgs = {
  locale?: InputMaybe<Scalars['String']['input']>;
};


/** A promotion product. */
export type PromotionNameArgs = {
  locale?: InputMaybe<Scalars['String']['input']>;
};


/** A promotion product. */
export type PromotionPromotionUrlDescriptionArgs = {
  locale?: InputMaybe<Scalars['String']['input']>;
};

/**
 * A provider for spaces.
 * There is only one provider which is not a partner (Everyworks itself)
 * All other providers are 3rd party partners.
 */
export type Provider = {
  /** Indicates if this provider is a 3rd party partner. */
  isPartner?: Maybe<Scalars['Boolean']['output']>;
  /** The file URL with the logo of this provider. */
  logoUrl?: Maybe<Scalars['String']['output']>;
  /** The name of the provider. */
  name?: Maybe<Scalars['String']['output']>;
};

/** A room or object that can be reserved in a space. */
export type Reservable = {
  /** Other images. */
  additionalImages?: Maybe<Array<Maybe<Image>>>;
  /** @deprecated It has been moved to reservableCategory */
  capacity?: Maybe<Scalars['Int']['output']>;
  /** The main image for this reservable. */
  headerImage?: Maybe<Image>;
  /** The ID of the reservable. */
  id?: Maybe<Scalars['UUIDV4']['output']>;
  /** Arbitrary metadata that can be configured in the administrative backend. */
  metadata?: Maybe<Scalars['JSON']['output']>;
  /** The name of the reservable. */
  name?: Maybe<Scalars['String']['output']>;
  /** @deprecated It has been moved to reservableCategory */
  pricePerSlotInCents?: Maybe<Scalars['Int']['output']>;
  /** The category which is used for pricing of this reservable. */
  reservableCategory?: Maybe<ReservableCategory>;
  /** The space that hosts this reservable. */
  space?: Maybe<Space>;
  /** The VAT for the prices of this reservable. */
  vatPercent?: Maybe<Scalars['Int']['output']>;
};


/** A room or object that can be reserved in a space. */
export type ReservableVatPercentArgs = {
  date?: InputMaybe<Scalars['Date']['input']>;
};

/** A category of reservables. */
export type ReservableCategory = {
  /** The capacity in persons of this category. */
  capacity?: Maybe<Scalars['Int']['output']>;
  /** The ID of the reservable category. */
  id?: Maybe<Scalars['UUIDV4']['output']>;
  /** The minimum duration in number of slots. */
  minimumDurationSlots?: Maybe<Scalars['Int']['output']>;
  /** The name of the category. */
  name?: Maybe<Scalars['String']['output']>;
  /** The maximum price for a booking in this category. */
  priceCapInCents?: Maybe<Scalars['Int']['output']>;
  /** The price per slot for booking reservables in this category. */
  pricePerSlotInCents?: Maybe<Scalars['Int']['output']>;
  /** The type of the reservable category. */
  type?: Maybe<ReservableType>;
};

/** A type of reservable category. */
export type ReservableType = {
  /** The URL of the icon for presenting this type. */
  iconUrl?: Maybe<Scalars['String']['output']>;
  /** The ID of the reservable type. */
  id?: Maybe<Scalars['UUIDV4']['output']>;
  /** Arbitrary metadata that can be configured in the administrative backend. */
  metadata?: Maybe<Scalars['JSON']['output']>;
  /** The name of the reservable type. */
  name?: Maybe<Scalars['String']['output']>;
  /** The position of this reservable type. */
  position?: Maybe<Scalars['Int']['output']>;
};

/** A reservation for a room or object in a space */
export type Reservation = {
  /** If the reservation was cancelled, the percentage by which the price is reduced. */
  cancellationDiscountPercent?: Maybe<Scalars['Int']['output']>;
  /** The reservation date. */
  date?: Maybe<Scalars['Date']['output']>;
  /** The timestamp when the reservation will end. */
  endsAt?: Maybe<Scalars['UTCDateTime']['output']>;
  /**
   * @deprecated This field will assume value '00:00:00' when the corresponding slot index is 47.
   *
   * While this is technically correct, it is confusing to the user and dangerous to base any
   * calculations on. Hence, any TimeWithoutTimezone fields will be removed in a future version of
   * the API. Please use the corresponding UTCDateTime fields.
   *
   */
  from?: Maybe<Scalars['TimeWithoutTimezone']['output']>;
  /** The first timeslot of the reservation. */
  fromSlot?: Maybe<Scalars['TimeslotIndex']['output']>;
  /** The invitation URL, that can be shared to invite guests. */
  guestInvitationUrl?: Maybe<Scalars['String']['output']>;
  /** The list of guests. */
  guests?: Maybe<Array<Maybe<Guest>>>;
  /** The code for the host QR code. */
  hostQrCode?: Maybe<Scalars['String']['output']>;
  /** The ID of the reservtion. */
  id?: Maybe<Scalars['UUIDV4']['output']>;
  /** If set, the reservation was invoiced on that timestamp. */
  invoicedAt?: Maybe<Scalars['UTCDateTime']['output']>;
  /** The reservable of the reservation. */
  reservable?: Maybe<Reservable>;
  /** The timestamp when the reservation will start. */
  startsAt?: Maybe<Scalars['UTCDateTime']['output']>;
  /** The state of the reservation. */
  state?: Maybe<ReservationState>;
  /** The title of the reservation. */
  title?: Maybe<Scalars['String']['output']>;
  /**
   * @deprecated This field will assume value '00:00:00' when the corresponding slot index is 47.
   *
   * While this is technically correct, it is confusing to the user and dangerous to base any
   * calculations on. Hence, any TimeWithoutTimezone fields will be removed in a future version of
   * the API. Please use the corresponding UTCDateTime fields.
   *
   */
  to?: Maybe<Scalars['TimeWithoutTimezone']['output']>;
  /** The last timeslot of the reservation. */
  toSlot?: Maybe<Scalars['TimeslotIndex']['output']>;
  /** The reservation price. */
  totalCents?: Maybe<Scalars['Int']['output']>;
  /** The VAT of the reservation price in percent. */
  vatPercent?: Maybe<Scalars['Int']['output']>;
};


/** A reservation for a room or object in a space */
export type ReservationGuestsArgs = {
  excludeHost?: InputMaybe<Scalars['Boolean']['input']>;
};

export enum ReservationState {
  /** A reservation can be cancelled which will free up the booked slots. */
  Cancelled = 'cancelled',
  /** A reservation is confirmed when the reservable has free slots in the given time frame. */
  Confirmed = 'confirmed',
  /** A reservation is unconfirmed until accepted by the reservable's calendar. */
  Unconfirmed = 'unconfirmed'
}

/** A single search result for reservations, including price and slot range */
export type ReservationSuggestion = {
  /** Indicates if there are other ranges available. */
  otherRangesAvailable?: Maybe<Scalars['Boolean']['output']>;
  /** The reservable for this suggestion. */
  reservable?: Maybe<Reservable>;
  /** The available timeslot range. */
  slotRange?: Maybe<TimeslotRange>;
  /** The price of this suggestion. */
  totalCents?: Maybe<Scalars['Int']['output']>;
  /** The VAT for the price of this suggestion. */
  vatPercent?: Maybe<Scalars['Int']['output']>;
};

/** A search result for reservations, with exact matches and alternatives */
export type ReservationSuggestions = {
  /** Alternative suggestions that do not match the criteria exactly. */
  alternatives?: Maybe<Array<Maybe<ReservationSuggestion>>>;
  /** The date of the search result. */
  date?: Maybe<Scalars['Date']['output']>;
  /** Exact matches for the search. */
  matches?: Maybe<Array<Maybe<ReservationSuggestion>>>;
};

/**
 * Operations for data mutation.
 *
 * ---
 *
 * #### API Errors
 *
 * Error responses of the API will have an `errors` key that contains
 * a list of error objects each carrying mandatory `code` and `message` keys.
 *
 * Courtesy of the GraphQL executor, you will also get information about the
 * location of the offending query/mutation in your request (`locations`),
 * as well as the `path` of the error.
 *
 * ##### Example
 *
 *     errors: [
 *       %{
 *         code: 1014,
 *         locations: [%{column: 0, line: 2}],
 *         message: "slot range unavailable",
 *         path: ["createReservation"]
 *       }
 *     ]
 *
 * ##### Error Index
 *
 * | Code | Description |
 * | ---- | ----------- |
 * | 1000 | something went wrong |
 * | 1001 | validation failed |
 * | 1002 | corporation visits not enabled |
 * | 1002 | space capacity reached |
 * | 1003 | account not confirmed |
 * | 1004 | account has been blocked |
 * | 1005 | account is missing profile |
 * | 1006 | account is missing payment method |
 * | 1007 | account has unconfirmed payment method |
 * | 1008 | the requested payment type is not available |
 * | 1009 | space not found |
 * | 1010 | account not found |
 * | 1011 | reservable not found |
 * | 1012 | account has open visit |
 * | 1013 | account already marked for deletion |
 * | 1014 | corporation reservations not enabled |
 * | 1014 | slot range unavailable |
 * | 1015 | reservation is too short |
 * | 1016 | the given token is invalid or unknown |
 * | 1017 | account already confirmed |
 * | 1018 | the unconfirmed email is already taken |
 * | 1019 | reservation not found |
 * | 1020 | reservation already cancelled |
 * | 1021 | reservation has already started |
 * | 1022 | terms document not found |
 * | 1023 | terms document is not the current |
 * | 1024 | need to accept current terms of service first |
 * | 1025 | slot range invalid |
 * | 1026 | account has reservations that have not been invoiced yet or that have not taken place yet |
 * | 1027 | terms document already accepted |
 * | 1028 | payment method verification failed |
 * | 1029 | account has open transaction |
 * | 1030 | reservable type not found |
 * | 1031 | account marked for deletion |
 * | 1032 | incorrect email or password |
 * | 1033 | account is missing dima customer ID |
 * | 1034 | dima customer not found |
 * | 2000 | this operation requires a client token |
 * | 2001 | this operation requires a customer token |
 * | 3000 | visit not found |
 * | 3001 | visit already checked in |
 * | 4000 | invalid voucher code |
 * | 4001 | voucher code already redeemed |
 * | 4010 | flexible time package not found |
 * | 5000 | forbidden for corporation account |
 * | 6000 | bahn card invalid |
 * | 6001 | bahn card expired |
 * | 6002 | bahn card already taken |
 * | 6100 | discount campaign not found |
 * | 8000 | permanent discount not found |
 *
 *
 * ##### 1001: Validation Failed
 *
 * The generic validation failed error additionally carries information about
 * the submitted field that triggered the validation failure (`arg` key in the
 * response) and the specific validation message (`validation`).
 *
 * The client will receive one 1001 error per failed validation.
 */
export type RootMutationType = {
  /** Accept new terms of service, allowing continued use of the app, and sometimes more features. */
  acceptCurrentTerms?: Maybe<Account>;
  /** Cancel a reservation */
  cancelReservation?: Maybe<Reservation>;
  /** Cancel a customer's visit if not checked in yet */
  cancelVisit?: Maybe<SimpleResponse>;
  /** Update payment method of your customer account with a credit card */
  configureCreditcard?: Maybe<PaymentMethod>;
  /** Update payment method of your customer account with PayPal */
  configurePaypal?: Maybe<PaymentMethod>;
  /** Confirm account with confirmation token */
  confirmAccount?: Maybe<Account>;
  /** Register a new customer account */
  createAccount?: Maybe<Account>;
  /** Create a reservation */
  createReservation?: Maybe<Reservation>;
  /** Create a new customer visit */
  createVisit?: Maybe<Visit>;
  /** Delete a customer account */
  deleteAccount?: Maybe<SimpleResponse>;
  /**
   * Deletes a permanent discount for the authenticated customer.
   * The saved BahnCard can also be removed, by removing its discount.
   */
  deletePermanentDiscount?: Maybe<SimpleResponse>;
  /** Purchase a Flexible Time Package for the customer */
  purchaseFlexibleTimePackage?: Maybe<MinuteWallet>;
  /** Redeem permanent discount */
  redeemPermanentDiscount?: Maybe<PermanentDiscount>;
  /** Redeem voucher to create a minute wallet for the customer */
  redeemVoucher?: Maybe<MinuteWallet>;
  /** Reject current terms of service. This is cause for account deletion. */
  rejectCurrentTerms?: Maybe<Account>;
  /** Resend email confirmation email */
  resendConfirmationEmail?: Maybe<SimpleResponse>;
  /** Request a password reset link via email */
  resetPassword?: Maybe<SimpleResponse>;
  /** Track terms document seen by the customer */
  seenCurrentTerms?: Maybe<Account>;
  /** Sign a customer in */
  signIn?: Maybe<SignInToken>;
  /** Change the email of an account */
  updateEmail?: Maybe<Account>;
  /** Update profile information of your customer account */
  updateProfile?: Maybe<Profile>;
};


/**
 * Operations for data mutation.
 *
 * ---
 *
 * #### API Errors
 *
 * Error responses of the API will have an `errors` key that contains
 * a list of error objects each carrying mandatory `code` and `message` keys.
 *
 * Courtesy of the GraphQL executor, you will also get information about the
 * location of the offending query/mutation in your request (`locations`),
 * as well as the `path` of the error.
 *
 * ##### Example
 *
 *     errors: [
 *       %{
 *         code: 1014,
 *         locations: [%{column: 0, line: 2}],
 *         message: "slot range unavailable",
 *         path: ["createReservation"]
 *       }
 *     ]
 *
 * ##### Error Index
 *
 * | Code | Description |
 * | ---- | ----------- |
 * | 1000 | something went wrong |
 * | 1001 | validation failed |
 * | 1002 | corporation visits not enabled |
 * | 1002 | space capacity reached |
 * | 1003 | account not confirmed |
 * | 1004 | account has been blocked |
 * | 1005 | account is missing profile |
 * | 1006 | account is missing payment method |
 * | 1007 | account has unconfirmed payment method |
 * | 1008 | the requested payment type is not available |
 * | 1009 | space not found |
 * | 1010 | account not found |
 * | 1011 | reservable not found |
 * | 1012 | account has open visit |
 * | 1013 | account already marked for deletion |
 * | 1014 | corporation reservations not enabled |
 * | 1014 | slot range unavailable |
 * | 1015 | reservation is too short |
 * | 1016 | the given token is invalid or unknown |
 * | 1017 | account already confirmed |
 * | 1018 | the unconfirmed email is already taken |
 * | 1019 | reservation not found |
 * | 1020 | reservation already cancelled |
 * | 1021 | reservation has already started |
 * | 1022 | terms document not found |
 * | 1023 | terms document is not the current |
 * | 1024 | need to accept current terms of service first |
 * | 1025 | slot range invalid |
 * | 1026 | account has reservations that have not been invoiced yet or that have not taken place yet |
 * | 1027 | terms document already accepted |
 * | 1028 | payment method verification failed |
 * | 1029 | account has open transaction |
 * | 1030 | reservable type not found |
 * | 1031 | account marked for deletion |
 * | 1032 | incorrect email or password |
 * | 1033 | account is missing dima customer ID |
 * | 1034 | dima customer not found |
 * | 2000 | this operation requires a client token |
 * | 2001 | this operation requires a customer token |
 * | 3000 | visit not found |
 * | 3001 | visit already checked in |
 * | 4000 | invalid voucher code |
 * | 4001 | voucher code already redeemed |
 * | 4010 | flexible time package not found |
 * | 5000 | forbidden for corporation account |
 * | 6000 | bahn card invalid |
 * | 6001 | bahn card expired |
 * | 6002 | bahn card already taken |
 * | 6100 | discount campaign not found |
 * | 8000 | permanent discount not found |
 *
 *
 * ##### 1001: Validation Failed
 *
 * The generic validation failed error additionally carries information about
 * the submitted field that triggered the validation failure (`arg` key in the
 * response) and the specific validation message (`validation`).
 *
 * The client will receive one 1001 error per failed validation.
 */
export type RootMutationTypeAcceptCurrentTermsArgs = {
  termsDocumentId: Scalars['UUIDV4']['input'];
};


/**
 * Operations for data mutation.
 *
 * ---
 *
 * #### API Errors
 *
 * Error responses of the API will have an `errors` key that contains
 * a list of error objects each carrying mandatory `code` and `message` keys.
 *
 * Courtesy of the GraphQL executor, you will also get information about the
 * location of the offending query/mutation in your request (`locations`),
 * as well as the `path` of the error.
 *
 * ##### Example
 *
 *     errors: [
 *       %{
 *         code: 1014,
 *         locations: [%{column: 0, line: 2}],
 *         message: "slot range unavailable",
 *         path: ["createReservation"]
 *       }
 *     ]
 *
 * ##### Error Index
 *
 * | Code | Description |
 * | ---- | ----------- |
 * | 1000 | something went wrong |
 * | 1001 | validation failed |
 * | 1002 | corporation visits not enabled |
 * | 1002 | space capacity reached |
 * | 1003 | account not confirmed |
 * | 1004 | account has been blocked |
 * | 1005 | account is missing profile |
 * | 1006 | account is missing payment method |
 * | 1007 | account has unconfirmed payment method |
 * | 1008 | the requested payment type is not available |
 * | 1009 | space not found |
 * | 1010 | account not found |
 * | 1011 | reservable not found |
 * | 1012 | account has open visit |
 * | 1013 | account already marked for deletion |
 * | 1014 | corporation reservations not enabled |
 * | 1014 | slot range unavailable |
 * | 1015 | reservation is too short |
 * | 1016 | the given token is invalid or unknown |
 * | 1017 | account already confirmed |
 * | 1018 | the unconfirmed email is already taken |
 * | 1019 | reservation not found |
 * | 1020 | reservation already cancelled |
 * | 1021 | reservation has already started |
 * | 1022 | terms document not found |
 * | 1023 | terms document is not the current |
 * | 1024 | need to accept current terms of service first |
 * | 1025 | slot range invalid |
 * | 1026 | account has reservations that have not been invoiced yet or that have not taken place yet |
 * | 1027 | terms document already accepted |
 * | 1028 | payment method verification failed |
 * | 1029 | account has open transaction |
 * | 1030 | reservable type not found |
 * | 1031 | account marked for deletion |
 * | 1032 | incorrect email or password |
 * | 1033 | account is missing dima customer ID |
 * | 1034 | dima customer not found |
 * | 2000 | this operation requires a client token |
 * | 2001 | this operation requires a customer token |
 * | 3000 | visit not found |
 * | 3001 | visit already checked in |
 * | 4000 | invalid voucher code |
 * | 4001 | voucher code already redeemed |
 * | 4010 | flexible time package not found |
 * | 5000 | forbidden for corporation account |
 * | 6000 | bahn card invalid |
 * | 6001 | bahn card expired |
 * | 6002 | bahn card already taken |
 * | 6100 | discount campaign not found |
 * | 8000 | permanent discount not found |
 *
 *
 * ##### 1001: Validation Failed
 *
 * The generic validation failed error additionally carries information about
 * the submitted field that triggered the validation failure (`arg` key in the
 * response) and the specific validation message (`validation`).
 *
 * The client will receive one 1001 error per failed validation.
 */
export type RootMutationTypeCancelReservationArgs = {
  reservationId: Scalars['UUIDV4']['input'];
};


/**
 * Operations for data mutation.
 *
 * ---
 *
 * #### API Errors
 *
 * Error responses of the API will have an `errors` key that contains
 * a list of error objects each carrying mandatory `code` and `message` keys.
 *
 * Courtesy of the GraphQL executor, you will also get information about the
 * location of the offending query/mutation in your request (`locations`),
 * as well as the `path` of the error.
 *
 * ##### Example
 *
 *     errors: [
 *       %{
 *         code: 1014,
 *         locations: [%{column: 0, line: 2}],
 *         message: "slot range unavailable",
 *         path: ["createReservation"]
 *       }
 *     ]
 *
 * ##### Error Index
 *
 * | Code | Description |
 * | ---- | ----------- |
 * | 1000 | something went wrong |
 * | 1001 | validation failed |
 * | 1002 | corporation visits not enabled |
 * | 1002 | space capacity reached |
 * | 1003 | account not confirmed |
 * | 1004 | account has been blocked |
 * | 1005 | account is missing profile |
 * | 1006 | account is missing payment method |
 * | 1007 | account has unconfirmed payment method |
 * | 1008 | the requested payment type is not available |
 * | 1009 | space not found |
 * | 1010 | account not found |
 * | 1011 | reservable not found |
 * | 1012 | account has open visit |
 * | 1013 | account already marked for deletion |
 * | 1014 | corporation reservations not enabled |
 * | 1014 | slot range unavailable |
 * | 1015 | reservation is too short |
 * | 1016 | the given token is invalid or unknown |
 * | 1017 | account already confirmed |
 * | 1018 | the unconfirmed email is already taken |
 * | 1019 | reservation not found |
 * | 1020 | reservation already cancelled |
 * | 1021 | reservation has already started |
 * | 1022 | terms document not found |
 * | 1023 | terms document is not the current |
 * | 1024 | need to accept current terms of service first |
 * | 1025 | slot range invalid |
 * | 1026 | account has reservations that have not been invoiced yet or that have not taken place yet |
 * | 1027 | terms document already accepted |
 * | 1028 | payment method verification failed |
 * | 1029 | account has open transaction |
 * | 1030 | reservable type not found |
 * | 1031 | account marked for deletion |
 * | 1032 | incorrect email or password |
 * | 1033 | account is missing dima customer ID |
 * | 1034 | dima customer not found |
 * | 2000 | this operation requires a client token |
 * | 2001 | this operation requires a customer token |
 * | 3000 | visit not found |
 * | 3001 | visit already checked in |
 * | 4000 | invalid voucher code |
 * | 4001 | voucher code already redeemed |
 * | 4010 | flexible time package not found |
 * | 5000 | forbidden for corporation account |
 * | 6000 | bahn card invalid |
 * | 6001 | bahn card expired |
 * | 6002 | bahn card already taken |
 * | 6100 | discount campaign not found |
 * | 8000 | permanent discount not found |
 *
 *
 * ##### 1001: Validation Failed
 *
 * The generic validation failed error additionally carries information about
 * the submitted field that triggered the validation failure (`arg` key in the
 * response) and the specific validation message (`validation`).
 *
 * The client will receive one 1001 error per failed validation.
 */
export type RootMutationTypeConfigureCreditcardArgs = {
  backurl: Scalars['String']['input'];
  cardExpiryDate: Scalars['String']['input'];
  cardType: Scalars['String']['input'];
  errorurl: Scalars['String']['input'];
  pseudocardpan: Scalars['String']['input'];
  successurl: Scalars['String']['input'];
  truncatedcardpan: Scalars['String']['input'];
};


/**
 * Operations for data mutation.
 *
 * ---
 *
 * #### API Errors
 *
 * Error responses of the API will have an `errors` key that contains
 * a list of error objects each carrying mandatory `code` and `message` keys.
 *
 * Courtesy of the GraphQL executor, you will also get information about the
 * location of the offending query/mutation in your request (`locations`),
 * as well as the `path` of the error.
 *
 * ##### Example
 *
 *     errors: [
 *       %{
 *         code: 1014,
 *         locations: [%{column: 0, line: 2}],
 *         message: "slot range unavailable",
 *         path: ["createReservation"]
 *       }
 *     ]
 *
 * ##### Error Index
 *
 * | Code | Description |
 * | ---- | ----------- |
 * | 1000 | something went wrong |
 * | 1001 | validation failed |
 * | 1002 | corporation visits not enabled |
 * | 1002 | space capacity reached |
 * | 1003 | account not confirmed |
 * | 1004 | account has been blocked |
 * | 1005 | account is missing profile |
 * | 1006 | account is missing payment method |
 * | 1007 | account has unconfirmed payment method |
 * | 1008 | the requested payment type is not available |
 * | 1009 | space not found |
 * | 1010 | account not found |
 * | 1011 | reservable not found |
 * | 1012 | account has open visit |
 * | 1013 | account already marked for deletion |
 * | 1014 | corporation reservations not enabled |
 * | 1014 | slot range unavailable |
 * | 1015 | reservation is too short |
 * | 1016 | the given token is invalid or unknown |
 * | 1017 | account already confirmed |
 * | 1018 | the unconfirmed email is already taken |
 * | 1019 | reservation not found |
 * | 1020 | reservation already cancelled |
 * | 1021 | reservation has already started |
 * | 1022 | terms document not found |
 * | 1023 | terms document is not the current |
 * | 1024 | need to accept current terms of service first |
 * | 1025 | slot range invalid |
 * | 1026 | account has reservations that have not been invoiced yet or that have not taken place yet |
 * | 1027 | terms document already accepted |
 * | 1028 | payment method verification failed |
 * | 1029 | account has open transaction |
 * | 1030 | reservable type not found |
 * | 1031 | account marked for deletion |
 * | 1032 | incorrect email or password |
 * | 1033 | account is missing dima customer ID |
 * | 1034 | dima customer not found |
 * | 2000 | this operation requires a client token |
 * | 2001 | this operation requires a customer token |
 * | 3000 | visit not found |
 * | 3001 | visit already checked in |
 * | 4000 | invalid voucher code |
 * | 4001 | voucher code already redeemed |
 * | 4010 | flexible time package not found |
 * | 5000 | forbidden for corporation account |
 * | 6000 | bahn card invalid |
 * | 6001 | bahn card expired |
 * | 6002 | bahn card already taken |
 * | 6100 | discount campaign not found |
 * | 8000 | permanent discount not found |
 *
 *
 * ##### 1001: Validation Failed
 *
 * The generic validation failed error additionally carries information about
 * the submitted field that triggered the validation failure (`arg` key in the
 * response) and the specific validation message (`validation`).
 *
 * The client will receive one 1001 error per failed validation.
 */
export type RootMutationTypeConfigurePaypalArgs = {
  backurl: Scalars['String']['input'];
  errorurl: Scalars['String']['input'];
  riskSessionCorrelationIdentifier?: InputMaybe<Scalars['String']['input']>;
  successurl: Scalars['String']['input'];
};


/**
 * Operations for data mutation.
 *
 * ---
 *
 * #### API Errors
 *
 * Error responses of the API will have an `errors` key that contains
 * a list of error objects each carrying mandatory `code` and `message` keys.
 *
 * Courtesy of the GraphQL executor, you will also get information about the
 * location of the offending query/mutation in your request (`locations`),
 * as well as the `path` of the error.
 *
 * ##### Example
 *
 *     errors: [
 *       %{
 *         code: 1014,
 *         locations: [%{column: 0, line: 2}],
 *         message: "slot range unavailable",
 *         path: ["createReservation"]
 *       }
 *     ]
 *
 * ##### Error Index
 *
 * | Code | Description |
 * | ---- | ----------- |
 * | 1000 | something went wrong |
 * | 1001 | validation failed |
 * | 1002 | corporation visits not enabled |
 * | 1002 | space capacity reached |
 * | 1003 | account not confirmed |
 * | 1004 | account has been blocked |
 * | 1005 | account is missing profile |
 * | 1006 | account is missing payment method |
 * | 1007 | account has unconfirmed payment method |
 * | 1008 | the requested payment type is not available |
 * | 1009 | space not found |
 * | 1010 | account not found |
 * | 1011 | reservable not found |
 * | 1012 | account has open visit |
 * | 1013 | account already marked for deletion |
 * | 1014 | corporation reservations not enabled |
 * | 1014 | slot range unavailable |
 * | 1015 | reservation is too short |
 * | 1016 | the given token is invalid or unknown |
 * | 1017 | account already confirmed |
 * | 1018 | the unconfirmed email is already taken |
 * | 1019 | reservation not found |
 * | 1020 | reservation already cancelled |
 * | 1021 | reservation has already started |
 * | 1022 | terms document not found |
 * | 1023 | terms document is not the current |
 * | 1024 | need to accept current terms of service first |
 * | 1025 | slot range invalid |
 * | 1026 | account has reservations that have not been invoiced yet or that have not taken place yet |
 * | 1027 | terms document already accepted |
 * | 1028 | payment method verification failed |
 * | 1029 | account has open transaction |
 * | 1030 | reservable type not found |
 * | 1031 | account marked for deletion |
 * | 1032 | incorrect email or password |
 * | 1033 | account is missing dima customer ID |
 * | 1034 | dima customer not found |
 * | 2000 | this operation requires a client token |
 * | 2001 | this operation requires a customer token |
 * | 3000 | visit not found |
 * | 3001 | visit already checked in |
 * | 4000 | invalid voucher code |
 * | 4001 | voucher code already redeemed |
 * | 4010 | flexible time package not found |
 * | 5000 | forbidden for corporation account |
 * | 6000 | bahn card invalid |
 * | 6001 | bahn card expired |
 * | 6002 | bahn card already taken |
 * | 6100 | discount campaign not found |
 * | 8000 | permanent discount not found |
 *
 *
 * ##### 1001: Validation Failed
 *
 * The generic validation failed error additionally carries information about
 * the submitted field that triggered the validation failure (`arg` key in the
 * response) and the specific validation message (`validation`).
 *
 * The client will receive one 1001 error per failed validation.
 */
export type RootMutationTypeConfirmAccountArgs = {
  token: Scalars['String']['input'];
};


/**
 * Operations for data mutation.
 *
 * ---
 *
 * #### API Errors
 *
 * Error responses of the API will have an `errors` key that contains
 * a list of error objects each carrying mandatory `code` and `message` keys.
 *
 * Courtesy of the GraphQL executor, you will also get information about the
 * location of the offending query/mutation in your request (`locations`),
 * as well as the `path` of the error.
 *
 * ##### Example
 *
 *     errors: [
 *       %{
 *         code: 1014,
 *         locations: [%{column: 0, line: 2}],
 *         message: "slot range unavailable",
 *         path: ["createReservation"]
 *       }
 *     ]
 *
 * ##### Error Index
 *
 * | Code | Description |
 * | ---- | ----------- |
 * | 1000 | something went wrong |
 * | 1001 | validation failed |
 * | 1002 | corporation visits not enabled |
 * | 1002 | space capacity reached |
 * | 1003 | account not confirmed |
 * | 1004 | account has been blocked |
 * | 1005 | account is missing profile |
 * | 1006 | account is missing payment method |
 * | 1007 | account has unconfirmed payment method |
 * | 1008 | the requested payment type is not available |
 * | 1009 | space not found |
 * | 1010 | account not found |
 * | 1011 | reservable not found |
 * | 1012 | account has open visit |
 * | 1013 | account already marked for deletion |
 * | 1014 | corporation reservations not enabled |
 * | 1014 | slot range unavailable |
 * | 1015 | reservation is too short |
 * | 1016 | the given token is invalid or unknown |
 * | 1017 | account already confirmed |
 * | 1018 | the unconfirmed email is already taken |
 * | 1019 | reservation not found |
 * | 1020 | reservation already cancelled |
 * | 1021 | reservation has already started |
 * | 1022 | terms document not found |
 * | 1023 | terms document is not the current |
 * | 1024 | need to accept current terms of service first |
 * | 1025 | slot range invalid |
 * | 1026 | account has reservations that have not been invoiced yet or that have not taken place yet |
 * | 1027 | terms document already accepted |
 * | 1028 | payment method verification failed |
 * | 1029 | account has open transaction |
 * | 1030 | reservable type not found |
 * | 1031 | account marked for deletion |
 * | 1032 | incorrect email or password |
 * | 1033 | account is missing dima customer ID |
 * | 1034 | dima customer not found |
 * | 2000 | this operation requires a client token |
 * | 2001 | this operation requires a customer token |
 * | 3000 | visit not found |
 * | 3001 | visit already checked in |
 * | 4000 | invalid voucher code |
 * | 4001 | voucher code already redeemed |
 * | 4010 | flexible time package not found |
 * | 5000 | forbidden for corporation account |
 * | 6000 | bahn card invalid |
 * | 6001 | bahn card expired |
 * | 6002 | bahn card already taken |
 * | 6100 | discount campaign not found |
 * | 8000 | permanent discount not found |
 *
 *
 * ##### 1001: Validation Failed
 *
 * The generic validation failed error additionally carries information about
 * the submitted field that triggered the validation failure (`arg` key in the
 * response) and the specific validation message (`validation`).
 *
 * The client will receive one 1001 error per failed validation.
 */
export type RootMutationTypeCreateAccountArgs = {
  acceptedTermsDocumentId?: InputMaybe<Scalars['UUIDV4']['input']>;
  email: Scalars['String']['input'];
  password: Scalars['String']['input'];
  profile?: InputMaybe<ProfileInput>;
  subscribeToNewsletter?: InputMaybe<Scalars['Boolean']['input']>;
};


/**
 * Operations for data mutation.
 *
 * ---
 *
 * #### API Errors
 *
 * Error responses of the API will have an `errors` key that contains
 * a list of error objects each carrying mandatory `code` and `message` keys.
 *
 * Courtesy of the GraphQL executor, you will also get information about the
 * location of the offending query/mutation in your request (`locations`),
 * as well as the `path` of the error.
 *
 * ##### Example
 *
 *     errors: [
 *       %{
 *         code: 1014,
 *         locations: [%{column: 0, line: 2}],
 *         message: "slot range unavailable",
 *         path: ["createReservation"]
 *       }
 *     ]
 *
 * ##### Error Index
 *
 * | Code | Description |
 * | ---- | ----------- |
 * | 1000 | something went wrong |
 * | 1001 | validation failed |
 * | 1002 | corporation visits not enabled |
 * | 1002 | space capacity reached |
 * | 1003 | account not confirmed |
 * | 1004 | account has been blocked |
 * | 1005 | account is missing profile |
 * | 1006 | account is missing payment method |
 * | 1007 | account has unconfirmed payment method |
 * | 1008 | the requested payment type is not available |
 * | 1009 | space not found |
 * | 1010 | account not found |
 * | 1011 | reservable not found |
 * | 1012 | account has open visit |
 * | 1013 | account already marked for deletion |
 * | 1014 | corporation reservations not enabled |
 * | 1014 | slot range unavailable |
 * | 1015 | reservation is too short |
 * | 1016 | the given token is invalid or unknown |
 * | 1017 | account already confirmed |
 * | 1018 | the unconfirmed email is already taken |
 * | 1019 | reservation not found |
 * | 1020 | reservation already cancelled |
 * | 1021 | reservation has already started |
 * | 1022 | terms document not found |
 * | 1023 | terms document is not the current |
 * | 1024 | need to accept current terms of service first |
 * | 1025 | slot range invalid |
 * | 1026 | account has reservations that have not been invoiced yet or that have not taken place yet |
 * | 1027 | terms document already accepted |
 * | 1028 | payment method verification failed |
 * | 1029 | account has open transaction |
 * | 1030 | reservable type not found |
 * | 1031 | account marked for deletion |
 * | 1032 | incorrect email or password |
 * | 1033 | account is missing dima customer ID |
 * | 1034 | dima customer not found |
 * | 2000 | this operation requires a client token |
 * | 2001 | this operation requires a customer token |
 * | 3000 | visit not found |
 * | 3001 | visit already checked in |
 * | 4000 | invalid voucher code |
 * | 4001 | voucher code already redeemed |
 * | 4010 | flexible time package not found |
 * | 5000 | forbidden for corporation account |
 * | 6000 | bahn card invalid |
 * | 6001 | bahn card expired |
 * | 6002 | bahn card already taken |
 * | 6100 | discount campaign not found |
 * | 8000 | permanent discount not found |
 *
 *
 * ##### 1001: Validation Failed
 *
 * The generic validation failed error additionally carries information about
 * the submitted field that triggered the validation failure (`arg` key in the
 * response) and the specific validation message (`validation`).
 *
 * The client will receive one 1001 error per failed validation.
 */
export type RootMutationTypeCreateReservationArgs = {
  date: Scalars['Date']['input'];
  fromSlot: Scalars['TimeslotIndex']['input'];
  reservableId: Scalars['UUIDV4']['input'];
  title?: InputMaybe<Scalars['String']['input']>;
  toSlot: Scalars['TimeslotIndex']['input'];
};


/**
 * Operations for data mutation.
 *
 * ---
 *
 * #### API Errors
 *
 * Error responses of the API will have an `errors` key that contains
 * a list of error objects each carrying mandatory `code` and `message` keys.
 *
 * Courtesy of the GraphQL executor, you will also get information about the
 * location of the offending query/mutation in your request (`locations`),
 * as well as the `path` of the error.
 *
 * ##### Example
 *
 *     errors: [
 *       %{
 *         code: 1014,
 *         locations: [%{column: 0, line: 2}],
 *         message: "slot range unavailable",
 *         path: ["createReservation"]
 *       }
 *     ]
 *
 * ##### Error Index
 *
 * | Code | Description |
 * | ---- | ----------- |
 * | 1000 | something went wrong |
 * | 1001 | validation failed |
 * | 1002 | corporation visits not enabled |
 * | 1002 | space capacity reached |
 * | 1003 | account not confirmed |
 * | 1004 | account has been blocked |
 * | 1005 | account is missing profile |
 * | 1006 | account is missing payment method |
 * | 1007 | account has unconfirmed payment method |
 * | 1008 | the requested payment type is not available |
 * | 1009 | space not found |
 * | 1010 | account not found |
 * | 1011 | reservable not found |
 * | 1012 | account has open visit |
 * | 1013 | account already marked for deletion |
 * | 1014 | corporation reservations not enabled |
 * | 1014 | slot range unavailable |
 * | 1015 | reservation is too short |
 * | 1016 | the given token is invalid or unknown |
 * | 1017 | account already confirmed |
 * | 1018 | the unconfirmed email is already taken |
 * | 1019 | reservation not found |
 * | 1020 | reservation already cancelled |
 * | 1021 | reservation has already started |
 * | 1022 | terms document not found |
 * | 1023 | terms document is not the current |
 * | 1024 | need to accept current terms of service first |
 * | 1025 | slot range invalid |
 * | 1026 | account has reservations that have not been invoiced yet or that have not taken place yet |
 * | 1027 | terms document already accepted |
 * | 1028 | payment method verification failed |
 * | 1029 | account has open transaction |
 * | 1030 | reservable type not found |
 * | 1031 | account marked for deletion |
 * | 1032 | incorrect email or password |
 * | 1033 | account is missing dima customer ID |
 * | 1034 | dima customer not found |
 * | 2000 | this operation requires a client token |
 * | 2001 | this operation requires a customer token |
 * | 3000 | visit not found |
 * | 3001 | visit already checked in |
 * | 4000 | invalid voucher code |
 * | 4001 | voucher code already redeemed |
 * | 4010 | flexible time package not found |
 * | 5000 | forbidden for corporation account |
 * | 6000 | bahn card invalid |
 * | 6001 | bahn card expired |
 * | 6002 | bahn card already taken |
 * | 6100 | discount campaign not found |
 * | 8000 | permanent discount not found |
 *
 *
 * ##### 1001: Validation Failed
 *
 * The generic validation failed error additionally carries information about
 * the submitted field that triggered the validation failure (`arg` key in the
 * response) and the specific validation message (`validation`).
 *
 * The client will receive one 1001 error per failed validation.
 */
export type RootMutationTypeCreateVisitArgs = {
  riskSessionCorrelationIdentifier?: InputMaybe<Scalars['String']['input']>;
  spaceId: Scalars['UUIDV4']['input'];
};


/**
 * Operations for data mutation.
 *
 * ---
 *
 * #### API Errors
 *
 * Error responses of the API will have an `errors` key that contains
 * a list of error objects each carrying mandatory `code` and `message` keys.
 *
 * Courtesy of the GraphQL executor, you will also get information about the
 * location of the offending query/mutation in your request (`locations`),
 * as well as the `path` of the error.
 *
 * ##### Example
 *
 *     errors: [
 *       %{
 *         code: 1014,
 *         locations: [%{column: 0, line: 2}],
 *         message: "slot range unavailable",
 *         path: ["createReservation"]
 *       }
 *     ]
 *
 * ##### Error Index
 *
 * | Code | Description |
 * | ---- | ----------- |
 * | 1000 | something went wrong |
 * | 1001 | validation failed |
 * | 1002 | corporation visits not enabled |
 * | 1002 | space capacity reached |
 * | 1003 | account not confirmed |
 * | 1004 | account has been blocked |
 * | 1005 | account is missing profile |
 * | 1006 | account is missing payment method |
 * | 1007 | account has unconfirmed payment method |
 * | 1008 | the requested payment type is not available |
 * | 1009 | space not found |
 * | 1010 | account not found |
 * | 1011 | reservable not found |
 * | 1012 | account has open visit |
 * | 1013 | account already marked for deletion |
 * | 1014 | corporation reservations not enabled |
 * | 1014 | slot range unavailable |
 * | 1015 | reservation is too short |
 * | 1016 | the given token is invalid or unknown |
 * | 1017 | account already confirmed |
 * | 1018 | the unconfirmed email is already taken |
 * | 1019 | reservation not found |
 * | 1020 | reservation already cancelled |
 * | 1021 | reservation has already started |
 * | 1022 | terms document not found |
 * | 1023 | terms document is not the current |
 * | 1024 | need to accept current terms of service first |
 * | 1025 | slot range invalid |
 * | 1026 | account has reservations that have not been invoiced yet or that have not taken place yet |
 * | 1027 | terms document already accepted |
 * | 1028 | payment method verification failed |
 * | 1029 | account has open transaction |
 * | 1030 | reservable type not found |
 * | 1031 | account marked for deletion |
 * | 1032 | incorrect email or password |
 * | 1033 | account is missing dima customer ID |
 * | 1034 | dima customer not found |
 * | 2000 | this operation requires a client token |
 * | 2001 | this operation requires a customer token |
 * | 3000 | visit not found |
 * | 3001 | visit already checked in |
 * | 4000 | invalid voucher code |
 * | 4001 | voucher code already redeemed |
 * | 4010 | flexible time package not found |
 * | 5000 | forbidden for corporation account |
 * | 6000 | bahn card invalid |
 * | 6001 | bahn card expired |
 * | 6002 | bahn card already taken |
 * | 6100 | discount campaign not found |
 * | 8000 | permanent discount not found |
 *
 *
 * ##### 1001: Validation Failed
 *
 * The generic validation failed error additionally carries information about
 * the submitted field that triggered the validation failure (`arg` key in the
 * response) and the specific validation message (`validation`).
 *
 * The client will receive one 1001 error per failed validation.
 */
export type RootMutationTypeDeletePermanentDiscountArgs = {
  permanentDiscountId: Scalars['UUIDV4']['input'];
};


/**
 * Operations for data mutation.
 *
 * ---
 *
 * #### API Errors
 *
 * Error responses of the API will have an `errors` key that contains
 * a list of error objects each carrying mandatory `code` and `message` keys.
 *
 * Courtesy of the GraphQL executor, you will also get information about the
 * location of the offending query/mutation in your request (`locations`),
 * as well as the `path` of the error.
 *
 * ##### Example
 *
 *     errors: [
 *       %{
 *         code: 1014,
 *         locations: [%{column: 0, line: 2}],
 *         message: "slot range unavailable",
 *         path: ["createReservation"]
 *       }
 *     ]
 *
 * ##### Error Index
 *
 * | Code | Description |
 * | ---- | ----------- |
 * | 1000 | something went wrong |
 * | 1001 | validation failed |
 * | 1002 | corporation visits not enabled |
 * | 1002 | space capacity reached |
 * | 1003 | account not confirmed |
 * | 1004 | account has been blocked |
 * | 1005 | account is missing profile |
 * | 1006 | account is missing payment method |
 * | 1007 | account has unconfirmed payment method |
 * | 1008 | the requested payment type is not available |
 * | 1009 | space not found |
 * | 1010 | account not found |
 * | 1011 | reservable not found |
 * | 1012 | account has open visit |
 * | 1013 | account already marked for deletion |
 * | 1014 | corporation reservations not enabled |
 * | 1014 | slot range unavailable |
 * | 1015 | reservation is too short |
 * | 1016 | the given token is invalid or unknown |
 * | 1017 | account already confirmed |
 * | 1018 | the unconfirmed email is already taken |
 * | 1019 | reservation not found |
 * | 1020 | reservation already cancelled |
 * | 1021 | reservation has already started |
 * | 1022 | terms document not found |
 * | 1023 | terms document is not the current |
 * | 1024 | need to accept current terms of service first |
 * | 1025 | slot range invalid |
 * | 1026 | account has reservations that have not been invoiced yet or that have not taken place yet |
 * | 1027 | terms document already accepted |
 * | 1028 | payment method verification failed |
 * | 1029 | account has open transaction |
 * | 1030 | reservable type not found |
 * | 1031 | account marked for deletion |
 * | 1032 | incorrect email or password |
 * | 1033 | account is missing dima customer ID |
 * | 1034 | dima customer not found |
 * | 2000 | this operation requires a client token |
 * | 2001 | this operation requires a customer token |
 * | 3000 | visit not found |
 * | 3001 | visit already checked in |
 * | 4000 | invalid voucher code |
 * | 4001 | voucher code already redeemed |
 * | 4010 | flexible time package not found |
 * | 5000 | forbidden for corporation account |
 * | 6000 | bahn card invalid |
 * | 6001 | bahn card expired |
 * | 6002 | bahn card already taken |
 * | 6100 | discount campaign not found |
 * | 8000 | permanent discount not found |
 *
 *
 * ##### 1001: Validation Failed
 *
 * The generic validation failed error additionally carries information about
 * the submitted field that triggered the validation failure (`arg` key in the
 * response) and the specific validation message (`validation`).
 *
 * The client will receive one 1001 error per failed validation.
 */
export type RootMutationTypePurchaseFlexibleTimePackageArgs = {
  activationDate?: InputMaybe<Scalars['Date']['input']>;
  flexibleTimePackageId: Scalars['UUIDV4']['input'];
  riskSessionCorrelationIdentifier?: InputMaybe<Scalars['String']['input']>;
};


/**
 * Operations for data mutation.
 *
 * ---
 *
 * #### API Errors
 *
 * Error responses of the API will have an `errors` key that contains
 * a list of error objects each carrying mandatory `code` and `message` keys.
 *
 * Courtesy of the GraphQL executor, you will also get information about the
 * location of the offending query/mutation in your request (`locations`),
 * as well as the `path` of the error.
 *
 * ##### Example
 *
 *     errors: [
 *       %{
 *         code: 1014,
 *         locations: [%{column: 0, line: 2}],
 *         message: "slot range unavailable",
 *         path: ["createReservation"]
 *       }
 *     ]
 *
 * ##### Error Index
 *
 * | Code | Description |
 * | ---- | ----------- |
 * | 1000 | something went wrong |
 * | 1001 | validation failed |
 * | 1002 | corporation visits not enabled |
 * | 1002 | space capacity reached |
 * | 1003 | account not confirmed |
 * | 1004 | account has been blocked |
 * | 1005 | account is missing profile |
 * | 1006 | account is missing payment method |
 * | 1007 | account has unconfirmed payment method |
 * | 1008 | the requested payment type is not available |
 * | 1009 | space not found |
 * | 1010 | account not found |
 * | 1011 | reservable not found |
 * | 1012 | account has open visit |
 * | 1013 | account already marked for deletion |
 * | 1014 | corporation reservations not enabled |
 * | 1014 | slot range unavailable |
 * | 1015 | reservation is too short |
 * | 1016 | the given token is invalid or unknown |
 * | 1017 | account already confirmed |
 * | 1018 | the unconfirmed email is already taken |
 * | 1019 | reservation not found |
 * | 1020 | reservation already cancelled |
 * | 1021 | reservation has already started |
 * | 1022 | terms document not found |
 * | 1023 | terms document is not the current |
 * | 1024 | need to accept current terms of service first |
 * | 1025 | slot range invalid |
 * | 1026 | account has reservations that have not been invoiced yet or that have not taken place yet |
 * | 1027 | terms document already accepted |
 * | 1028 | payment method verification failed |
 * | 1029 | account has open transaction |
 * | 1030 | reservable type not found |
 * | 1031 | account marked for deletion |
 * | 1032 | incorrect email or password |
 * | 1033 | account is missing dima customer ID |
 * | 1034 | dima customer not found |
 * | 2000 | this operation requires a client token |
 * | 2001 | this operation requires a customer token |
 * | 3000 | visit not found |
 * | 3001 | visit already checked in |
 * | 4000 | invalid voucher code |
 * | 4001 | voucher code already redeemed |
 * | 4010 | flexible time package not found |
 * | 5000 | forbidden for corporation account |
 * | 6000 | bahn card invalid |
 * | 6001 | bahn card expired |
 * | 6002 | bahn card already taken |
 * | 6100 | discount campaign not found |
 * | 8000 | permanent discount not found |
 *
 *
 * ##### 1001: Validation Failed
 *
 * The generic validation failed error additionally carries information about
 * the submitted field that triggered the validation failure (`arg` key in the
 * response) and the specific validation message (`validation`).
 *
 * The client will receive one 1001 error per failed validation.
 */
export type RootMutationTypeRedeemPermanentDiscountArgs = {
  bahnCardNumber: Scalars['String']['input'];
};


/**
 * Operations for data mutation.
 *
 * ---
 *
 * #### API Errors
 *
 * Error responses of the API will have an `errors` key that contains
 * a list of error objects each carrying mandatory `code` and `message` keys.
 *
 * Courtesy of the GraphQL executor, you will also get information about the
 * location of the offending query/mutation in your request (`locations`),
 * as well as the `path` of the error.
 *
 * ##### Example
 *
 *     errors: [
 *       %{
 *         code: 1014,
 *         locations: [%{column: 0, line: 2}],
 *         message: "slot range unavailable",
 *         path: ["createReservation"]
 *       }
 *     ]
 *
 * ##### Error Index
 *
 * | Code | Description |
 * | ---- | ----------- |
 * | 1000 | something went wrong |
 * | 1001 | validation failed |
 * | 1002 | corporation visits not enabled |
 * | 1002 | space capacity reached |
 * | 1003 | account not confirmed |
 * | 1004 | account has been blocked |
 * | 1005 | account is missing profile |
 * | 1006 | account is missing payment method |
 * | 1007 | account has unconfirmed payment method |
 * | 1008 | the requested payment type is not available |
 * | 1009 | space not found |
 * | 1010 | account not found |
 * | 1011 | reservable not found |
 * | 1012 | account has open visit |
 * | 1013 | account already marked for deletion |
 * | 1014 | corporation reservations not enabled |
 * | 1014 | slot range unavailable |
 * | 1015 | reservation is too short |
 * | 1016 | the given token is invalid or unknown |
 * | 1017 | account already confirmed |
 * | 1018 | the unconfirmed email is already taken |
 * | 1019 | reservation not found |
 * | 1020 | reservation already cancelled |
 * | 1021 | reservation has already started |
 * | 1022 | terms document not found |
 * | 1023 | terms document is not the current |
 * | 1024 | need to accept current terms of service first |
 * | 1025 | slot range invalid |
 * | 1026 | account has reservations that have not been invoiced yet or that have not taken place yet |
 * | 1027 | terms document already accepted |
 * | 1028 | payment method verification failed |
 * | 1029 | account has open transaction |
 * | 1030 | reservable type not found |
 * | 1031 | account marked for deletion |
 * | 1032 | incorrect email or password |
 * | 1033 | account is missing dima customer ID |
 * | 1034 | dima customer not found |
 * | 2000 | this operation requires a client token |
 * | 2001 | this operation requires a customer token |
 * | 3000 | visit not found |
 * | 3001 | visit already checked in |
 * | 4000 | invalid voucher code |
 * | 4001 | voucher code already redeemed |
 * | 4010 | flexible time package not found |
 * | 5000 | forbidden for corporation account |
 * | 6000 | bahn card invalid |
 * | 6001 | bahn card expired |
 * | 6002 | bahn card already taken |
 * | 6100 | discount campaign not found |
 * | 8000 | permanent discount not found |
 *
 *
 * ##### 1001: Validation Failed
 *
 * The generic validation failed error additionally carries information about
 * the submitted field that triggered the validation failure (`arg` key in the
 * response) and the specific validation message (`validation`).
 *
 * The client will receive one 1001 error per failed validation.
 */
export type RootMutationTypeRedeemVoucherArgs = {
  code: Scalars['String']['input'];
};


/**
 * Operations for data mutation.
 *
 * ---
 *
 * #### API Errors
 *
 * Error responses of the API will have an `errors` key that contains
 * a list of error objects each carrying mandatory `code` and `message` keys.
 *
 * Courtesy of the GraphQL executor, you will also get information about the
 * location of the offending query/mutation in your request (`locations`),
 * as well as the `path` of the error.
 *
 * ##### Example
 *
 *     errors: [
 *       %{
 *         code: 1014,
 *         locations: [%{column: 0, line: 2}],
 *         message: "slot range unavailable",
 *         path: ["createReservation"]
 *       }
 *     ]
 *
 * ##### Error Index
 *
 * | Code | Description |
 * | ---- | ----------- |
 * | 1000 | something went wrong |
 * | 1001 | validation failed |
 * | 1002 | corporation visits not enabled |
 * | 1002 | space capacity reached |
 * | 1003 | account not confirmed |
 * | 1004 | account has been blocked |
 * | 1005 | account is missing profile |
 * | 1006 | account is missing payment method |
 * | 1007 | account has unconfirmed payment method |
 * | 1008 | the requested payment type is not available |
 * | 1009 | space not found |
 * | 1010 | account not found |
 * | 1011 | reservable not found |
 * | 1012 | account has open visit |
 * | 1013 | account already marked for deletion |
 * | 1014 | corporation reservations not enabled |
 * | 1014 | slot range unavailable |
 * | 1015 | reservation is too short |
 * | 1016 | the given token is invalid or unknown |
 * | 1017 | account already confirmed |
 * | 1018 | the unconfirmed email is already taken |
 * | 1019 | reservation not found |
 * | 1020 | reservation already cancelled |
 * | 1021 | reservation has already started |
 * | 1022 | terms document not found |
 * | 1023 | terms document is not the current |
 * | 1024 | need to accept current terms of service first |
 * | 1025 | slot range invalid |
 * | 1026 | account has reservations that have not been invoiced yet or that have not taken place yet |
 * | 1027 | terms document already accepted |
 * | 1028 | payment method verification failed |
 * | 1029 | account has open transaction |
 * | 1030 | reservable type not found |
 * | 1031 | account marked for deletion |
 * | 1032 | incorrect email or password |
 * | 1033 | account is missing dima customer ID |
 * | 1034 | dima customer not found |
 * | 2000 | this operation requires a client token |
 * | 2001 | this operation requires a customer token |
 * | 3000 | visit not found |
 * | 3001 | visit already checked in |
 * | 4000 | invalid voucher code |
 * | 4001 | voucher code already redeemed |
 * | 4010 | flexible time package not found |
 * | 5000 | forbidden for corporation account |
 * | 6000 | bahn card invalid |
 * | 6001 | bahn card expired |
 * | 6002 | bahn card already taken |
 * | 6100 | discount campaign not found |
 * | 8000 | permanent discount not found |
 *
 *
 * ##### 1001: Validation Failed
 *
 * The generic validation failed error additionally carries information about
 * the submitted field that triggered the validation failure (`arg` key in the
 * response) and the specific validation message (`validation`).
 *
 * The client will receive one 1001 error per failed validation.
 */
export type RootMutationTypeRejectCurrentTermsArgs = {
  termsDocumentId: Scalars['UUIDV4']['input'];
};


/**
 * Operations for data mutation.
 *
 * ---
 *
 * #### API Errors
 *
 * Error responses of the API will have an `errors` key that contains
 * a list of error objects each carrying mandatory `code` and `message` keys.
 *
 * Courtesy of the GraphQL executor, you will also get information about the
 * location of the offending query/mutation in your request (`locations`),
 * as well as the `path` of the error.
 *
 * ##### Example
 *
 *     errors: [
 *       %{
 *         code: 1014,
 *         locations: [%{column: 0, line: 2}],
 *         message: "slot range unavailable",
 *         path: ["createReservation"]
 *       }
 *     ]
 *
 * ##### Error Index
 *
 * | Code | Description |
 * | ---- | ----------- |
 * | 1000 | something went wrong |
 * | 1001 | validation failed |
 * | 1002 | corporation visits not enabled |
 * | 1002 | space capacity reached |
 * | 1003 | account not confirmed |
 * | 1004 | account has been blocked |
 * | 1005 | account is missing profile |
 * | 1006 | account is missing payment method |
 * | 1007 | account has unconfirmed payment method |
 * | 1008 | the requested payment type is not available |
 * | 1009 | space not found |
 * | 1010 | account not found |
 * | 1011 | reservable not found |
 * | 1012 | account has open visit |
 * | 1013 | account already marked for deletion |
 * | 1014 | corporation reservations not enabled |
 * | 1014 | slot range unavailable |
 * | 1015 | reservation is too short |
 * | 1016 | the given token is invalid or unknown |
 * | 1017 | account already confirmed |
 * | 1018 | the unconfirmed email is already taken |
 * | 1019 | reservation not found |
 * | 1020 | reservation already cancelled |
 * | 1021 | reservation has already started |
 * | 1022 | terms document not found |
 * | 1023 | terms document is not the current |
 * | 1024 | need to accept current terms of service first |
 * | 1025 | slot range invalid |
 * | 1026 | account has reservations that have not been invoiced yet or that have not taken place yet |
 * | 1027 | terms document already accepted |
 * | 1028 | payment method verification failed |
 * | 1029 | account has open transaction |
 * | 1030 | reservable type not found |
 * | 1031 | account marked for deletion |
 * | 1032 | incorrect email or password |
 * | 1033 | account is missing dima customer ID |
 * | 1034 | dima customer not found |
 * | 2000 | this operation requires a client token |
 * | 2001 | this operation requires a customer token |
 * | 3000 | visit not found |
 * | 3001 | visit already checked in |
 * | 4000 | invalid voucher code |
 * | 4001 | voucher code already redeemed |
 * | 4010 | flexible time package not found |
 * | 5000 | forbidden for corporation account |
 * | 6000 | bahn card invalid |
 * | 6001 | bahn card expired |
 * | 6002 | bahn card already taken |
 * | 6100 | discount campaign not found |
 * | 8000 | permanent discount not found |
 *
 *
 * ##### 1001: Validation Failed
 *
 * The generic validation failed error additionally carries information about
 * the submitted field that triggered the validation failure (`arg` key in the
 * response) and the specific validation message (`validation`).
 *
 * The client will receive one 1001 error per failed validation.
 */
export type RootMutationTypeResetPasswordArgs = {
  email: Scalars['String']['input'];
};


/**
 * Operations for data mutation.
 *
 * ---
 *
 * #### API Errors
 *
 * Error responses of the API will have an `errors` key that contains
 * a list of error objects each carrying mandatory `code` and `message` keys.
 *
 * Courtesy of the GraphQL executor, you will also get information about the
 * location of the offending query/mutation in your request (`locations`),
 * as well as the `path` of the error.
 *
 * ##### Example
 *
 *     errors: [
 *       %{
 *         code: 1014,
 *         locations: [%{column: 0, line: 2}],
 *         message: "slot range unavailable",
 *         path: ["createReservation"]
 *       }
 *     ]
 *
 * ##### Error Index
 *
 * | Code | Description |
 * | ---- | ----------- |
 * | 1000 | something went wrong |
 * | 1001 | validation failed |
 * | 1002 | corporation visits not enabled |
 * | 1002 | space capacity reached |
 * | 1003 | account not confirmed |
 * | 1004 | account has been blocked |
 * | 1005 | account is missing profile |
 * | 1006 | account is missing payment method |
 * | 1007 | account has unconfirmed payment method |
 * | 1008 | the requested payment type is not available |
 * | 1009 | space not found |
 * | 1010 | account not found |
 * | 1011 | reservable not found |
 * | 1012 | account has open visit |
 * | 1013 | account already marked for deletion |
 * | 1014 | corporation reservations not enabled |
 * | 1014 | slot range unavailable |
 * | 1015 | reservation is too short |
 * | 1016 | the given token is invalid or unknown |
 * | 1017 | account already confirmed |
 * | 1018 | the unconfirmed email is already taken |
 * | 1019 | reservation not found |
 * | 1020 | reservation already cancelled |
 * | 1021 | reservation has already started |
 * | 1022 | terms document not found |
 * | 1023 | terms document is not the current |
 * | 1024 | need to accept current terms of service first |
 * | 1025 | slot range invalid |
 * | 1026 | account has reservations that have not been invoiced yet or that have not taken place yet |
 * | 1027 | terms document already accepted |
 * | 1028 | payment method verification failed |
 * | 1029 | account has open transaction |
 * | 1030 | reservable type not found |
 * | 1031 | account marked for deletion |
 * | 1032 | incorrect email or password |
 * | 1033 | account is missing dima customer ID |
 * | 1034 | dima customer not found |
 * | 2000 | this operation requires a client token |
 * | 2001 | this operation requires a customer token |
 * | 3000 | visit not found |
 * | 3001 | visit already checked in |
 * | 4000 | invalid voucher code |
 * | 4001 | voucher code already redeemed |
 * | 4010 | flexible time package not found |
 * | 5000 | forbidden for corporation account |
 * | 6000 | bahn card invalid |
 * | 6001 | bahn card expired |
 * | 6002 | bahn card already taken |
 * | 6100 | discount campaign not found |
 * | 8000 | permanent discount not found |
 *
 *
 * ##### 1001: Validation Failed
 *
 * The generic validation failed error additionally carries information about
 * the submitted field that triggered the validation failure (`arg` key in the
 * response) and the specific validation message (`validation`).
 *
 * The client will receive one 1001 error per failed validation.
 */
export type RootMutationTypeSeenCurrentTermsArgs = {
  termsDocumentId: Scalars['UUIDV4']['input'];
};


/**
 * Operations for data mutation.
 *
 * ---
 *
 * #### API Errors
 *
 * Error responses of the API will have an `errors` key that contains
 * a list of error objects each carrying mandatory `code` and `message` keys.
 *
 * Courtesy of the GraphQL executor, you will also get information about the
 * location of the offending query/mutation in your request (`locations`),
 * as well as the `path` of the error.
 *
 * ##### Example
 *
 *     errors: [
 *       %{
 *         code: 1014,
 *         locations: [%{column: 0, line: 2}],
 *         message: "slot range unavailable",
 *         path: ["createReservation"]
 *       }
 *     ]
 *
 * ##### Error Index
 *
 * | Code | Description |
 * | ---- | ----------- |
 * | 1000 | something went wrong |
 * | 1001 | validation failed |
 * | 1002 | corporation visits not enabled |
 * | 1002 | space capacity reached |
 * | 1003 | account not confirmed |
 * | 1004 | account has been blocked |
 * | 1005 | account is missing profile |
 * | 1006 | account is missing payment method |
 * | 1007 | account has unconfirmed payment method |
 * | 1008 | the requested payment type is not available |
 * | 1009 | space not found |
 * | 1010 | account not found |
 * | 1011 | reservable not found |
 * | 1012 | account has open visit |
 * | 1013 | account already marked for deletion |
 * | 1014 | corporation reservations not enabled |
 * | 1014 | slot range unavailable |
 * | 1015 | reservation is too short |
 * | 1016 | the given token is invalid or unknown |
 * | 1017 | account already confirmed |
 * | 1018 | the unconfirmed email is already taken |
 * | 1019 | reservation not found |
 * | 1020 | reservation already cancelled |
 * | 1021 | reservation has already started |
 * | 1022 | terms document not found |
 * | 1023 | terms document is not the current |
 * | 1024 | need to accept current terms of service first |
 * | 1025 | slot range invalid |
 * | 1026 | account has reservations that have not been invoiced yet or that have not taken place yet |
 * | 1027 | terms document already accepted |
 * | 1028 | payment method verification failed |
 * | 1029 | account has open transaction |
 * | 1030 | reservable type not found |
 * | 1031 | account marked for deletion |
 * | 1032 | incorrect email or password |
 * | 1033 | account is missing dima customer ID |
 * | 1034 | dima customer not found |
 * | 2000 | this operation requires a client token |
 * | 2001 | this operation requires a customer token |
 * | 3000 | visit not found |
 * | 3001 | visit already checked in |
 * | 4000 | invalid voucher code |
 * | 4001 | voucher code already redeemed |
 * | 4010 | flexible time package not found |
 * | 5000 | forbidden for corporation account |
 * | 6000 | bahn card invalid |
 * | 6001 | bahn card expired |
 * | 6002 | bahn card already taken |
 * | 6100 | discount campaign not found |
 * | 8000 | permanent discount not found |
 *
 *
 * ##### 1001: Validation Failed
 *
 * The generic validation failed error additionally carries information about
 * the submitted field that triggered the validation failure (`arg` key in the
 * response) and the specific validation message (`validation`).
 *
 * The client will receive one 1001 error per failed validation.
 */
export type RootMutationTypeSignInArgs = {
  deviceIdentifier: Scalars['String']['input'];
  deviceName: Scalars['String']['input'];
  email: Scalars['String']['input'];
  password: Scalars['String']['input'];
  redirectPath: Scalars['String']['input'];
};


/**
 * Operations for data mutation.
 *
 * ---
 *
 * #### API Errors
 *
 * Error responses of the API will have an `errors` key that contains
 * a list of error objects each carrying mandatory `code` and `message` keys.
 *
 * Courtesy of the GraphQL executor, you will also get information about the
 * location of the offending query/mutation in your request (`locations`),
 * as well as the `path` of the error.
 *
 * ##### Example
 *
 *     errors: [
 *       %{
 *         code: 1014,
 *         locations: [%{column: 0, line: 2}],
 *         message: "slot range unavailable",
 *         path: ["createReservation"]
 *       }
 *     ]
 *
 * ##### Error Index
 *
 * | Code | Description |
 * | ---- | ----------- |
 * | 1000 | something went wrong |
 * | 1001 | validation failed |
 * | 1002 | corporation visits not enabled |
 * | 1002 | space capacity reached |
 * | 1003 | account not confirmed |
 * | 1004 | account has been blocked |
 * | 1005 | account is missing profile |
 * | 1006 | account is missing payment method |
 * | 1007 | account has unconfirmed payment method |
 * | 1008 | the requested payment type is not available |
 * | 1009 | space not found |
 * | 1010 | account not found |
 * | 1011 | reservable not found |
 * | 1012 | account has open visit |
 * | 1013 | account already marked for deletion |
 * | 1014 | corporation reservations not enabled |
 * | 1014 | slot range unavailable |
 * | 1015 | reservation is too short |
 * | 1016 | the given token is invalid or unknown |
 * | 1017 | account already confirmed |
 * | 1018 | the unconfirmed email is already taken |
 * | 1019 | reservation not found |
 * | 1020 | reservation already cancelled |
 * | 1021 | reservation has already started |
 * | 1022 | terms document not found |
 * | 1023 | terms document is not the current |
 * | 1024 | need to accept current terms of service first |
 * | 1025 | slot range invalid |
 * | 1026 | account has reservations that have not been invoiced yet or that have not taken place yet |
 * | 1027 | terms document already accepted |
 * | 1028 | payment method verification failed |
 * | 1029 | account has open transaction |
 * | 1030 | reservable type not found |
 * | 1031 | account marked for deletion |
 * | 1032 | incorrect email or password |
 * | 1033 | account is missing dima customer ID |
 * | 1034 | dima customer not found |
 * | 2000 | this operation requires a client token |
 * | 2001 | this operation requires a customer token |
 * | 3000 | visit not found |
 * | 3001 | visit already checked in |
 * | 4000 | invalid voucher code |
 * | 4001 | voucher code already redeemed |
 * | 4010 | flexible time package not found |
 * | 5000 | forbidden for corporation account |
 * | 6000 | bahn card invalid |
 * | 6001 | bahn card expired |
 * | 6002 | bahn card already taken |
 * | 6100 | discount campaign not found |
 * | 8000 | permanent discount not found |
 *
 *
 * ##### 1001: Validation Failed
 *
 * The generic validation failed error additionally carries information about
 * the submitted field that triggered the validation failure (`arg` key in the
 * response) and the specific validation message (`validation`).
 *
 * The client will receive one 1001 error per failed validation.
 */
export type RootMutationTypeUpdateEmailArgs = {
  unconfirmedEmail: Scalars['String']['input'];
};


/**
 * Operations for data mutation.
 *
 * ---
 *
 * #### API Errors
 *
 * Error responses of the API will have an `errors` key that contains
 * a list of error objects each carrying mandatory `code` and `message` keys.
 *
 * Courtesy of the GraphQL executor, you will also get information about the
 * location of the offending query/mutation in your request (`locations`),
 * as well as the `path` of the error.
 *
 * ##### Example
 *
 *     errors: [
 *       %{
 *         code: 1014,
 *         locations: [%{column: 0, line: 2}],
 *         message: "slot range unavailable",
 *         path: ["createReservation"]
 *       }
 *     ]
 *
 * ##### Error Index
 *
 * | Code | Description |
 * | ---- | ----------- |
 * | 1000 | something went wrong |
 * | 1001 | validation failed |
 * | 1002 | corporation visits not enabled |
 * | 1002 | space capacity reached |
 * | 1003 | account not confirmed |
 * | 1004 | account has been blocked |
 * | 1005 | account is missing profile |
 * | 1006 | account is missing payment method |
 * | 1007 | account has unconfirmed payment method |
 * | 1008 | the requested payment type is not available |
 * | 1009 | space not found |
 * | 1010 | account not found |
 * | 1011 | reservable not found |
 * | 1012 | account has open visit |
 * | 1013 | account already marked for deletion |
 * | 1014 | corporation reservations not enabled |
 * | 1014 | slot range unavailable |
 * | 1015 | reservation is too short |
 * | 1016 | the given token is invalid or unknown |
 * | 1017 | account already confirmed |
 * | 1018 | the unconfirmed email is already taken |
 * | 1019 | reservation not found |
 * | 1020 | reservation already cancelled |
 * | 1021 | reservation has already started |
 * | 1022 | terms document not found |
 * | 1023 | terms document is not the current |
 * | 1024 | need to accept current terms of service first |
 * | 1025 | slot range invalid |
 * | 1026 | account has reservations that have not been invoiced yet or that have not taken place yet |
 * | 1027 | terms document already accepted |
 * | 1028 | payment method verification failed |
 * | 1029 | account has open transaction |
 * | 1030 | reservable type not found |
 * | 1031 | account marked for deletion |
 * | 1032 | incorrect email or password |
 * | 1033 | account is missing dima customer ID |
 * | 1034 | dima customer not found |
 * | 2000 | this operation requires a client token |
 * | 2001 | this operation requires a customer token |
 * | 3000 | visit not found |
 * | 3001 | visit already checked in |
 * | 4000 | invalid voucher code |
 * | 4001 | voucher code already redeemed |
 * | 4010 | flexible time package not found |
 * | 5000 | forbidden for corporation account |
 * | 6000 | bahn card invalid |
 * | 6001 | bahn card expired |
 * | 6002 | bahn card already taken |
 * | 6100 | discount campaign not found |
 * | 8000 | permanent discount not found |
 *
 *
 * ##### 1001: Validation Failed
 *
 * The generic validation failed error additionally carries information about
 * the submitted field that triggered the validation failure (`arg` key in the
 * response) and the specific validation message (`validation`).
 *
 * The client will receive one 1001 error per failed validation.
 */
export type RootMutationTypeUpdateProfileArgs = {
  address: Scalars['String']['input'];
  city: Scalars['String']['input'];
  company?: InputMaybe<Scalars['String']['input']>;
  country: Scalars['String']['input'];
  firstName: Scalars['String']['input'];
  lastName: Scalars['String']['input'];
  optionalInformation?: InputMaybe<Scalars['String']['input']>;
  zip: Scalars['String']['input'];
};

/**
 * Operations for data fetching.
 *
 * ---
 *
 * #### API Errors
 *
 * Error responses of the API will have an `errors` key that contains
 * a list of error objects each carrying mandatory `code` and `message` keys.
 *
 * Courtesy of the GraphQL executor, you will also get information about the
 * location of the offending query/mutation in your request (`locations`),
 * as well as the `path` of the error.
 *
 * ##### Example
 *
 *     errors: [
 *       %{
 *         code: 1014,
 *         locations: [%{column: 0, line: 2}],
 *         message: "slot range unavailable",
 *         path: ["createReservation"]
 *       }
 *     ]
 *
 * ##### Error Index
 *
 * | Code | Description |
 * | ---- | ----------- |
 * | 1000 | something went wrong |
 * | 1001 | validation failed |
 * | 1002 | corporation visits not enabled |
 * | 1002 | space capacity reached |
 * | 1003 | account not confirmed |
 * | 1004 | account has been blocked |
 * | 1005 | account is missing profile |
 * | 1006 | account is missing payment method |
 * | 1007 | account has unconfirmed payment method |
 * | 1008 | the requested payment type is not available |
 * | 1009 | space not found |
 * | 1010 | account not found |
 * | 1011 | reservable not found |
 * | 1012 | account has open visit |
 * | 1013 | account already marked for deletion |
 * | 1014 | corporation reservations not enabled |
 * | 1014 | slot range unavailable |
 * | 1015 | reservation is too short |
 * | 1016 | the given token is invalid or unknown |
 * | 1017 | account already confirmed |
 * | 1018 | the unconfirmed email is already taken |
 * | 1019 | reservation not found |
 * | 1020 | reservation already cancelled |
 * | 1021 | reservation has already started |
 * | 1022 | terms document not found |
 * | 1023 | terms document is not the current |
 * | 1024 | need to accept current terms of service first |
 * | 1025 | slot range invalid |
 * | 1026 | account has reservations that have not been invoiced yet or that have not taken place yet |
 * | 1027 | terms document already accepted |
 * | 1028 | payment method verification failed |
 * | 1029 | account has open transaction |
 * | 1030 | reservable type not found |
 * | 1031 | account marked for deletion |
 * | 1032 | incorrect email or password |
 * | 1033 | account is missing dima customer ID |
 * | 1034 | dima customer not found |
 * | 2000 | this operation requires a client token |
 * | 2001 | this operation requires a customer token |
 * | 3000 | visit not found |
 * | 3001 | visit already checked in |
 * | 4000 | invalid voucher code |
 * | 4001 | voucher code already redeemed |
 * | 4010 | flexible time package not found |
 * | 5000 | forbidden for corporation account |
 * | 6000 | bahn card invalid |
 * | 6001 | bahn card expired |
 * | 6002 | bahn card already taken |
 * | 6100 | discount campaign not found |
 * | 8000 | permanent discount not found |
 *
 *
 * ##### 1001: Validation Failed
 *
 * The generic validation failed error additionally carries information about
 * the submitted field that triggered the validation failure (`arg` key in the
 * response) and the specific validation message (`validation`).
 *
 * The client will receive one 1001 error per failed validation.
 */
export type RootQueryType = {
  /** Get a list of available payment methods */
  availablePaymentTypes?: Maybe<Array<Maybe<Scalars['String']['output']>>>;
  /** Get params for a credit card check with BS Payone */
  creditCardCheckParams?: Maybe<CreditCardCheckParams>;
  /** Get active Flexible Time Package and Promotions (published and not revoked yet) */
  currentPromotions?: Maybe<Array<Maybe<CurrentPromotion>>>;
  /** Get information about the environment */
  environment?: Maybe<Environment>;
  /** Get active Flexible Time Package (published and not revoked yet) */
  flexibleTimePackages?: Maybe<Array<Maybe<FlexibleTimePackage>>>;
  /** Get information about your customer account */
  me?: Maybe<Account>;
  /** Get customers minute wallets */
  myMinuteWallets?: Maybe<Array<Maybe<MinuteWallet>>>;
  /** Get the past visits of the customer */
  myPastVisits?: Maybe<Array<Maybe<PastVisit>>>;
  /** Get customer reservations */
  myReservations?: Maybe<Array<Maybe<Reservation>>>;
  /** Get the customers visit, if they have one */
  myVisit?: Maybe<Visit>;
  /** Get information on a single reservable */
  reservable?: Maybe<Reservable>;
  /** Get a list of all reservable categories of a space */
  reservableCategories?: Maybe<Array<Maybe<ReservableCategory>>>;
  /** Get a list of all reservable types */
  reservableTypes?: Maybe<Array<Maybe<ReservableType>>>;
  reservationSuggestions?: Maybe<ReservationSuggestions>;
  /** Get information on a single space */
  space?: Maybe<Space>;
  /** Get a list of all accessible spaces to the authenticated user */
  spaces?: Maybe<Array<Maybe<Space>>>;
  /** Get a list of all terms documents */
  termsDocuments?: Maybe<Array<Maybe<TermsDocument>>>;
  /** Get information on a reservable's timetable */
  timetable?: Maybe<Array<Maybe<Timeslot>>>;
};


/**
 * Operations for data fetching.
 *
 * ---
 *
 * #### API Errors
 *
 * Error responses of the API will have an `errors` key that contains
 * a list of error objects each carrying mandatory `code` and `message` keys.
 *
 * Courtesy of the GraphQL executor, you will also get information about the
 * location of the offending query/mutation in your request (`locations`),
 * as well as the `path` of the error.
 *
 * ##### Example
 *
 *     errors: [
 *       %{
 *         code: 1014,
 *         locations: [%{column: 0, line: 2}],
 *         message: "slot range unavailable",
 *         path: ["createReservation"]
 *       }
 *     ]
 *
 * ##### Error Index
 *
 * | Code | Description |
 * | ---- | ----------- |
 * | 1000 | something went wrong |
 * | 1001 | validation failed |
 * | 1002 | corporation visits not enabled |
 * | 1002 | space capacity reached |
 * | 1003 | account not confirmed |
 * | 1004 | account has been blocked |
 * | 1005 | account is missing profile |
 * | 1006 | account is missing payment method |
 * | 1007 | account has unconfirmed payment method |
 * | 1008 | the requested payment type is not available |
 * | 1009 | space not found |
 * | 1010 | account not found |
 * | 1011 | reservable not found |
 * | 1012 | account has open visit |
 * | 1013 | account already marked for deletion |
 * | 1014 | corporation reservations not enabled |
 * | 1014 | slot range unavailable |
 * | 1015 | reservation is too short |
 * | 1016 | the given token is invalid or unknown |
 * | 1017 | account already confirmed |
 * | 1018 | the unconfirmed email is already taken |
 * | 1019 | reservation not found |
 * | 1020 | reservation already cancelled |
 * | 1021 | reservation has already started |
 * | 1022 | terms document not found |
 * | 1023 | terms document is not the current |
 * | 1024 | need to accept current terms of service first |
 * | 1025 | slot range invalid |
 * | 1026 | account has reservations that have not been invoiced yet or that have not taken place yet |
 * | 1027 | terms document already accepted |
 * | 1028 | payment method verification failed |
 * | 1029 | account has open transaction |
 * | 1030 | reservable type not found |
 * | 1031 | account marked for deletion |
 * | 1032 | incorrect email or password |
 * | 1033 | account is missing dima customer ID |
 * | 1034 | dima customer not found |
 * | 2000 | this operation requires a client token |
 * | 2001 | this operation requires a customer token |
 * | 3000 | visit not found |
 * | 3001 | visit already checked in |
 * | 4000 | invalid voucher code |
 * | 4001 | voucher code already redeemed |
 * | 4010 | flexible time package not found |
 * | 5000 | forbidden for corporation account |
 * | 6000 | bahn card invalid |
 * | 6001 | bahn card expired |
 * | 6002 | bahn card already taken |
 * | 6100 | discount campaign not found |
 * | 8000 | permanent discount not found |
 *
 *
 * ##### 1001: Validation Failed
 *
 * The generic validation failed error additionally carries information about
 * the submitted field that triggered the validation failure (`arg` key in the
 * response) and the specific validation message (`validation`).
 *
 * The client will receive one 1001 error per failed validation.
 */
export type RootQueryTypeMyMinuteWalletsArgs = {
  onlyActive?: InputMaybe<Scalars['Boolean']['input']>;
};


/**
 * Operations for data fetching.
 *
 * ---
 *
 * #### API Errors
 *
 * Error responses of the API will have an `errors` key that contains
 * a list of error objects each carrying mandatory `code` and `message` keys.
 *
 * Courtesy of the GraphQL executor, you will also get information about the
 * location of the offending query/mutation in your request (`locations`),
 * as well as the `path` of the error.
 *
 * ##### Example
 *
 *     errors: [
 *       %{
 *         code: 1014,
 *         locations: [%{column: 0, line: 2}],
 *         message: "slot range unavailable",
 *         path: ["createReservation"]
 *       }
 *     ]
 *
 * ##### Error Index
 *
 * | Code | Description |
 * | ---- | ----------- |
 * | 1000 | something went wrong |
 * | 1001 | validation failed |
 * | 1002 | corporation visits not enabled |
 * | 1002 | space capacity reached |
 * | 1003 | account not confirmed |
 * | 1004 | account has been blocked |
 * | 1005 | account is missing profile |
 * | 1006 | account is missing payment method |
 * | 1007 | account has unconfirmed payment method |
 * | 1008 | the requested payment type is not available |
 * | 1009 | space not found |
 * | 1010 | account not found |
 * | 1011 | reservable not found |
 * | 1012 | account has open visit |
 * | 1013 | account already marked for deletion |
 * | 1014 | corporation reservations not enabled |
 * | 1014 | slot range unavailable |
 * | 1015 | reservation is too short |
 * | 1016 | the given token is invalid or unknown |
 * | 1017 | account already confirmed |
 * | 1018 | the unconfirmed email is already taken |
 * | 1019 | reservation not found |
 * | 1020 | reservation already cancelled |
 * | 1021 | reservation has already started |
 * | 1022 | terms document not found |
 * | 1023 | terms document is not the current |
 * | 1024 | need to accept current terms of service first |
 * | 1025 | slot range invalid |
 * | 1026 | account has reservations that have not been invoiced yet or that have not taken place yet |
 * | 1027 | terms document already accepted |
 * | 1028 | payment method verification failed |
 * | 1029 | account has open transaction |
 * | 1030 | reservable type not found |
 * | 1031 | account marked for deletion |
 * | 1032 | incorrect email or password |
 * | 1033 | account is missing dima customer ID |
 * | 1034 | dima customer not found |
 * | 2000 | this operation requires a client token |
 * | 2001 | this operation requires a customer token |
 * | 3000 | visit not found |
 * | 3001 | visit already checked in |
 * | 4000 | invalid voucher code |
 * | 4001 | voucher code already redeemed |
 * | 4010 | flexible time package not found |
 * | 5000 | forbidden for corporation account |
 * | 6000 | bahn card invalid |
 * | 6001 | bahn card expired |
 * | 6002 | bahn card already taken |
 * | 6100 | discount campaign not found |
 * | 8000 | permanent discount not found |
 *
 *
 * ##### 1001: Validation Failed
 *
 * The generic validation failed error additionally carries information about
 * the submitted field that triggered the validation failure (`arg` key in the
 * response) and the specific validation message (`validation`).
 *
 * The client will receive one 1001 error per failed validation.
 */
export type RootQueryTypeMyReservationsArgs = {
  onlyActive?: InputMaybe<Scalars['Boolean']['input']>;
};


/**
 * Operations for data fetching.
 *
 * ---
 *
 * #### API Errors
 *
 * Error responses of the API will have an `errors` key that contains
 * a list of error objects each carrying mandatory `code` and `message` keys.
 *
 * Courtesy of the GraphQL executor, you will also get information about the
 * location of the offending query/mutation in your request (`locations`),
 * as well as the `path` of the error.
 *
 * ##### Example
 *
 *     errors: [
 *       %{
 *         code: 1014,
 *         locations: [%{column: 0, line: 2}],
 *         message: "slot range unavailable",
 *         path: ["createReservation"]
 *       }
 *     ]
 *
 * ##### Error Index
 *
 * | Code | Description |
 * | ---- | ----------- |
 * | 1000 | something went wrong |
 * | 1001 | validation failed |
 * | 1002 | corporation visits not enabled |
 * | 1002 | space capacity reached |
 * | 1003 | account not confirmed |
 * | 1004 | account has been blocked |
 * | 1005 | account is missing profile |
 * | 1006 | account is missing payment method |
 * | 1007 | account has unconfirmed payment method |
 * | 1008 | the requested payment type is not available |
 * | 1009 | space not found |
 * | 1010 | account not found |
 * | 1011 | reservable not found |
 * | 1012 | account has open visit |
 * | 1013 | account already marked for deletion |
 * | 1014 | corporation reservations not enabled |
 * | 1014 | slot range unavailable |
 * | 1015 | reservation is too short |
 * | 1016 | the given token is invalid or unknown |
 * | 1017 | account already confirmed |
 * | 1018 | the unconfirmed email is already taken |
 * | 1019 | reservation not found |
 * | 1020 | reservation already cancelled |
 * | 1021 | reservation has already started |
 * | 1022 | terms document not found |
 * | 1023 | terms document is not the current |
 * | 1024 | need to accept current terms of service first |
 * | 1025 | slot range invalid |
 * | 1026 | account has reservations that have not been invoiced yet or that have not taken place yet |
 * | 1027 | terms document already accepted |
 * | 1028 | payment method verification failed |
 * | 1029 | account has open transaction |
 * | 1030 | reservable type not found |
 * | 1031 | account marked for deletion |
 * | 1032 | incorrect email or password |
 * | 1033 | account is missing dima customer ID |
 * | 1034 | dima customer not found |
 * | 2000 | this operation requires a client token |
 * | 2001 | this operation requires a customer token |
 * | 3000 | visit not found |
 * | 3001 | visit already checked in |
 * | 4000 | invalid voucher code |
 * | 4001 | voucher code already redeemed |
 * | 4010 | flexible time package not found |
 * | 5000 | forbidden for corporation account |
 * | 6000 | bahn card invalid |
 * | 6001 | bahn card expired |
 * | 6002 | bahn card already taken |
 * | 6100 | discount campaign not found |
 * | 8000 | permanent discount not found |
 *
 *
 * ##### 1001: Validation Failed
 *
 * The generic validation failed error additionally carries information about
 * the submitted field that triggered the validation failure (`arg` key in the
 * response) and the specific validation message (`validation`).
 *
 * The client will receive one 1001 error per failed validation.
 */
export type RootQueryTypeReservableArgs = {
  id: Scalars['UUIDV4']['input'];
};


/**
 * Operations for data fetching.
 *
 * ---
 *
 * #### API Errors
 *
 * Error responses of the API will have an `errors` key that contains
 * a list of error objects each carrying mandatory `code` and `message` keys.
 *
 * Courtesy of the GraphQL executor, you will also get information about the
 * location of the offending query/mutation in your request (`locations`),
 * as well as the `path` of the error.
 *
 * ##### Example
 *
 *     errors: [
 *       %{
 *         code: 1014,
 *         locations: [%{column: 0, line: 2}],
 *         message: "slot range unavailable",
 *         path: ["createReservation"]
 *       }
 *     ]
 *
 * ##### Error Index
 *
 * | Code | Description |
 * | ---- | ----------- |
 * | 1000 | something went wrong |
 * | 1001 | validation failed |
 * | 1002 | corporation visits not enabled |
 * | 1002 | space capacity reached |
 * | 1003 | account not confirmed |
 * | 1004 | account has been blocked |
 * | 1005 | account is missing profile |
 * | 1006 | account is missing payment method |
 * | 1007 | account has unconfirmed payment method |
 * | 1008 | the requested payment type is not available |
 * | 1009 | space not found |
 * | 1010 | account not found |
 * | 1011 | reservable not found |
 * | 1012 | account has open visit |
 * | 1013 | account already marked for deletion |
 * | 1014 | corporation reservations not enabled |
 * | 1014 | slot range unavailable |
 * | 1015 | reservation is too short |
 * | 1016 | the given token is invalid or unknown |
 * | 1017 | account already confirmed |
 * | 1018 | the unconfirmed email is already taken |
 * | 1019 | reservation not found |
 * | 1020 | reservation already cancelled |
 * | 1021 | reservation has already started |
 * | 1022 | terms document not found |
 * | 1023 | terms document is not the current |
 * | 1024 | need to accept current terms of service first |
 * | 1025 | slot range invalid |
 * | 1026 | account has reservations that have not been invoiced yet or that have not taken place yet |
 * | 1027 | terms document already accepted |
 * | 1028 | payment method verification failed |
 * | 1029 | account has open transaction |
 * | 1030 | reservable type not found |
 * | 1031 | account marked for deletion |
 * | 1032 | incorrect email or password |
 * | 1033 | account is missing dima customer ID |
 * | 1034 | dima customer not found |
 * | 2000 | this operation requires a client token |
 * | 2001 | this operation requires a customer token |
 * | 3000 | visit not found |
 * | 3001 | visit already checked in |
 * | 4000 | invalid voucher code |
 * | 4001 | voucher code already redeemed |
 * | 4010 | flexible time package not found |
 * | 5000 | forbidden for corporation account |
 * | 6000 | bahn card invalid |
 * | 6001 | bahn card expired |
 * | 6002 | bahn card already taken |
 * | 6100 | discount campaign not found |
 * | 8000 | permanent discount not found |
 *
 *
 * ##### 1001: Validation Failed
 *
 * The generic validation failed error additionally carries information about
 * the submitted field that triggered the validation failure (`arg` key in the
 * response) and the specific validation message (`validation`).
 *
 * The client will receive one 1001 error per failed validation.
 */
export type RootQueryTypeReservableCategoriesArgs = {
  spaceId: Scalars['UUIDV4']['input'];
};


/**
 * Operations for data fetching.
 *
 * ---
 *
 * #### API Errors
 *
 * Error responses of the API will have an `errors` key that contains
 * a list of error objects each carrying mandatory `code` and `message` keys.
 *
 * Courtesy of the GraphQL executor, you will also get information about the
 * location of the offending query/mutation in your request (`locations`),
 * as well as the `path` of the error.
 *
 * ##### Example
 *
 *     errors: [
 *       %{
 *         code: 1014,
 *         locations: [%{column: 0, line: 2}],
 *         message: "slot range unavailable",
 *         path: ["createReservation"]
 *       }
 *     ]
 *
 * ##### Error Index
 *
 * | Code | Description |
 * | ---- | ----------- |
 * | 1000 | something went wrong |
 * | 1001 | validation failed |
 * | 1002 | corporation visits not enabled |
 * | 1002 | space capacity reached |
 * | 1003 | account not confirmed |
 * | 1004 | account has been blocked |
 * | 1005 | account is missing profile |
 * | 1006 | account is missing payment method |
 * | 1007 | account has unconfirmed payment method |
 * | 1008 | the requested payment type is not available |
 * | 1009 | space not found |
 * | 1010 | account not found |
 * | 1011 | reservable not found |
 * | 1012 | account has open visit |
 * | 1013 | account already marked for deletion |
 * | 1014 | corporation reservations not enabled |
 * | 1014 | slot range unavailable |
 * | 1015 | reservation is too short |
 * | 1016 | the given token is invalid or unknown |
 * | 1017 | account already confirmed |
 * | 1018 | the unconfirmed email is already taken |
 * | 1019 | reservation not found |
 * | 1020 | reservation already cancelled |
 * | 1021 | reservation has already started |
 * | 1022 | terms document not found |
 * | 1023 | terms document is not the current |
 * | 1024 | need to accept current terms of service first |
 * | 1025 | slot range invalid |
 * | 1026 | account has reservations that have not been invoiced yet or that have not taken place yet |
 * | 1027 | terms document already accepted |
 * | 1028 | payment method verification failed |
 * | 1029 | account has open transaction |
 * | 1030 | reservable type not found |
 * | 1031 | account marked for deletion |
 * | 1032 | incorrect email or password |
 * | 1033 | account is missing dima customer ID |
 * | 1034 | dima customer not found |
 * | 2000 | this operation requires a client token |
 * | 2001 | this operation requires a customer token |
 * | 3000 | visit not found |
 * | 3001 | visit already checked in |
 * | 4000 | invalid voucher code |
 * | 4001 | voucher code already redeemed |
 * | 4010 | flexible time package not found |
 * | 5000 | forbidden for corporation account |
 * | 6000 | bahn card invalid |
 * | 6001 | bahn card expired |
 * | 6002 | bahn card already taken |
 * | 6100 | discount campaign not found |
 * | 8000 | permanent discount not found |
 *
 *
 * ##### 1001: Validation Failed
 *
 * The generic validation failed error additionally carries information about
 * the submitted field that triggered the validation failure (`arg` key in the
 * response) and the specific validation message (`validation`).
 *
 * The client will receive one 1001 error per failed validation.
 */
export type RootQueryTypeReservationSuggestionsArgs = {
  date?: InputMaybe<Scalars['Date']['input']>;
  minCapacity?: InputMaybe<Scalars['Int']['input']>;
  reservableTypeId?: InputMaybe<Scalars['UUIDV4']['input']>;
  slotRange?: InputMaybe<TimeslotRangeInput>;
  spaceId: Scalars['UUIDV4']['input'];
};


/**
 * Operations for data fetching.
 *
 * ---
 *
 * #### API Errors
 *
 * Error responses of the API will have an `errors` key that contains
 * a list of error objects each carrying mandatory `code` and `message` keys.
 *
 * Courtesy of the GraphQL executor, you will also get information about the
 * location of the offending query/mutation in your request (`locations`),
 * as well as the `path` of the error.
 *
 * ##### Example
 *
 *     errors: [
 *       %{
 *         code: 1014,
 *         locations: [%{column: 0, line: 2}],
 *         message: "slot range unavailable",
 *         path: ["createReservation"]
 *       }
 *     ]
 *
 * ##### Error Index
 *
 * | Code | Description |
 * | ---- | ----------- |
 * | 1000 | something went wrong |
 * | 1001 | validation failed |
 * | 1002 | corporation visits not enabled |
 * | 1002 | space capacity reached |
 * | 1003 | account not confirmed |
 * | 1004 | account has been blocked |
 * | 1005 | account is missing profile |
 * | 1006 | account is missing payment method |
 * | 1007 | account has unconfirmed payment method |
 * | 1008 | the requested payment type is not available |
 * | 1009 | space not found |
 * | 1010 | account not found |
 * | 1011 | reservable not found |
 * | 1012 | account has open visit |
 * | 1013 | account already marked for deletion |
 * | 1014 | corporation reservations not enabled |
 * | 1014 | slot range unavailable |
 * | 1015 | reservation is too short |
 * | 1016 | the given token is invalid or unknown |
 * | 1017 | account already confirmed |
 * | 1018 | the unconfirmed email is already taken |
 * | 1019 | reservation not found |
 * | 1020 | reservation already cancelled |
 * | 1021 | reservation has already started |
 * | 1022 | terms document not found |
 * | 1023 | terms document is not the current |
 * | 1024 | need to accept current terms of service first |
 * | 1025 | slot range invalid |
 * | 1026 | account has reservations that have not been invoiced yet or that have not taken place yet |
 * | 1027 | terms document already accepted |
 * | 1028 | payment method verification failed |
 * | 1029 | account has open transaction |
 * | 1030 | reservable type not found |
 * | 1031 | account marked for deletion |
 * | 1032 | incorrect email or password |
 * | 1033 | account is missing dima customer ID |
 * | 1034 | dima customer not found |
 * | 2000 | this operation requires a client token |
 * | 2001 | this operation requires a customer token |
 * | 3000 | visit not found |
 * | 3001 | visit already checked in |
 * | 4000 | invalid voucher code |
 * | 4001 | voucher code already redeemed |
 * | 4010 | flexible time package not found |
 * | 5000 | forbidden for corporation account |
 * | 6000 | bahn card invalid |
 * | 6001 | bahn card expired |
 * | 6002 | bahn card already taken |
 * | 6100 | discount campaign not found |
 * | 8000 | permanent discount not found |
 *
 *
 * ##### 1001: Validation Failed
 *
 * The generic validation failed error additionally carries information about
 * the submitted field that triggered the validation failure (`arg` key in the
 * response) and the specific validation message (`validation`).
 *
 * The client will receive one 1001 error per failed validation.
 */
export type RootQueryTypeSpaceArgs = {
  id: Scalars['UUIDV4']['input'];
};


/**
 * Operations for data fetching.
 *
 * ---
 *
 * #### API Errors
 *
 * Error responses of the API will have an `errors` key that contains
 * a list of error objects each carrying mandatory `code` and `message` keys.
 *
 * Courtesy of the GraphQL executor, you will also get information about the
 * location of the offending query/mutation in your request (`locations`),
 * as well as the `path` of the error.
 *
 * ##### Example
 *
 *     errors: [
 *       %{
 *         code: 1014,
 *         locations: [%{column: 0, line: 2}],
 *         message: "slot range unavailable",
 *         path: ["createReservation"]
 *       }
 *     ]
 *
 * ##### Error Index
 *
 * | Code | Description |
 * | ---- | ----------- |
 * | 1000 | something went wrong |
 * | 1001 | validation failed |
 * | 1002 | corporation visits not enabled |
 * | 1002 | space capacity reached |
 * | 1003 | account not confirmed |
 * | 1004 | account has been blocked |
 * | 1005 | account is missing profile |
 * | 1006 | account is missing payment method |
 * | 1007 | account has unconfirmed payment method |
 * | 1008 | the requested payment type is not available |
 * | 1009 | space not found |
 * | 1010 | account not found |
 * | 1011 | reservable not found |
 * | 1012 | account has open visit |
 * | 1013 | account already marked for deletion |
 * | 1014 | corporation reservations not enabled |
 * | 1014 | slot range unavailable |
 * | 1015 | reservation is too short |
 * | 1016 | the given token is invalid or unknown |
 * | 1017 | account already confirmed |
 * | 1018 | the unconfirmed email is already taken |
 * | 1019 | reservation not found |
 * | 1020 | reservation already cancelled |
 * | 1021 | reservation has already started |
 * | 1022 | terms document not found |
 * | 1023 | terms document is not the current |
 * | 1024 | need to accept current terms of service first |
 * | 1025 | slot range invalid |
 * | 1026 | account has reservations that have not been invoiced yet or that have not taken place yet |
 * | 1027 | terms document already accepted |
 * | 1028 | payment method verification failed |
 * | 1029 | account has open transaction |
 * | 1030 | reservable type not found |
 * | 1031 | account marked for deletion |
 * | 1032 | incorrect email or password |
 * | 1033 | account is missing dima customer ID |
 * | 1034 | dima customer not found |
 * | 2000 | this operation requires a client token |
 * | 2001 | this operation requires a customer token |
 * | 3000 | visit not found |
 * | 3001 | visit already checked in |
 * | 4000 | invalid voucher code |
 * | 4001 | voucher code already redeemed |
 * | 4010 | flexible time package not found |
 * | 5000 | forbidden for corporation account |
 * | 6000 | bahn card invalid |
 * | 6001 | bahn card expired |
 * | 6002 | bahn card already taken |
 * | 6100 | discount campaign not found |
 * | 8000 | permanent discount not found |
 *
 *
 * ##### 1001: Validation Failed
 *
 * The generic validation failed error additionally carries information about
 * the submitted field that triggered the validation failure (`arg` key in the
 * response) and the specific validation message (`validation`).
 *
 * The client will receive one 1001 error per failed validation.
 */
export type RootQueryTypeSpacesArgs = {
  date?: InputMaybe<Scalars['Date']['input']>;
};


/**
 * Operations for data fetching.
 *
 * ---
 *
 * #### API Errors
 *
 * Error responses of the API will have an `errors` key that contains
 * a list of error objects each carrying mandatory `code` and `message` keys.
 *
 * Courtesy of the GraphQL executor, you will also get information about the
 * location of the offending query/mutation in your request (`locations`),
 * as well as the `path` of the error.
 *
 * ##### Example
 *
 *     errors: [
 *       %{
 *         code: 1014,
 *         locations: [%{column: 0, line: 2}],
 *         message: "slot range unavailable",
 *         path: ["createReservation"]
 *       }
 *     ]
 *
 * ##### Error Index
 *
 * | Code | Description |
 * | ---- | ----------- |
 * | 1000 | something went wrong |
 * | 1001 | validation failed |
 * | 1002 | corporation visits not enabled |
 * | 1002 | space capacity reached |
 * | 1003 | account not confirmed |
 * | 1004 | account has been blocked |
 * | 1005 | account is missing profile |
 * | 1006 | account is missing payment method |
 * | 1007 | account has unconfirmed payment method |
 * | 1008 | the requested payment type is not available |
 * | 1009 | space not found |
 * | 1010 | account not found |
 * | 1011 | reservable not found |
 * | 1012 | account has open visit |
 * | 1013 | account already marked for deletion |
 * | 1014 | corporation reservations not enabled |
 * | 1014 | slot range unavailable |
 * | 1015 | reservation is too short |
 * | 1016 | the given token is invalid or unknown |
 * | 1017 | account already confirmed |
 * | 1018 | the unconfirmed email is already taken |
 * | 1019 | reservation not found |
 * | 1020 | reservation already cancelled |
 * | 1021 | reservation has already started |
 * | 1022 | terms document not found |
 * | 1023 | terms document is not the current |
 * | 1024 | need to accept current terms of service first |
 * | 1025 | slot range invalid |
 * | 1026 | account has reservations that have not been invoiced yet or that have not taken place yet |
 * | 1027 | terms document already accepted |
 * | 1028 | payment method verification failed |
 * | 1029 | account has open transaction |
 * | 1030 | reservable type not found |
 * | 1031 | account marked for deletion |
 * | 1032 | incorrect email or password |
 * | 1033 | account is missing dima customer ID |
 * | 1034 | dima customer not found |
 * | 2000 | this operation requires a client token |
 * | 2001 | this operation requires a customer token |
 * | 3000 | visit not found |
 * | 3001 | visit already checked in |
 * | 4000 | invalid voucher code |
 * | 4001 | voucher code already redeemed |
 * | 4010 | flexible time package not found |
 * | 5000 | forbidden for corporation account |
 * | 6000 | bahn card invalid |
 * | 6001 | bahn card expired |
 * | 6002 | bahn card already taken |
 * | 6100 | discount campaign not found |
 * | 8000 | permanent discount not found |
 *
 *
 * ##### 1001: Validation Failed
 *
 * The generic validation failed error additionally carries information about
 * the submitted field that triggered the validation failure (`arg` key in the
 * response) and the specific validation message (`validation`).
 *
 * The client will receive one 1001 error per failed validation.
 */
export type RootQueryTypeTimetableArgs = {
  date: Scalars['Date']['input'];
  id: Scalars['UUIDV4']['input'];
};

/** Sign in token. */
export type SignInToken = {
  /** A sign in token. */
  token?: Maybe<Scalars['String']['output']>;
};

/** This is used by fire and forget operations that do not have a response. */
export type SimpleResponse = {
  /** A status field with the response status, so something in the response can be queried for. */
  status?: Maybe<Scalars['String']['output']>;
};

/** A space that can be entered. */
export type Space = {
  /** Other images of this space. */
  additionalImages?: Maybe<Array<Maybe<Image>>>;
  /** The address of the space. */
  address?: Maybe<Scalars['String']['output']>;
  /** A list of timeframes/events when the space is closed outside of normal operating hours. */
  closingEvents?: Maybe<Array<Maybe<ClosingEvent>>>;
  /** The main image for this space. */
  headerImage?: Maybe<Image>;
  /** The ID of the space. */
  id?: Maybe<Scalars['UUIDV4']['output']>;
  /** The latitude geo position. */
  lat?: Maybe<Scalars['Float']['output']>;
  /** Image showing how to access the location */
  locationAccessImage?: Maybe<Image>;
  /** The longitude geo position. */
  long?: Maybe<Scalars['Float']['output']>;
  /** The amount of days reservations can be booked into the future. */
  maxFutureReservationDays?: Maybe<Scalars['Int']['output']>;
  /** Arbitrary metadata that can be configured in the administrative backend. */
  metadata?: Maybe<Scalars['JSON']['output']>;
  /** The price in minutes for a minute desk in this space. */
  minuteDeskPriceInCents?: Maybe<Scalars['Int']['output']>;
  /** How many minutes make up a timeslot. */
  minutesPerSlot?: Maybe<Scalars['Int']['output']>;
  /** The name of the space. */
  name?: Maybe<Scalars['String']['output']>;
  /** The opening hours of this space. */
  openingHours?: Maybe<Array<Maybe<OpeningHour>>>;
  /** Preparation time that is subtracted from a visit before billing. */
  prepTimeInMinutes?: Maybe<Scalars['Int']['output']>;
  /** The provider for this space. */
  provider?: Maybe<Provider>;
  /** The available reservables of this space. */
  reservables?: Maybe<Array<Maybe<Reservable>>>;
  /** The URL for the space rules document. */
  spaceRulesUrl?: Maybe<Scalars['String']['output']>;
  /** Support email */
  supportEmail?: Maybe<Scalars['String']['output']>;
  /** Support hotline */
  supportHotline?: Maybe<Scalars['String']['output']>;
  /** The timezone of the space. */
  timezone?: Maybe<Scalars['String']['output']>;
  /** The VAT for the base price. */
  vatPercent?: Maybe<Scalars['Int']['output']>;
};


/** A space that can be entered. */
export type SpaceClosingEventsArgs = {
  maxStartsAt?: InputMaybe<Scalars['UTCDateTime']['input']>;
};


/** A space that can be entered. */
export type SpaceVatPercentArgs = {
  date?: InputMaybe<Scalars['Date']['input']>;
};

/**
 * A terms of service document. Every time a new version of the terms is released it will
 * be available as a new terms of service document.
 */
export type TermsDocument = {
  /** The URL that leads to the cancellation policy. */
  cancellationUrl?: Maybe<Scalars['String']['output']>;
  /** If this is true, the terms document if the currently active one. */
  current?: Maybe<Scalars['Boolean']['output']>;
  /** The URL that leads to the data privacy policy. */
  dataPrivacyUrl?: Maybe<Scalars['String']['output']>;
  /** The ID of the terms document. */
  id?: Maybe<Scalars['UUIDV4']['output']>;
  /** The reference name of the terms document. */
  name?: Maybe<Scalars['String']['output']>;
  /** The release timestamp for this terms document. */
  releasedAt?: Maybe<Scalars['UTCDateTime']['output']>;
  /** The URL that leads to the the terms and conditions. */
  termsUrl?: Maybe<Scalars['String']['output']>;
  /** The terms document version. */
  version?: Maybe<Scalars['Int']['output']>;
  /** The URL that leads to the rights to withdrawal. */
  withdrawalUrl?: Maybe<Scalars['String']['output']>;
};


/**
 * A terms of service document. Every time a new version of the terms is released it will
 * be available as a new terms of service document.
 */
export type TermsDocumentCancellationUrlArgs = {
  locale?: InputMaybe<Scalars['String']['input']>;
};


/**
 * A terms of service document. Every time a new version of the terms is released it will
 * be available as a new terms of service document.
 */
export type TermsDocumentDataPrivacyUrlArgs = {
  locale?: InputMaybe<Scalars['String']['input']>;
};


/**
 * A terms of service document. Every time a new version of the terms is released it will
 * be available as a new terms of service document.
 */
export type TermsDocumentTermsUrlArgs = {
  locale?: InputMaybe<Scalars['String']['input']>;
};


/**
 * A terms of service document. Every time a new version of the terms is released it will
 * be available as a new terms of service document.
 */
export type TermsDocumentWithdrawalUrlArgs = {
  locale?: InputMaybe<Scalars['String']['input']>;
};

/**
 * A fixed minute time interval indicating a time frame in a day.
 *
 * ## Model
 *
 * * Slots define a time frame in a single day, they never span multiple days, except for
 *   the last slot of the day, which ends the next day at 0:00:00.
 * * The amount of numbers in a minute may vary per space. It may be retrieved by querying
 *   field `minutes_per_slot` on the `Space` type.
 * * It is usually 30 minutes.
 * * Slots in a day are indexed from zero, so they usually are in the range [0,47].
 * * On days with daylight saving changes, the range may be [0,45] or [0,49].
 * * Slots have sharp starting and end times, for instance slot 0 starts at `0:00:00` and
 *   ends ad `0:30:00`.
 *
 * ## Conversion of slots to datetimes & local times
 *
 * * Slots can be converted to a datetime when combined with a date and a IANA timezone.
 * * For convenience, the `TimeSlot` type exposes `starts_at` and `ends_at` fields which
 *   make these UTC timestamps available.
 * * The UTC datetimes may freely be converted to local timezones for display.
 *
 * ## Slot enumeration / Conversion of local date & time to slots
 *
 * The best way to convert from a local time to a slot index is to enumerate the slots in
 * the given day.
 *
 * * Start with the local beginning of the day. That is, use the date and 0:00:00 to construct
 *   a local datetime, then convert it to UTC.
 * * Repeatedly add the number of minutes in a slot to your timestamp, each time incrementing
 *   the current slot index.
 * * Convert each timestamp back to your local timezone.
 * * Stop when the local timestamp passes into the next day.
 */
export type Timeslot = {
  /**
   * @deprecated This field will assume value '00:00:00' when the corresponding slot index is 47.
   *
   * While this is technically correct, it is confusing to the user and dangerous to base any
   * calculations on. Hence, any TimeWithoutTimezone fields will be removed in a future version of
   * the API. Please use the corresponding UTCDateTime fields.
   *
   */
  endTime?: Maybe<Scalars['TimeWithoutTimezone']['output']>;
  /** The timestamp when the slot ends. */
  endsAt?: Maybe<Scalars['UTCDateTime']['output']>;
  /** The current availability of the slot. */
  reservation?: Maybe<TimeslotReservationType>;
  /** The slot index. */
  slot?: Maybe<Scalars['Int']['output']>;
  /**
   * @deprecated This field will assume value '00:00:00' when the corresponding slot index is 47.
   *
   * While this is technically correct, it is confusing to the user and dangerous to base any
   * calculations on. Hence, any TimeWithoutTimezone fields will be removed in a future version of
   * the API. Please use the corresponding UTCDateTime fields.
   *
   */
  startTime?: Maybe<Scalars['TimeWithoutTimezone']['output']>;
  /** The timestamp when the slot starts. */
  startsAt?: Maybe<Scalars['UTCDateTime']['output']>;
};

/** A timeslot range defining a time period, including start and from */
export type TimeslotRange = {
  /** The first slot in the range. */
  from?: Maybe<Timeslot>;
  /** The last slot in the range. */
  to?: Maybe<Timeslot>;
};

/** A time slot range argument. See `Timeslot` type for more information */
export type TimeslotRangeInput = {
  /** The first slot in the range. */
  fromSlot: Scalars['Int']['input'];
  /** The last slot in the range. */
  toSlot: Scalars['Int']['input'];
};

/** Indicates availability of a reservable/timeslot pair */
export enum TimeslotReservationType {
  Busy = 'busy',
  Free = 'free',
  Own = 'own'
}

/** An active visit. */
export type Visit = {
  /** The time when this visit will expire if not checked in. */
  checkInExpiresAt?: Maybe<Scalars['UTCDateTime']['output']>;
  /** If checked in already, the check in timestamp. */
  checkedInAt?: Maybe<Scalars['UTCDateTime']['output']>;
  /** The code for the QR code for check-in and check-out. */
  qrCode?: Maybe<Scalars['String']['output']>;
  /** The space for the visit. */
  space?: Maybe<Space>;
};

/** A voucher code that can be entered in order to obtain a voucher. */
export type VoucherCode = {
  /** The ID of the voucher code. */
  id?: Maybe<Scalars['UUIDV4']['output']>;
};

export type CancelReservationMutationVariables = Exact<{
  reservationId: Scalars['UUIDV4']['input'];
}>;


export type CancelReservationMutation = { cancelReservation?: { state?: ReservationState | null } | null };

export type CreateAccountMutationVariables = Exact<{
  email: Scalars['String']['input'];
  password: Scalars['String']['input'];
  profile?: InputMaybe<ProfileInput>;
}>;


export type CreateAccountMutation = { createAccount?: { id?: string | null } | null };

export type CreateReservationMutationVariables = Exact<{
  reservableId: Scalars['UUIDV4']['input'];
  fromSlot: Scalars['TimeslotIndex']['input'];
  toSlot: Scalars['TimeslotIndex']['input'];
  date: Scalars['Date']['input'];
  title: Scalars['String']['input'];
}>;


export type CreateReservationMutation = { createReservation?: { id?: string | null } | null };

export type ResendConfirmationEmailMutationVariables = Exact<{ [key: string]: never; }>;


export type ResendConfirmationEmailMutation = { resendConfirmationEmail?: { status?: string | null } | null };

export type ResetPasswordMutationVariables = Exact<{
  email: Scalars['String']['input'];
}>;


export type ResetPasswordMutation = { resetPassword?: { status?: string | null } | null };

export type SignInMutationVariables = Exact<{
  email: Scalars['String']['input'];
  password: Scalars['String']['input'];
  deviceName: Scalars['String']['input'];
  deviceIdentifier: Scalars['String']['input'];
  redirectPath: Scalars['String']['input'];
}>;


export type SignInMutation = { signIn?: { token?: string | null } | null };

export type UpdateEmailMutationVariables = Exact<{
  unconfirmedEmail: Scalars['String']['input'];
}>;


export type UpdateEmailMutation = { updateEmail?: { id?: string | null } | null };

export type UpdateProfileMutationVariables = Exact<{
  address: Scalars['String']['input'];
  city: Scalars['String']['input'];
  company?: InputMaybe<Scalars['String']['input']>;
  country: Scalars['String']['input'];
  firstName: Scalars['String']['input'];
  lastName: Scalars['String']['input'];
  optionalInformation?: InputMaybe<Scalars['String']['input']>;
  zip: Scalars['String']['input'];
}>;


export type UpdateProfileMutation = { updateProfile?: { id?: string | null } | null };

export type EnvironmentQueryVariables = Exact<{ [key: string]: never; }>;


export type EnvironmentQuery = { environment?: { creditorId?: string | null } | null };

export type GetMyReservationsQueryVariables = Exact<{ [key: string]: never; }>;


export type GetMyReservationsQuery = { myReservations?: Array<{ id?: string | null, startsAt?: string | null, endsAt?: string | null, date?: string | null, guestInvitationUrl?: string | null, state?: ReservationState | null, totalCents?: number | null, vatPercent?: number | null, guests?: Array<{ name?: string | null } | null> | null, reservable?: { name?: string | null, reservableCategory?: { capacity?: number | null, name?: string | null, type?: { name?: string | null } | null } | null, space?: { name?: string | null } | null } | null } | null> | null };

export type GetReservableCategoriesQueryVariables = Exact<{
  spaceId: Scalars['UUIDV4']['input'];
}>;


export type GetReservableCategoriesQuery = { reservableCategories?: Array<{ id?: string | null, name?: string | null, capacity?: number | null, pricePerSlotInCents?: number | null, priceCapInCents?: number | null, minimumDurationSlots?: number | null, type?: { id?: string | null, name?: string | null, position?: number | null, iconUrl?: string | null } | null } | null> | null };

export type GetReservationSuggestionsQueryVariables = Exact<{
  spaceId: Scalars['UUIDV4']['input'];
  date?: InputMaybe<Scalars['Date']['input']>;
  slotRange?: InputMaybe<TimeslotRangeInput>;
  minCapacity?: InputMaybe<Scalars['Int']['input']>;
  reservableTypeId?: InputMaybe<Scalars['UUIDV4']['input']>;
}>;


export type GetReservationSuggestionsQuery = { reservationSuggestions?: { matches?: Array<{ totalCents?: number | null, otherRangesAvailable?: boolean | null, reservable?: { id?: string | null, metadata?: any | null, name?: string | null, pricePerSlotInCents?: number | null, vatPercent?: number | null, reservableCategory?: { id?: string | null, name?: string | null, capacity?: number | null, pricePerSlotInCents?: number | null, priceCapInCents?: number | null, minimumDurationSlots?: number | null, type?: { id?: string | null, name?: string | null, position?: number | null, iconUrl?: string | null } | null } | null, additionalImages?: Array<{ id?: string | null, type?: ImageScope | null, url?: string | null } | null> | null, headerImage?: { id?: string | null, type?: ImageScope | null, url?: string | null } | null } | null, slotRange?: { from?: { slot?: number | null, startsAt?: string | null } | null, to?: { slot?: number | null, endsAt?: string | null } | null } | null } | null> | null } | null };

export type GetSpacesQueryVariables = Exact<{ [key: string]: never; }>;


export type GetSpacesQuery = { spaces?: Array<{ id?: string | null, name?: string | null, minutesPerSlot?: number | null, vatPercent?: number | null, maxFutureReservationDays?: number | null, spaceRulesUrl?: string | null, reservables?: Array<{ vatPercent?: number | null } | null> | null, openingHours?: Array<{ dayOfWeek?: string | null, opensAt?: string | null, closesAt?: string | null } | null> | null } | null> | null };

export type MeQueryVariables = Exact<{ [key: string]: never; }>;


export type MeQuery = { me?: { id?: string | null, email?: string | null, unconfirmedEmail?: string | null, emailConfirmedAt?: string | null, paymentMethod?: { failing?: boolean | null, confirmedAt?: string | null } | null, profile?: { address?: string | null, city?: string | null, company?: string | null, country?: string | null, firstName?: string | null, lastName?: string | null, optionalInformation?: string | null, zip?: string | null } | null } | null };


export const CancelReservationDocument = gql`
    mutation cancelReservation($reservationId: UUIDV4!) {
  cancelReservation(reservationId: $reservationId) {
    state
  }
}
    `;
export type CancelReservationMutationFn = Apollo.MutationFunction<CancelReservationMutation, CancelReservationMutationVariables>;

/**
 * __useCancelReservationMutation__
 *
 * To run a mutation, you first call `useCancelReservationMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCancelReservationMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [cancelReservationMutation, { data, loading, error }] = useCancelReservationMutation({
 *   variables: {
 *      reservationId: // value for 'reservationId'
 *   },
 * });
 */
export function useCancelReservationMutation(baseOptions?: Apollo.MutationHookOptions<CancelReservationMutation, CancelReservationMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CancelReservationMutation, CancelReservationMutationVariables>(CancelReservationDocument, options);
      }
export type CancelReservationMutationHookResult = ReturnType<typeof useCancelReservationMutation>;
export type CancelReservationMutationResult = Apollo.MutationResult<CancelReservationMutation>;
export type CancelReservationMutationOptions = Apollo.BaseMutationOptions<CancelReservationMutation, CancelReservationMutationVariables>;
export const CreateAccountDocument = gql`
    mutation createAccount($email: String!, $password: String!, $profile: ProfileInput) {
  createAccount(email: $email, password: $password, profile: $profile) {
    id
  }
}
    `;
export type CreateAccountMutationFn = Apollo.MutationFunction<CreateAccountMutation, CreateAccountMutationVariables>;

/**
 * __useCreateAccountMutation__
 *
 * To run a mutation, you first call `useCreateAccountMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateAccountMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createAccountMutation, { data, loading, error }] = useCreateAccountMutation({
 *   variables: {
 *      email: // value for 'email'
 *      password: // value for 'password'
 *      profile: // value for 'profile'
 *   },
 * });
 */
export function useCreateAccountMutation(baseOptions?: Apollo.MutationHookOptions<CreateAccountMutation, CreateAccountMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateAccountMutation, CreateAccountMutationVariables>(CreateAccountDocument, options);
      }
export type CreateAccountMutationHookResult = ReturnType<typeof useCreateAccountMutation>;
export type CreateAccountMutationResult = Apollo.MutationResult<CreateAccountMutation>;
export type CreateAccountMutationOptions = Apollo.BaseMutationOptions<CreateAccountMutation, CreateAccountMutationVariables>;
export const CreateReservationDocument = gql`
    mutation createReservation($reservableId: UUIDV4!, $fromSlot: TimeslotIndex!, $toSlot: TimeslotIndex!, $date: Date!, $title: String!) {
  createReservation(
    reservableId: $reservableId
    date: $date
    fromSlot: $fromSlot
    toSlot: $toSlot
    title: $title
  ) {
    id
  }
}
    `;
export type CreateReservationMutationFn = Apollo.MutationFunction<CreateReservationMutation, CreateReservationMutationVariables>;

/**
 * __useCreateReservationMutation__
 *
 * To run a mutation, you first call `useCreateReservationMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateReservationMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createReservationMutation, { data, loading, error }] = useCreateReservationMutation({
 *   variables: {
 *      reservableId: // value for 'reservableId'
 *      fromSlot: // value for 'fromSlot'
 *      toSlot: // value for 'toSlot'
 *      date: // value for 'date'
 *      title: // value for 'title'
 *   },
 * });
 */
export function useCreateReservationMutation(baseOptions?: Apollo.MutationHookOptions<CreateReservationMutation, CreateReservationMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateReservationMutation, CreateReservationMutationVariables>(CreateReservationDocument, options);
      }
export type CreateReservationMutationHookResult = ReturnType<typeof useCreateReservationMutation>;
export type CreateReservationMutationResult = Apollo.MutationResult<CreateReservationMutation>;
export type CreateReservationMutationOptions = Apollo.BaseMutationOptions<CreateReservationMutation, CreateReservationMutationVariables>;
export const ResendConfirmationEmailDocument = gql`
    mutation resendConfirmationEmail {
  resendConfirmationEmail {
    status
  }
}
    `;
export type ResendConfirmationEmailMutationFn = Apollo.MutationFunction<ResendConfirmationEmailMutation, ResendConfirmationEmailMutationVariables>;

/**
 * __useResendConfirmationEmailMutation__
 *
 * To run a mutation, you first call `useResendConfirmationEmailMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useResendConfirmationEmailMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [resendConfirmationEmailMutation, { data, loading, error }] = useResendConfirmationEmailMutation({
 *   variables: {
 *   },
 * });
 */
export function useResendConfirmationEmailMutation(baseOptions?: Apollo.MutationHookOptions<ResendConfirmationEmailMutation, ResendConfirmationEmailMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<ResendConfirmationEmailMutation, ResendConfirmationEmailMutationVariables>(ResendConfirmationEmailDocument, options);
      }
export type ResendConfirmationEmailMutationHookResult = ReturnType<typeof useResendConfirmationEmailMutation>;
export type ResendConfirmationEmailMutationResult = Apollo.MutationResult<ResendConfirmationEmailMutation>;
export type ResendConfirmationEmailMutationOptions = Apollo.BaseMutationOptions<ResendConfirmationEmailMutation, ResendConfirmationEmailMutationVariables>;
export const ResetPasswordDocument = gql`
    mutation resetPassword($email: String!) {
  resetPassword(email: $email) {
    status
  }
}
    `;
export type ResetPasswordMutationFn = Apollo.MutationFunction<ResetPasswordMutation, ResetPasswordMutationVariables>;

/**
 * __useResetPasswordMutation__
 *
 * To run a mutation, you first call `useResetPasswordMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useResetPasswordMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [resetPasswordMutation, { data, loading, error }] = useResetPasswordMutation({
 *   variables: {
 *      email: // value for 'email'
 *   },
 * });
 */
export function useResetPasswordMutation(baseOptions?: Apollo.MutationHookOptions<ResetPasswordMutation, ResetPasswordMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<ResetPasswordMutation, ResetPasswordMutationVariables>(ResetPasswordDocument, options);
      }
export type ResetPasswordMutationHookResult = ReturnType<typeof useResetPasswordMutation>;
export type ResetPasswordMutationResult = Apollo.MutationResult<ResetPasswordMutation>;
export type ResetPasswordMutationOptions = Apollo.BaseMutationOptions<ResetPasswordMutation, ResetPasswordMutationVariables>;
export const SignInDocument = gql`
    mutation signIn($email: String!, $password: String!, $deviceName: String!, $deviceIdentifier: String!, $redirectPath: String!) {
  signIn(
    email: $email
    password: $password
    deviceName: $deviceName
    deviceIdentifier: $deviceIdentifier
    redirectPath: $redirectPath
  ) {
    token
  }
}
    `;
export type SignInMutationFn = Apollo.MutationFunction<SignInMutation, SignInMutationVariables>;

/**
 * __useSignInMutation__
 *
 * To run a mutation, you first call `useSignInMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSignInMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [signInMutation, { data, loading, error }] = useSignInMutation({
 *   variables: {
 *      email: // value for 'email'
 *      password: // value for 'password'
 *      deviceName: // value for 'deviceName'
 *      deviceIdentifier: // value for 'deviceIdentifier'
 *      redirectPath: // value for 'redirectPath'
 *   },
 * });
 */
export function useSignInMutation(baseOptions?: Apollo.MutationHookOptions<SignInMutation, SignInMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<SignInMutation, SignInMutationVariables>(SignInDocument, options);
      }
export type SignInMutationHookResult = ReturnType<typeof useSignInMutation>;
export type SignInMutationResult = Apollo.MutationResult<SignInMutation>;
export type SignInMutationOptions = Apollo.BaseMutationOptions<SignInMutation, SignInMutationVariables>;
export const UpdateEmailDocument = gql`
    mutation updateEmail($unconfirmedEmail: String!) {
  updateEmail(unconfirmedEmail: $unconfirmedEmail) {
    id
  }
}
    `;
export type UpdateEmailMutationFn = Apollo.MutationFunction<UpdateEmailMutation, UpdateEmailMutationVariables>;

/**
 * __useUpdateEmailMutation__
 *
 * To run a mutation, you first call `useUpdateEmailMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateEmailMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateEmailMutation, { data, loading, error }] = useUpdateEmailMutation({
 *   variables: {
 *      unconfirmedEmail: // value for 'unconfirmedEmail'
 *   },
 * });
 */
export function useUpdateEmailMutation(baseOptions?: Apollo.MutationHookOptions<UpdateEmailMutation, UpdateEmailMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateEmailMutation, UpdateEmailMutationVariables>(UpdateEmailDocument, options);
      }
export type UpdateEmailMutationHookResult = ReturnType<typeof useUpdateEmailMutation>;
export type UpdateEmailMutationResult = Apollo.MutationResult<UpdateEmailMutation>;
export type UpdateEmailMutationOptions = Apollo.BaseMutationOptions<UpdateEmailMutation, UpdateEmailMutationVariables>;
export const UpdateProfileDocument = gql`
    mutation updateProfile($address: String!, $city: String!, $company: String, $country: String!, $firstName: String!, $lastName: String!, $optionalInformation: String, $zip: String!) {
  updateProfile(
    address: $address
    city: $city
    company: $company
    country: $country
    firstName: $firstName
    lastName: $lastName
    optionalInformation: $optionalInformation
    zip: $zip
  ) {
    id
  }
}
    `;
export type UpdateProfileMutationFn = Apollo.MutationFunction<UpdateProfileMutation, UpdateProfileMutationVariables>;

/**
 * __useUpdateProfileMutation__
 *
 * To run a mutation, you first call `useUpdateProfileMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateProfileMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateProfileMutation, { data, loading, error }] = useUpdateProfileMutation({
 *   variables: {
 *      address: // value for 'address'
 *      city: // value for 'city'
 *      company: // value for 'company'
 *      country: // value for 'country'
 *      firstName: // value for 'firstName'
 *      lastName: // value for 'lastName'
 *      optionalInformation: // value for 'optionalInformation'
 *      zip: // value for 'zip'
 *   },
 * });
 */
export function useUpdateProfileMutation(baseOptions?: Apollo.MutationHookOptions<UpdateProfileMutation, UpdateProfileMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateProfileMutation, UpdateProfileMutationVariables>(UpdateProfileDocument, options);
      }
export type UpdateProfileMutationHookResult = ReturnType<typeof useUpdateProfileMutation>;
export type UpdateProfileMutationResult = Apollo.MutationResult<UpdateProfileMutation>;
export type UpdateProfileMutationOptions = Apollo.BaseMutationOptions<UpdateProfileMutation, UpdateProfileMutationVariables>;
export const EnvironmentDocument = gql`
    query environment {
  environment {
    creditorId
  }
}
    `;

/**
 * __useEnvironmentQuery__
 *
 * To run a query within a React component, call `useEnvironmentQuery` and pass it any options that fit your needs.
 * When your component renders, `useEnvironmentQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useEnvironmentQuery({
 *   variables: {
 *   },
 * });
 */
export function useEnvironmentQuery(baseOptions?: Apollo.QueryHookOptions<EnvironmentQuery, EnvironmentQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<EnvironmentQuery, EnvironmentQueryVariables>(EnvironmentDocument, options);
      }
export function useEnvironmentLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<EnvironmentQuery, EnvironmentQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<EnvironmentQuery, EnvironmentQueryVariables>(EnvironmentDocument, options);
        }
export function useEnvironmentSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<EnvironmentQuery, EnvironmentQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<EnvironmentQuery, EnvironmentQueryVariables>(EnvironmentDocument, options);
        }
export type EnvironmentQueryHookResult = ReturnType<typeof useEnvironmentQuery>;
export type EnvironmentLazyQueryHookResult = ReturnType<typeof useEnvironmentLazyQuery>;
export type EnvironmentSuspenseQueryHookResult = ReturnType<typeof useEnvironmentSuspenseQuery>;
export type EnvironmentQueryResult = Apollo.QueryResult<EnvironmentQuery, EnvironmentQueryVariables>;
export const GetMyReservationsDocument = gql`
    query getMyReservations {
  myReservations(onlyActive: false) {
    id
    startsAt
    endsAt
    date
    guests {
      name
    }
    guestInvitationUrl
    reservable {
      name
      reservableCategory {
        capacity
        name
        type {
          name
        }
      }
      space {
        name
      }
    }
    state
    totalCents
    vatPercent
  }
}
    `;

/**
 * __useGetMyReservationsQuery__
 *
 * To run a query within a React component, call `useGetMyReservationsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetMyReservationsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetMyReservationsQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetMyReservationsQuery(baseOptions?: Apollo.QueryHookOptions<GetMyReservationsQuery, GetMyReservationsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetMyReservationsQuery, GetMyReservationsQueryVariables>(GetMyReservationsDocument, options);
      }
export function useGetMyReservationsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetMyReservationsQuery, GetMyReservationsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetMyReservationsQuery, GetMyReservationsQueryVariables>(GetMyReservationsDocument, options);
        }
export function useGetMyReservationsSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<GetMyReservationsQuery, GetMyReservationsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<GetMyReservationsQuery, GetMyReservationsQueryVariables>(GetMyReservationsDocument, options);
        }
export type GetMyReservationsQueryHookResult = ReturnType<typeof useGetMyReservationsQuery>;
export type GetMyReservationsLazyQueryHookResult = ReturnType<typeof useGetMyReservationsLazyQuery>;
export type GetMyReservationsSuspenseQueryHookResult = ReturnType<typeof useGetMyReservationsSuspenseQuery>;
export type GetMyReservationsQueryResult = Apollo.QueryResult<GetMyReservationsQuery, GetMyReservationsQueryVariables>;
export const GetReservableCategoriesDocument = gql`
    query getReservableCategories($spaceId: UUIDV4!) {
  reservableCategories(spaceId: $spaceId) {
    id
    name
    capacity
    pricePerSlotInCents
    priceCapInCents
    minimumDurationSlots
    type {
      id
      name
      position
      iconUrl
    }
  }
}
    `;

/**
 * __useGetReservableCategoriesQuery__
 *
 * To run a query within a React component, call `useGetReservableCategoriesQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetReservableCategoriesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetReservableCategoriesQuery({
 *   variables: {
 *      spaceId: // value for 'spaceId'
 *   },
 * });
 */
export function useGetReservableCategoriesQuery(baseOptions: Apollo.QueryHookOptions<GetReservableCategoriesQuery, GetReservableCategoriesQueryVariables> & ({ variables: GetReservableCategoriesQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetReservableCategoriesQuery, GetReservableCategoriesQueryVariables>(GetReservableCategoriesDocument, options);
      }
export function useGetReservableCategoriesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetReservableCategoriesQuery, GetReservableCategoriesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetReservableCategoriesQuery, GetReservableCategoriesQueryVariables>(GetReservableCategoriesDocument, options);
        }
export function useGetReservableCategoriesSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<GetReservableCategoriesQuery, GetReservableCategoriesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<GetReservableCategoriesQuery, GetReservableCategoriesQueryVariables>(GetReservableCategoriesDocument, options);
        }
export type GetReservableCategoriesQueryHookResult = ReturnType<typeof useGetReservableCategoriesQuery>;
export type GetReservableCategoriesLazyQueryHookResult = ReturnType<typeof useGetReservableCategoriesLazyQuery>;
export type GetReservableCategoriesSuspenseQueryHookResult = ReturnType<typeof useGetReservableCategoriesSuspenseQuery>;
export type GetReservableCategoriesQueryResult = Apollo.QueryResult<GetReservableCategoriesQuery, GetReservableCategoriesQueryVariables>;
export const GetReservationSuggestionsDocument = gql`
    query getReservationSuggestions($spaceId: UUIDV4!, $date: Date, $slotRange: TimeslotRangeInput, $minCapacity: Int, $reservableTypeId: UUIDV4) {
  reservationSuggestions(
    spaceId: $spaceId
    date: $date
    slotRange: $slotRange
    minCapacity: $minCapacity
    reservableTypeId: $reservableTypeId
  ) {
    matches {
      reservable {
        reservableCategory {
          id
          name
          capacity
          pricePerSlotInCents
          priceCapInCents
          minimumDurationSlots
          type {
            id
            name
            position
            iconUrl
          }
        }
        additionalImages {
          id
          type
          url
        }
        headerImage {
          id
          type
          url
        }
        id
        metadata
        name
        pricePerSlotInCents
        vatPercent
      }
      slotRange {
        from {
          slot
          startsAt
        }
        to {
          slot
          endsAt
        }
      }
      totalCents
      otherRangesAvailable
    }
  }
}
    `;

/**
 * __useGetReservationSuggestionsQuery__
 *
 * To run a query within a React component, call `useGetReservationSuggestionsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetReservationSuggestionsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetReservationSuggestionsQuery({
 *   variables: {
 *      spaceId: // value for 'spaceId'
 *      date: // value for 'date'
 *      slotRange: // value for 'slotRange'
 *      minCapacity: // value for 'minCapacity'
 *      reservableTypeId: // value for 'reservableTypeId'
 *   },
 * });
 */
export function useGetReservationSuggestionsQuery(baseOptions: Apollo.QueryHookOptions<GetReservationSuggestionsQuery, GetReservationSuggestionsQueryVariables> & ({ variables: GetReservationSuggestionsQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetReservationSuggestionsQuery, GetReservationSuggestionsQueryVariables>(GetReservationSuggestionsDocument, options);
      }
export function useGetReservationSuggestionsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetReservationSuggestionsQuery, GetReservationSuggestionsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetReservationSuggestionsQuery, GetReservationSuggestionsQueryVariables>(GetReservationSuggestionsDocument, options);
        }
export function useGetReservationSuggestionsSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<GetReservationSuggestionsQuery, GetReservationSuggestionsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<GetReservationSuggestionsQuery, GetReservationSuggestionsQueryVariables>(GetReservationSuggestionsDocument, options);
        }
export type GetReservationSuggestionsQueryHookResult = ReturnType<typeof useGetReservationSuggestionsQuery>;
export type GetReservationSuggestionsLazyQueryHookResult = ReturnType<typeof useGetReservationSuggestionsLazyQuery>;
export type GetReservationSuggestionsSuspenseQueryHookResult = ReturnType<typeof useGetReservationSuggestionsSuspenseQuery>;
export type GetReservationSuggestionsQueryResult = Apollo.QueryResult<GetReservationSuggestionsQuery, GetReservationSuggestionsQueryVariables>;
export const GetSpacesDocument = gql`
    query getSpaces {
  spaces {
    id
    name
    minutesPerSlot
    vatPercent
    reservables {
      vatPercent
    }
    openingHours {
      dayOfWeek
      opensAt
      closesAt
    }
    maxFutureReservationDays
    spaceRulesUrl
  }
}
    `;

/**
 * __useGetSpacesQuery__
 *
 * To run a query within a React component, call `useGetSpacesQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetSpacesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetSpacesQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetSpacesQuery(baseOptions?: Apollo.QueryHookOptions<GetSpacesQuery, GetSpacesQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetSpacesQuery, GetSpacesQueryVariables>(GetSpacesDocument, options);
      }
export function useGetSpacesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetSpacesQuery, GetSpacesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetSpacesQuery, GetSpacesQueryVariables>(GetSpacesDocument, options);
        }
export function useGetSpacesSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<GetSpacesQuery, GetSpacesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<GetSpacesQuery, GetSpacesQueryVariables>(GetSpacesDocument, options);
        }
export type GetSpacesQueryHookResult = ReturnType<typeof useGetSpacesQuery>;
export type GetSpacesLazyQueryHookResult = ReturnType<typeof useGetSpacesLazyQuery>;
export type GetSpacesSuspenseQueryHookResult = ReturnType<typeof useGetSpacesSuspenseQuery>;
export type GetSpacesQueryResult = Apollo.QueryResult<GetSpacesQuery, GetSpacesQueryVariables>;
export const MeDocument = gql`
    query me {
  me {
    id
    email
    unconfirmedEmail
    emailConfirmedAt
    paymentMethod {
      failing
      confirmedAt
    }
    profile {
      address
      city
      company
      country
      firstName
      lastName
      optionalInformation
      zip
    }
  }
}
    `;

/**
 * __useMeQuery__
 *
 * To run a query within a React component, call `useMeQuery` and pass it any options that fit your needs.
 * When your component renders, `useMeQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useMeQuery({
 *   variables: {
 *   },
 * });
 */
export function useMeQuery(baseOptions?: Apollo.QueryHookOptions<MeQuery, MeQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<MeQuery, MeQueryVariables>(MeDocument, options);
      }
export function useMeLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<MeQuery, MeQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<MeQuery, MeQueryVariables>(MeDocument, options);
        }
export function useMeSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<MeQuery, MeQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<MeQuery, MeQueryVariables>(MeDocument, options);
        }
export type MeQueryHookResult = ReturnType<typeof useMeQuery>;
export type MeLazyQueryHookResult = ReturnType<typeof useMeLazyQuery>;
export type MeSuspenseQueryHookResult = ReturnType<typeof useMeSuspenseQuery>;
export type MeQueryResult = Apollo.QueryResult<MeQuery, MeQueryVariables>;