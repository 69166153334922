import { msg } from "@lingui/macro";
import { useLingui } from "@lingui/react";
import type { ReactElement } from "react";
import React, {
  createContext,
  useCallback,
  useContext,
  useEffect,
  useRef,
  useState,
} from "react";

import classNames from "../../utils/classNames";
import random from "../../utils/random";
import Button from "../Button";
import Icon from "../Icon";
import { ReactComponent as ExpandMore } from "../icons/32/navigation-expand-more.svg";
import setFocus from "./setFocus";

export const DropdownPosition = {
  right: "a-dropdown--right",
  top: "a-dropdown--top",
  left: "a-dropdown--left",
};

type DropdownContextType = {
  id: string;
  isOpen: boolean;
  position: keyof typeof DropdownPosition;
  openDropdown: () => void;
  closeDropdown: () => void;
};

const DropdownContext = createContext<DropdownContextType>({
  id: "dropdown",
  isOpen: false,
  openDropdown: () => {},
  closeDropdown: () => {},
  position: "right",
});

export type DropdownButtonProps = React.PropsWithChildren<{
  openDescription?: string;
  closeDescription?: string;
}>;

export function DropdownButton({
  children,
  openDescription,
  closeDescription,
}: DropdownButtonProps) {
  const { openDropdown, closeDropdown, id, isOpen } =
    useContext(DropdownContext);

  const buttonRef = useRef<HTMLButtonElement>(null);
  const { _ } = useLingui();

  const handleCloseClick = () => {
    closeDropdown();
    setFocus(buttonRef.current);
  };

  let onClick = isOpen ? handleCloseClick : openDropdown;
  let description = isOpen
    ? closeDescription || _(msg`Close dropdown`)
    : openDescription || _(msg`Open dropdown`);

  return (
    <Button
      color="nav"
      ref={buttonRef}
      onClick={onClick}
      aria-controls={id}
      aria-expanded={isOpen}
    >
      <div className="a-dropdown__button">
        {children}
        <div className="a-dropdown__icon">
          <Icon svg={ExpandMore} size="medium" />
          <span className="u-sr-only">{description}</span>
        </div>
      </div>
    </Button>
  );
}

export type DropdownListItemProps = React.PropsWithChildren<{}>;

export function DropdownListItem({ children }: DropdownListItemProps) {
  return <li className="a-dropdown__list-item">{children}</li>;
}

export type DropdownListProps = React.PropsWithChildren<{}>;

export function DropdownList({ children }: DropdownListProps) {
  const { isOpen, id, closeDropdown, position } = useContext(DropdownContext);
  const contentRef = useRef<HTMLUListElement>(null);
  const className = classNames([
    "a-dropdown",
    DropdownPosition[position],
    !isOpen && "u-hidden",
  ]);

  useEffect(() => {
    if (isOpen) setFocus(contentRef.current);
  }, [isOpen]);

  return (
    <ul
      id={id}
      ref={contentRef}
      hidden={!isOpen}
      className={className}
      onClick={closeDropdown}
      tabIndex={-1}
    >
      {children}
    </ul>
  );
}

export type DropdownWrapperProps = {
  position?: keyof typeof DropdownPosition;
  children: [
    ReactElement<typeof DropdownButton>,
    ReactElement<typeof DropdownList>,
  ];
};

export function DropdownWrapper({
  position = "right",
  children,
}: DropdownWrapperProps) {
  const [isOpen, setIsOpen] = useState(false);

  const openDropdown = useCallback(() => {
    setIsOpen(true);
  }, []);

  const closeDropdown = useCallback(() => {
    setIsOpen(false);
  }, []);

  const dropdownRef = useRef<HTMLDivElement>(null);

  const closeDropdownOnOutsideClick = useCallback(
    (event: any) => {
      if (
        dropdownRef.current &&
        !dropdownRef.current.contains(event.target as Node)
      )
        closeDropdown();
    },
    [dropdownRef, closeDropdown]
  );

  const closeDropdownOnEscape = useCallback(
    (event: KeyboardEvent) => {
      if (event.code === "Escape") closeDropdown();
    },
    [closeDropdown]
  );

  useEffect(() => {
    if (isOpen) {
      document.addEventListener("mousedown", closeDropdownOnOutsideClick);
      document.addEventListener("keydown", closeDropdownOnEscape);

      return () => {
        document.removeEventListener("mousedown", closeDropdownOnOutsideClick);
        document.removeEventListener("keydown", closeDropdownOnEscape);
      };
    }
  }, [isOpen, closeDropdownOnOutsideClick, closeDropdownOnEscape]);

  const classes = classNames([
    "a-dropdown__wrapper",
    position !== "right" ? "u-justify-start" : "u-justify-end",
  ]);

  return (
    <DropdownContext.Provider
      value={{
        id: random.guid(),
        isOpen,
        openDropdown,
        closeDropdown,
        position,
      }}
    >
      <div ref={dropdownRef} className={classes}>
        {children}
      </div>
    </DropdownContext.Provider>
  );
}
const Dropdown = {
  Wrapper: DropdownWrapper,
  List: DropdownList,
  Button: DropdownButton,
  ListItem: DropdownListItem,
};
export default Dropdown;
