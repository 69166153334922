import { msg } from "@lingui/macro";
import { useLingui } from "@lingui/react";
import React from "react";

import classNames from "../utils/classNames";

export type Props = {
  size?: keyof typeof SIZES;
  message?: string;
};

const SIZES = {
  s: "loading-indicator-size-s",
  m: "loading-indicator-size-m",
  l: "loading-indicator-size-l",
  xl: "loading-indicator-size-xl",
};

/**
 * Renders a spinner animation to indicate an ongoing loading process to the
 * user.
 */
function LoadingIndicator({ size = "xl", message }: Props): React.ReactElement {
  const { _ } = useLingui();

  message = message || _(msg`Loading…`);
  const className = classNames(["loading-indicator", SIZES[size]]);
  return (
    <>
      <svg
        aria-hidden="true"
        focusable="false"
        className={className}
        viewBox="0 0 44 44"
        width={32}
        height={32}
      >
        <circle
          className="loading-indicator-circle"
          cx="22"
          cy="22"
          r="20"
          fill="none"
          strokeMiterlimit="10"
        ></circle>
      </svg>
      <span className="u-sr-only">{message}</span>
    </>
  );
}

export default LoadingIndicator;
