import { i18n } from "@lingui/core";
import { detect, fromStorage } from "@lingui/detect-locale";
import { registerLocale as loadCountryData } from "i18n-iso-countries";
import deCountries from "i18n-iso-countries/langs/de.json";
import enCountries from "i18n-iso-countries/langs/en.json";

import { messages as deMessages } from "../locales/de/messages";
import { messages as enMessages } from "../locales/en/messages";
import defaultLocale from "./default-locale";

loadCountryData(enCountries);
loadCountryData(deCountries);

const locales = {
  en: "English",
  de: "German",
};

const LOCAL_STORAGE_KEY = "lang";
const isLocaleValid = (locale: string | null) => `${locale}` in locales;

export function getLocale() {
  const detectedLocale = detect(
    fromStorage(LOCAL_STORAGE_KEY),
    () => defaultLocale
  );

  return isLocaleValid(detectedLocale)
    ? (detectedLocale as string)
    : defaultLocale;
}
const localeFromStorage = getLocale();

[
  { locale: "de", messages: deMessages },
  { locale: "en", messages: enMessages },
].forEach(({ locale, messages }) => {
  i18n.load(locale, messages);
});

i18n.activate(localeFromStorage);
