import { msg } from "@lingui/macro";
import { useLingui } from "@lingui/react";
import type { ReactElement } from "react";

import { useGetSpacesQuery } from "../../../graphql/__generated__/globalTypes";
import ErrorMessage from "../../../shared/ErrorMessage";
import { ReactComponent as Location } from "../../../shared/icons/48/location.svg";
import { LinkList, LinkListItem } from "../../../shared/LinkList";
import LoadingIndicator from "../../../shared/LoadingIndicator";
import PageLayout from "../../../shared/PageLayout";
import ProgressStepper from "../../../shared/ProgressStepper";

export default function SpaceSelectionPage(): ReactElement {
  const { _ } = useLingui();
  const { data, error, loading } = useGetSpacesQuery();

  return (
    <PageLayout title={_(msg`Where would you like to work?`)}>
      <ProgressStepper step={1} of={6} />
      {loading && (
        <span className="loading-indicator-wrapper">
          <LoadingIndicator />
        </span>
      )}
      {error && <ErrorMessage error={error} />}
      {!loading && !error && data?.spaces && (
        <LinkList>
          {data?.spaces?.map((space) => (
            <LinkListItem
              key={space?.id}
              icon={Location}
              title={space?.name!}
              to={`/reservations/new/category/?spaceId=${space?.id}`}
            />
          ))}
        </LinkList>
      )}
    </PageLayout>
  );
}
