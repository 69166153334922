import { isApolloError as checkIsApolloError } from "@apollo/client";
import { msg, Trans } from "@lingui/macro";
import { useLingui } from "@lingui/react";
import get from "lodash/get";
import React from "react";

import type { TitleTag } from "../utils/propTypes";

export const retryStatusCodes: number[] = [
  408, // Request Timeout
  429, // Too Many Requests
  502, // Bad Gateway
  503, // Service Unavailable
  504, // Gateway Timeout
  507, // Insufficient Storage
];

export type Props = {
  error: Error;
  titleTag?: TitleTag;
};

export default function ErrorMessage({
  error,
  titleTag,
}: Props): React.ReactElement {
  const { _ } = useLingui();
  const TitleTag = titleTag || "h3";

  const isApolloError = checkIsApolloError(error);
  const isApolloNetworkError =
    isApolloError && !!get(error, ["networkError", "response"]);
  const response: Response = get(error, ["networkError", "response"]);
  const networkErrorStatus = isApolloNetworkError && response.status;

  let message;

  if (
    networkErrorStatus &&
    networkErrorStatus >= 500 &&
    networkErrorStatus < 600
  ) {
    message = _(msg`The service is temporarily unavailable.`);
  } else if (
    networkErrorStatus &&
    networkErrorStatus >= 400 &&
    networkErrorStatus < 500
  ) {
    message = _(msg`An error occurred while processing your request.`);
  } else if (isApolloError) {
    message = _(msg`An error occurred while processing your request.`);
  } else {
    message = _(msg`An unexpected error occurred.`);
  }

  const hint =
    networkErrorStatus && retryStatusCodes.includes(networkErrorStatus)
      ? _(msg`Please try again later.`)
      : "";

  return (
    <>
      <TitleTag className="u-h4">
        <Trans>Something went wrong</Trans>
      </TitleTag>
      <p>
        {message} {hint}
      </p>
    </>
  );
}
