import classNames from "../utils/classNames";
import type { Props as ButtonProps } from "./Button";
import Button from "./Button";
import LoadingIndicator from "./LoadingIndicator";

export type Props = ButtonProps & {
  loading?: boolean;
};

const ButtonWithLoadingIndicator: React.FC<Props> = ({
  loading,
  color,
  shape,
  children,
  ...rest
}) => {
  return (
    <Button color={color} shape={shape} {...rest}>
      {loading && (
        <span className="a-button__overlay">
          <LoadingIndicator size="s" />
        </span>
      )}
      <span className={classNames([loading && "a-button__hidden-label"])}>
        {children}
      </span>
    </Button>
  );
};
export default ButtonWithLoadingIndicator;
