import { Plural, Trans } from "@lingui/macro";
import React from "react";
import type { To } from "react-router-dom";
import { Link } from "react-router-dom";

import type { Reservable } from "../graphql/__generated__/globalTypes";
import type { TitleTag } from "../utils/propTypes";
import FormattedDate from "./FormattedDate";
import FormattedMoney from "./FormattedMoney";
import Icon from "./Icon";
import { ReactComponent as Person } from "./icons/32/person.svg";

export type Props = {
  date: Date;
  startTime: string;
  endTime: string;
  reservable: Reservable;
  totalCents: number;
  to: To;
  titleTag?: TitleTag;
};

/**
 * A single reservable, meant to be rendered in a list of reservables in reservation suggestions.
 */
export default function ReservableListItem(props: Props) {
  const { to, reservable, date, startTime, endTime, totalCents } = props;
  const { vatPercent, reservableCategory } = reservable;
  const capacity = reservableCategory?.capacity || 0;
  const TitleTag = props.titleTag || "h3";

  return (
    <Link to={to} className="reservable-list-item u-link-reset">
      {reservable?.headerImage?.url ? (
        <div
          className="reservable-list-item__image"
          style={{ backgroundImage: `url(${reservable.headerImage.url})` }}
          aria-hidden={true}
        />
      ) : (
        <div className="reservable-list-item__image reservable-list-item__image--placeholder" />
      )}
      <TitleTag className="reservable-list-item__title u-h4">
        {reservable.name}
      </TitleTag>
      <p>
        {<FormattedDate date={date} />},{" "}
        <Trans>
          {startTime} - {endTime}
        </Trans>
      </p>
      <p>
        <Icon svg={Person} className="reservable-list-item__person" />
        <Plural
          value={capacity}
          one={`{capacity} person`}
          other={`{capacity} people`}
        />
      </p>
      <p>
        <span className="u-h4">
          <FormattedMoney cents={totalCents} />
        </span>{" "}
        <span className="u-h6">
          <Trans>incl. {vatPercent}% VAT</Trans>
        </span>
      </p>
    </Link>
  );
}
