import React from "react";

import { useCSRF } from "./CSRFContext";

export type Props = Omit<
  React.InputHTMLAttributes<HTMLInputElement>,
  "name" | "value"
>;

function CSRFTokenInput(props: Props): React.ReactElement {
  const token = useCSRF();
  return token ? (
    <input
      type="hidden"
      name="_csrf_token"
      value={token}
      data-testid="csrf-token-input"
      {...props}
    />
  ) : (
    <></>
  );
}

export default CSRFTokenInput;
