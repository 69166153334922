import { msg, Trans } from "@lingui/macro";
import { useLingui } from "@lingui/react";
import React, { useRef, useState } from "react";

import { useAuth } from "../auth/AuthContext";
import { useResendConfirmationEmailMutation } from "../graphql/__generated__/globalTypes";
import Button from "../shared/Button";
import ButtonWithLoadingIndicator from "../shared/ButtonWithLoadingIndicator";
import ErrorMessage from "../shared/ErrorMessage";
import LoadingIndicator from "../shared/LoadingIndicator";
import PageLayout from "../shared/PageLayout";
import useRedirectPathSearchParam from "../shared/useRedirectPathSearchParam";
import classNames from "../utils/classNames";
import { isDimaPath, isExternalAppPath } from "../utils/dima";

function Loading(): React.ReactElement {
  return (
    <span className="loading-indicator-wrapper">
      <LoadingIndicator />
    </span>
  );
}

type ResendButtonProps = {
  loading: boolean;
  onClick: () => void;
};

function ResendEmailButton(props: ResendButtonProps): React.ReactElement {
  const { loading, onClick } = props;
  const [sent, setSent] = useState(false);
  const timeoutRef = useRef<NodeJS.Timeout | null>(null);

  const handleClick = () => {
    onClick();
    setSent(true);
    if (timeoutRef.current) {
      clearTimeout(timeoutRef.current);
    }
    timeoutRef.current = setTimeout(() => setSent(false), 3000);
  };

  return (
    <ButtonWithLoadingIndicator
      loading={loading}
      color="ghost"
      onClick={handleClick}
    >
      <span className="a-button__overlay" aria-live="polite">
        {sent && <Trans>Sent!</Trans>}
      </span>
      <span className={classNames([sent && "a-button__hidden-label"])}>
        <Trans>Resend email</Trans>
      </span>
    </ButtonWithLoadingIndicator>
  );
}

export default function EmailConfirmationPage(): React.ReactElement {
  const { _ } = useLingui();
  const { account, loading } = useAuth();
  const { redirectPath } = useRedirectPathSearchParam();

  const [resendConfirmationEmail, { error, loading: resending }] =
    useResendConfirmationEmailMutation({
      onError: console.error,
    });

  const useHrefAttribute =
    isDimaPath(redirectPath) || isExternalAppPath(redirectPath);

  return (
    <PageLayout title={_(msg`Confirm email address`)}>
      {loading && <Loading />}
      {error && <ErrorMessage error={error} />}
      {!loading && !error && account && (
        <div className="email-confirmation-page">
          <p>
            <Trans>
              An email with a confirmation link has been sent to{" "}
              <strong>{account?.unconfirmedEmail || account?.email}</strong>.
              Please confirm your email via the link in in the email.
            </Trans>
          </p>

          <div className="button-group">
            {useHrefAttribute ? (
              <Button href={redirectPath}>
                <Trans>Ok, continue!</Trans>
              </Button>
            ) : (
              <Button to={redirectPath}>
                <Trans>Ok, continue!</Trans>
              </Button>
            )}
            <ResendEmailButton
              loading={resending}
              onClick={resendConfirmationEmail}
            />
          </div>
        </div>
      )}
    </PageLayout>
  );
}
