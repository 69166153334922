import UAParser from "ua-parser-js";

import random from "./random";

const parser = new UAParser();
parser.setUA(navigator.userAgent);

export const getDeviceName = () =>
  `${parser.getBrowser().name} for ${parser.getOS().name}`;

export const getDeviceId = () => {
  const everyworksDeviceId = window.localStorage.getItem("everyworksDeviceId");
  if (everyworksDeviceId) {
    return everyworksDeviceId;
  }

  const randomEveryworksDeviceId = random.guid();
  window.localStorage.setItem("everyworksDeviceId", randomEveryworksDeviceId);
  return randomEveryworksDeviceId;
};
