import { msg } from "@lingui/macro";
import { useLingui } from "@lingui/react";

import ErrorPage from "./ErrorPage";

export default function NotFoundPage(): React.ReactElement {
  const { _ } = useLingui();

  return (
    <ErrorPage
      error={_(msg`We’re sorry. We couldn’t find the page you’re looking for.`)}
    />
  );
}
