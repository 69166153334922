import type { ApolloError } from "@apollo/client";
import type { GraphQLErrors } from "@apollo/client/errors";

import type { GraphQLEveryworksError } from "./EveryworksError";

export type GraphQLEveryworksValidationError = {
  code: 1001;
  arg: string;
  validation: string;
} & GraphQLEveryworksError;

export function getValidationErrorForField(errors: GraphQLErrors, key: string) {
  if (key.toLowerCase() !== key) {
    throw new Error(`Expected key to be snake_case, got ${key}`);
  }

  const relevantErrors = errors.filter(
    (error) =>
      "code" in error &&
      error.code === 1001 &&
      "arg" in error &&
      error.arg === key
  );

  if (relevantErrors.length > 0) {
    return relevantErrors
      .map((error) => (error as GraphQLEveryworksValidationError).validation)
      .join(", ");
  }
}

export function hasValidationErrors(error: ApolloError): Boolean {
  return error.graphQLErrors.some(
    (error) => "code" in error && error.code === 1001
  );
}
