import { createContext, useContext } from "react";

import csrf from "./csrf";

export type CSRFContextType = string | null;

const CSRFContext = createContext<CSRFContextType>(csrf.token);

export const useCSRF = () => {
  return useContext(CSRFContext);
};

export default CSRFContext;
