import { Trans } from "@lingui/macro";
import React, { useEffect, useState } from "react";

import type { Account } from "../../graphql/__generated__/globalTypes";
import ButtonWithLoadingIndicator from "../../shared/ButtonWithLoadingIndicator";

export type OnSubmitData = { email: string };

export type Props = {
  loading: boolean;
  emailErrorMessage?: string;
  account?: Account;
  onSubmit: (data: OnSubmitData) => void;
};

export default function EditEmailForm(props: Props) {
  const { loading, account, emailErrorMessage } = props;

  const [email, setEmail] = useState("");

  const onEmailChange = (e: React.ChangeEvent<HTMLInputElement>) =>
    setEmail(e.target.value);

  // NOTE: This is done to have updates to the state work in Storybook
  useEffect(() => {
    if (account) setEmail(account.email || "");
  }, [account]);

  const onSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    props.onSubmit({ email });
  };

  return (
    <form className="form" onSubmit={onSubmit}>
      <div className="form__row">
        <label className="form__label" htmlFor="email">
          <Trans>Email</Trans>
        </label>
        <input
          id="email"
          type="email"
          value={email}
          onChange={onEmailChange}
          autoComplete="email"
          aria-describedby={emailErrorMessage && "email-error"}
        />
        {emailErrorMessage && (
          <p id="email-error" className="form__error">
            {emailErrorMessage}
          </p>
        )}
      </div>

      <div className="form__row form__row--submit">
        <ButtonWithLoadingIndicator
          type="submit"
          loading={loading}
          data-testid="save-button"
        >
          <Trans>Save</Trans>
        </ButtonWithLoadingIndicator>
      </div>
    </form>
  );
}
