import type { SVGProps } from "react";
import React from "react";

import classNames from "../utils/classNames";

export type Props = {
  svg: React.FC<SVGProps<SVGSVGElement>>;
  size?: keyof typeof ICON_PROPS;
  className?: string;
};

const ICON_PROPS = {
  medium: {
    className: "a-icon a-icon--m",
    size: 32,
  },
  large: {
    className: "a-icon a-icon--l",
    size: 48,
  },
};

function Icon({ svg: Svg, size = "medium", className = "" }: Props) {
  const iconProps = ICON_PROPS[size];
  return (
    <Svg
      className={classNames([iconProps.className, className])}
      width={iconProps.size}
      height={iconProps.size}
      focusable="false"
      aria-hidden="true"
      data-testid="icon"
    />
  );
}

export default Icon;
