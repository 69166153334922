import { useMeQuery } from "../graphql/__generated__/globalTypes";

/*
 * Utility to refetch the current account from the server and update the cache.
 */
export default function useRefetchAccount() {
  const { data, loading, error } = useMeQuery({
    fetchPolicy: "cache-and-network",
  });

  const account = data?.me || null;

  return { account, loading, error };
}
