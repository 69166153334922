import { msg, Trans } from "@lingui/macro";
import { useLingui } from "@lingui/react";
import React from "react";
import { Helmet } from "react-helmet";

import Button from "./Button";
import Icon from "./Icon";
import { ReactComponent as NavigationBackIcon } from "./icons/32/navigation-back.svg";
import pageLayoutIllustration from "./images/page-layout-illustration.png";
import Footer from "./PageLayout/Footer";
import SidebarNav from "./PageLayout/SidebarNav";
import TopBar from "./PageLayout/TopBar";

export type Props = React.PropsWithChildren<{
  title: string;
  subtitle?: string;
  backPath?: string;
}>;

export default function PageLayout(props: Props) {
  const { title, subtitle, backPath, children } = props;
  const { _ } = useLingui();

  return (
    <div className="page-layout u-min-height-stretch">
      <a href="#main" className="a-skip-link u-text-center">
        <Trans>Skip to main content</Trans>
      </a>
      <TopBar className="page-layout__top-bar u-hidden u-block@l" />
      <div className="page-layout__page-header">
        <div className="a-content a-content--m">
          <header className="page-layout__page-header-content">
            <div className="page-layout__page-header-navigation">
              {backPath && (
                <span
                  className="page-layout__back-button"
                  data-testid="back-button"
                >
                  <Button shape="small" color="transparent" to={backPath}>
                    <Icon svg={NavigationBackIcon} />
                    <span className="u-sr-only">
                      <Trans>Go back</Trans>
                    </span>
                  </Button>
                </span>
              )}
              <div className="page-layout__sidebar-button u-hidden@l">
                <SidebarNav />
              </div>
            </div>
            <div className="page-layout__page-header-text">
              <h1 className="u-h1 page-layout__page-header-title">{title}</h1>
              {subtitle && (
                <p className="u-body page-layout__page-header-subtitle">
                  {subtitle}
                </p>
              )}
            </div>
            <img
              className="page-layout__page-header-illustration"
              src={pageLayoutIllustration}
              alt={_(
                msg`A person entering a soundproof phone box. There's a tall stool and small table inside of it.`
              )}
            />
          </header>
        </div>
      </div>
      <main id="main" className="page-layout__main">
        <div className="a-content a-content--m">{children}</div>
      </main>
      <Footer className="page-layout__footer u-hidden u-block@l" />
      <Helmet>
        <title>
          {title} · {_(msg`Everyworks`)}
        </title>
      </Helmet>
    </div>
  );
}
